import { ContractorTypeName } from 'models/calculationModel';
import { CaseStatuses, CaseTypes } from './models/models';

export const caseStatuses = {
  [CaseStatuses.NO_CONTRACT]: {
    label: 'Offert påbörjad',
  },
  [CaseStatuses.PENDING_CONTRACT]: {
    label: 'Kontrakt skickat',
  },
  [CaseStatuses.SIGNED_CONTRACT]: {
    label: 'Avtal påskrivet',
  },
  [CaseStatuses.PENDING_CONSTRUCTION_ORDER]: {
    label: 'Tillverkningsorder skickad',
  },
  [CaseStatuses.SIGNED_CONSTRUCITON_ORDER]: {
    label: 'Tillverkningsorder påskriven',
  },
};

export const caseTypes = {
  [CaseTypes.SPECIAL_HOUSE]: {
    label: 'Specialhus',
  },
  [CaseTypes.CATALOGUE_HOUSE]: {
    label: 'Kataloghus',
  },
  [CaseTypes.WILD_STRAWBERRY]: {
    label: 'Smultronställe',
  },
};

export const catalogueHouseChanges = {
  NO_CHANGE: -1,
  MINOR_CHANGE: 400095,
  MAJOR_CHANGE_ONE_FLOOR: 400096,
  MAJOR_CHANGE_TWO_FLOOR: 400097,
};

export const documentTypes = {
  CONTRACT: 0,
  DELIVERY_DECLARATION: 1,
  CONSTRUCTION_ORDER: 2,
  QUOTE: 3,
  PRODUCTION_COST_CALCULATION: 4,
};

export const dependencyEnums = {
  sourceType: { all_from_product_group: 0, specific_product: 1 },
  productFieldPropertyType: { proptype_quantity: 0, proptype_number_of_added_products: 1 },
  operationType: {
    replace_target_value: 0,
    add_to_target_value: 1,
    clear_if_source_exists: 2,
    clear_if_source_does_not_exist: 3,
    set_target_to_1_if_source_exists: 4,
    set_target_to_1_if_source_does_not_exist: 5,
    do_nothing_just_run_modifier: 6,
  },
  conditionType: {
    none: 0,
    source_equal: 1,
    source_greater: 2,
    source_smaller: 3,
    source_greater_or_equal: 4,
    source_smaller_or_equal: 5,
  },
  modifyOperator: { add: 0, subtract: 1, multiply: 2, divide: 3 },
};
export const houseModelSection = {
  parameters: 0,
  firstfloor: 1,
  secondfloor: 2,
  basement: 3,
  garage: 4,
  contractors: 5,
};

export const contractorTypeNames: ContractorTypeName[] = [
  'construction',
  'electricity',
  'exteriorPainting',
  'painting',
  'foundation',
  'plumbing',
  'tiling',
];

export type ContractorType = { id: number; type: string };
export const contractorTypes: ContractorType[] = [
  { id: 1, type: 'Kontrollansvarig pbl' },
  { id: 2, type: 'Grund' },
  { id: 3, type: 'Bygg' },
  { id: 4, type: 'VS' },
  { id: 5, type: 'Målare' },
  { id: 6, type: 'Kakel' },
];

export const counties: Record<string, string> = {
  '1': 'Blekinge',
  '2': 'Dalarna',
  '3': 'Gotland',
  '4': 'Gävleborg',
  '5': 'Halland',
  '6': 'Jämtland',
  '7': 'Jönköping',
  '8': 'Kalmar',
  '9': 'Kronoberg',
  '10': 'Norrbotten',
  '11': 'Skåne',
  '12': 'Stockholm',
  '13': 'Södermanland',
  '14': 'Uppsala',
  '15': 'Värmland',
  '16': 'Västerbotten',
  '17': 'Västernorrland',
  '18': 'Västmanland',
  '19': 'Västra Götaland',
  '20': 'Örebro',
  '21': 'Östergötland',
};

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const AD_TENANT_ID = process.env.REACT_APP_AD_TENANT_ID;
export const AD_CLIENT_ID = process.env.REACT_APP_AD_CLIENT_ID;
