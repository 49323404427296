import { useIsViewingOlderVersion } from 'hooks/useIsViewingOlderVersion';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import ContractorNav from './ContractorNav';
import ContractorTable from './ContractorTable';
import GeneralContractor from './GeneralContractor';

type Props = {
  isHouseTemplate?: boolean;
  isGarageTemplate?: boolean;
};
const Contractor = ({ isHouseTemplate, isGarageTemplate = false }: Props) => {
  const { caseNumber, templateCalculationId } =
    useParams<{ caseNumber: string; templateCalculationId: string }>();
  const id = isHouseTemplate ? templateCalculationId : caseNumber;
  const urlBase: string = isHouseTemplate ? 'husmall' : 'case';
  const idParamName: string = isHouseTemplate ? 'templateCalculationId' : 'caseNumber';

  const isViewingOlderVersion = useIsViewingOlderVersion();

  const contractorPages = [
    {
      slug: 'bygg',
      calculationName: 'construction',
    },
    {
      slug: 'vvs',
      calculationName: 'plumbing',
    },
    {
      slug: 'el',
      calculationName: 'electricity',
    },
    {
      slug: 'malning-utvandigt',
      calculationName: 'exteriorPainting',
    },
    {
      slug: 'grund',
      calculationName: 'foundation',
    },
    {
      slug: 'malning',
      calculationName: 'painting',
    },
    {
      slug: 'kakel',
      calculationName: 'tiling',
    },
  ];
  return (
    <div>
      <ContractorNav isHouseTemplate={isHouseTemplate} />
      <Switch>
        {contractorPages.map(({ calculationName, slug }) => {
          return (
            <Route key={slug} path={`/${urlBase}/:${idParamName}/entreprenad/${slug}`} exact>
              <ContractorTable calculationName={calculationName} isGarageTemplate={isGarageTemplate} />
            </Route>
          );
        })}
        <Route path={`/${urlBase}/:${idParamName}/entreprenad/generalentreprenad`}>
          <GeneralContractor />
        </Route>
        <Route path={`/${urlBase}/:${idParamName}/entreprenad`} exact>
          <Redirect
            to={`/${urlBase}/${id}/entreprenad/bygg${
              isViewingOlderVersion ? `?version=${isViewingOlderVersion}` : ''
            }`}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default Contractor;
