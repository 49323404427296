import { IContractor } from './calculationModel';

export enum CaseStatuses {
  NO_CONTRACT,
  PENDING_CONTRACT,
  SIGNED_CONTRACT,
  PENDING_CONSTRUCTION_ORDER,
  SIGNED_CONSTRUCITON_ORDER,
}
export interface IObjectKeys {
  [key: string]: any;
}
export interface ICustomer extends IObjectKeys {
  id: number | null;
  personalIdentityNumber: string;
  firstName: string;
  lastName: string;
  address: string;
  postalAddress: string;
  phoneWork: string;
  phoneHome: string;
  phoneCell: string;
  email: string;
  municipality: string;
}
export type ProductionCostCalculationColumn = {
  value: number;
  isActive: boolean;
  isLocked: boolean;
};
export interface IProductionCostCalculationRow {
  rowNumber: number;
  name: string;
  comment: string;
  column1: ProductionCostCalculationColumn;
  column2: ProductionCostCalculationColumn;
  column3: ProductionCostCalculationColumn;
  column4: ProductionCostCalculationColumn;
}
export interface IProductionCostCalculationGroup {
  name: string;
  rows: IProductionCostCalculationRow[];
}
export interface IProductionCostCalculation {
  groups: IProductionCostCalculationGroup[];
  comment: string;
}

export enum CaseTypes {
  SPECIAL_HOUSE,
  CATALOGUE_HOUSE,
  WILD_STRAWBERRY,
}
export interface ICase {
  caseNumber: number;
  changedAt?: string | null;
  createdAt: string | null;
  constructionOrderSigned?: number;
  constructionOrdersSent: number[];
  contractNumber?: number;
  contractVersion: number;
  contractors: IContractor[];
  contractsSent: number[];
  customers: ICustomer[];
  deliveryWeekNumber?: number;
  drawingGarageChangedDate?: string | null;
  drawingGarageCreatedDate?: string | null;
  drawingHouseChangedDate?: string | null;
  drawingHouseCreatedDate?: string | null;
  drawingNumberGarage?: string | null;
  drawingNumberHouse?: string | null;
  id?: number;
  indexBaseMonth?: string | null;
  indexBaseYear?: string | null;
  isInverted?: boolean;
  name: string;
  productionCostCalculation: IProductionCostCalculation;
  property?: string;
  propertyAddress?: string;
  propertyDesignation?: string;
  propertyMunicipal?: string;
  quotesSent: number[];
  reloadingRequired?: boolean;
  status: CaseStatuses;
  templateCalculationId?: number;
  templateVersion?: number;
  type: CaseTypes;
  userId?: string;
  userName?: string;
}

export interface ICustomerCase {
  caseNumber: number;
  changedAt: string;
  createdAt: string;
  id: number;
  name: string;
  userId: string;
  userName: string;
}

export type CurrentUser = {
  userName: string;
  userId: string;
  roles: number[];
  isAdmin: boolean;
  isSemiAdmin: boolean;
};

export type SelectOption = {
  value: number;
  label: string;
  data: any;
};
export interface IOptions {
  dict: {
    [key in string]: number;
  };
  options: {
    [key in number | string]: SelectOption[];
  };
}

export enum ModalTypes {
  EditProduct = 1,
  EditSubproduct = 2,
  EditProductGroup = 4,
}

export type FloorName = 'parameters' | 'firstFloor' | 'secondFloor' | 'basement' | 'garage';
