import { useCurrentCase, useSetBNumber, useSetCaseStatus } from 'api/case';
import { IVersion } from 'models/calculationModel';
import { CaseStatuses } from 'models/models';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import Button, { FormSubmitWrapper } from 'ui-library/Button';
import InputField from 'ui-library/InputField';
import Modal from 'ui-library/Modal';

type Props = {
  show: boolean;
  onClose: () => void;
  version: IVersion;
};
const BNumberModal = ({ show, onClose, version }: Props) => {
  const [bNumber, setBNumber] = useState<string>();
  const { mutate: updateCaseStatus } = useSetCaseStatus();
  const { mutate: updateBNumber } = useSetBNumber();
  const queryClient = useQueryClient();
  const { currentCase } = useCurrentCase();

  const handleSave = () => {
    if (currentCase && bNumber) {
      updateCaseStatus(
        { status: CaseStatuses.SIGNED_CONTRACT, version: version.version },
        {
          onSuccess: () => {
            updateBNumber(bNumber, {
              onSuccess: () => {
                queryClient.invalidateQueries(['version']);
                queryClient.invalidateQueries(['case']);
              },
            });
          },
        },
      );
    }
  };

  return (
    <Modal header="Ange B-nummer" show={show} onClose={onClose}>
      <InputField prefix="B-" onChange={(e) => setBNumber(e.target.value)} />
      <FormSubmitWrapper>
        <Button onClick={handleSave} disabled={!bNumber}>
          Spara
        </Button>
      </FormSubmitWrapper>
    </Modal>
  );
};

export default BNumberModal;
