import CalculationInput from 'components/CalculationInput';
import Bathroom from 'components/ProductComponents/Bathroom';
import DeliveryInformation from 'components/ProductComponents/DeliveryInformation';
import FrontDoors from 'components/ProductComponents/FrontDoors';
import GarageSelector from 'components/ProductComponents/GarageSelector';
import HeatingAndVentilation from 'components/ProductComponents/HeatingAndVentilation';
import ParameterSelect from 'components/ProductComponents/ParameterSelect';
import ProductComponent from 'components/ProductComponents/ProductComponent';
import ProductSelect from 'components/ProductComponents/ProductComponent/ProductSelect';
import UndefinedProducts from 'components/ProductComponents/UndefinedProducts';
import WindowsAndDoors from 'components/ProductComponents/WindowsAndDoors';
import { useCalculationIsDisabled } from 'hooks/useCalculationIsDisabled';
import { FloorName } from 'models/models';

interface CustomController {
  match: ({ floor, productName }: { floor?: string; productName: string }) => boolean;
  component: any;
}
const inputControllerProducts = [
  'area',
  'garageWallSquareMeters',
  'garageWallMeters',
  'indoorFloorSurface',
  'undefinedEaves',
  'numberOfCorners',
  'openingFrames',
  'bearingWoodWallsQuantity',
  'nonBearingWoodWallsQuantity',
  'twoSpaceCup',
  'threeSpaceCup',
];
const parameterSelectProducts = ['houseShape', 'houseDesignType', 'floorType', 'basementType', 'roofSlope'];
const productSelectProducts = ['garageType', 'changedCatalogueHouse'];
const customControllers: CustomController[] = [
  {
    match: ({ productName }) =>
      (productName.startsWith('defined') ||
        productName.startsWith('frontDoor.defined') ||
        productName === 'garageDoors') &&
      productName !== 'definedWindows',
    component: UndefinedProducts,
  },
  {
    match: ({ productName }) => productName === 'deliveryInformation',
    component: DeliveryInformation,
  },
  {
    match: ({ productName }) => productName === 'garageSelector',
    component: GarageSelector,
  },
  {
    match: ({ productName }) =>
      productName.startsWith('standard') || productName.startsWith('frontDoor.standard'),
    component: CalculationInput,
  },
  {
    match: ({ productName }) => productName === 'heatingAndVentilation',
    component: HeatingAndVentilation,
  },
  {
    match: ({ productName }) => inputControllerProducts.includes(productName),
    component: CalculationInput,
  },
  {
    match: ({ productName }) => productName === 'windowsAndDoors',
    component: WindowsAndDoors,
  },
  {
    match: ({ productName }) => parameterSelectProducts.includes(productName),
    component: ParameterSelect,
  },
  {
    match: ({ productName }) => productSelectProducts.includes(productName),
    component: ProductSelect,
  },
  {
    match: ({ productName }) => productName.startsWith('frontDoors'),
    component: FrontDoors,
  },
  {
    match: ({ productName }) => productName === 'bathroom',
    component: Bathroom,
  },
];

type Props = {
  floor?: FloorName;
  productName: string;
  label?: string;
};
const ControlSelector = ({ floor, productName, label }: Props) => {
  const path = `${floor}.${productName}`;
  const customController = customControllers.find((custom) => custom.match({ floor, productName }));
  const disabled = useCalculationIsDisabled();

  if (customController) {
    return customController.component({ floor, path, productName, label, disabled });
  }
  return <ProductComponent floor={floor} path={path} disabled={disabled} />;
};

export default ControlSelector;
