import EditProductGroupForm from 'Admin/Forms/EditProductGroup/EditProductGroup';
import { IAdminProductGroup } from 'models/adminModels';
import Modal from 'ui-library/Modal';

type Props = {
  show: boolean;
  onClose: () => void;
  adminProductGroup: IAdminProductGroup;
};
const EditProductGroup = ({ show, onClose, adminProductGroup }: Props) => {
  return (
    <Modal header="Editera/Lägg till produktgrupp" show={show} onClose={onClose} size="medium">
      Edit productgroup
      <EditProductGroupForm adminProductGroup={adminProductGroup} />
    </Modal>
  );
};

export default EditProductGroup;
