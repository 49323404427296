import ReactDOM from 'react-dom';
import { runWithAdal } from 'react-adal';
import App from './App';

import { authContext } from './adalConfig';

runWithAdal(
  authContext,
  () =>
    // eslint-disable-next-line react/no-render-return-value
    ReactDOM.render(
      // <React.StrictMode>
      <App />,
      // </React.StrictMode>,
      document.getElementById('root'),
    ),
  false,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
