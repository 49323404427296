import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useUpdateProduct } from 'Admin/api/product';
import { IAdminProduct, IAdminProductGroup } from 'models/adminModels';
import toast from 'react-hot-toast';
import Button, { FormSubmitWrapper } from 'ui-library/Button';
import InputField from 'ui-library/InputField';
import Checkbox from 'ui-library/Checkbox';
import ControlledSelect from 'ui-library/Select/ControlledSelect';
import LabeledCollapse from 'ui-library/LabeledCollapse';
import Box from 'ui-library/Box';
import DependencyListForm from 'Admin/Components/DependencyListForm';

const validationTypes = { integer: 'Heltal', number: 'Decimaltal', none: 'Ingen' };

type Props = {
  product: IAdminProduct;
  onClose: () => void;
  adminProductGroup?: IAdminProductGroup;
  isNew?: boolean;
};
const EditProductForm = ({ product, adminProductGroup, isNew, onClose }: Props) => {
  const defaultValues: IAdminProduct = isNew
    ? { ...product, productGroupNumber: adminProductGroup?.productGroupNumber || 0 }
    : product;
  const form = useForm<IAdminProduct>({
    defaultValues,
  });

  const { register, handleSubmit, formState, control } = form;
  const { isDirty } = formState;
  const { mutate } = useUpdateProduct();
  const onSubmit = (data: IAdminProduct) => {
    mutate(data, {
      onSuccess: () => {
        toast.success('Produkt sparad');
        onClose();
      },
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Row>
          <Col md="6" lg="6">
            <InputField {...register('name')} label="Namn" />
            <InputField {...register('priceIncludingVat')} label="Pris, ink. moms" />
            <InputField {...register('priceExcludingVat')} label="Pris, ex. moms" />
            <InputField {...register('netPrice')} label="Bra Hus nettopris" />
            <InputField {...register('deductionPrice')} label="Avdragspris" />
          </Col>
          <Col md="6" lg="6">
            <InputField {...register('validFrom')} disabled label="Giltig från" />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <InputField {...register('metaData.title')} label="Titel (alternativt namn i t.ex. LD)" />
            <InputField {...register('metaData.description')} label="Beskrivning" />
            <InputField
              {...register('metaData.subProductHeadling')}
              label="Titel till underproduktkategori (LD)"
            />
            <Checkbox label="Exkludera från smultronställe" {...register('excludeFromWildStrawberry')} />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <ControlledSelect
              control={control}
              name="validationType"
              label="Valideringstyp"
              options={Object.entries(validationTypes).map(([value, label]) => ({ value, label }))}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <LabeledCollapse label="Etiketter och placeholders">
              <Box>
                <InputField {...register('labels.descriptionLabel')} label="Beskrivning, etikett" />
                <InputField {...register('labels.descriptionPlaceholder')} label="Beskrivning, placholder" />
                <InputField
                  {...register('labels.externalDeductionPriceLabel')}
                  label="Externt avdragspris, etikett"
                />
                <InputField
                  {...register('labels.externalDeductionPricePlaceholder')}
                  label="Externt avdragspris, placholder"
                />
                <InputField
                  {...register('labels.externalPricePerPieceLabel')}
                  label="Externt pris per styck, etikett"
                />
                <InputField
                  {...register('labels.externalPricePerPiecePlaceholder')}
                  label="Externt pris per styck, placholder"
                />
                <InputField
                  {...register('labels.externalTotalPriceLabel')}
                  label="Externt pris totalt, etikett"
                />
                <InputField
                  {...register('labels.externalTotalPricePlaceholder')}
                  label="Externt pris totalt, placholder"
                />
              </Box>
            </LabeledCollapse>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Checkbox {...register('isStandardCustom')} label="Är standardprodukt för specialhus" />
            <Checkbox {...register('isStandardCatalogue')} label="Är standardprodukt för kataloghus" />
            <Checkbox
              {...register('isStandardCatalogueWildStrawberry')}
              label="Är standardprodukt för smultronställe"
            />
            <Checkbox {...register('includeSubProducts')} label="Inkludera underproduktskategorier" />
            <Checkbox {...register('isContainer')} label="Är behållare" />
            <Checkbox {...register('requiresDescription')} label="Kräver beskrivning" />
            <Checkbox {...register('requiresExternalDeductionPrice')} label="Kräver externt avdragspris" />
            <Checkbox
              {...register('requiresExternalPricePerPiece')}
              label="Kräver externt pris (per styck)"
            />
            <Checkbox {...register('requiresExternalTotalPrice')} label="Kräver externt pris (totalpris)" />
            <Checkbox {...register('isNoneProduct')} label="Är icke-produkt (t.ex ingen, inget)" />
          </Col>
        </Row>
        <Row>
          <Col>
            <DependencyListForm form={form} prefix="productDependencies" />
          </Col>
        </Row>
      </Container>
      <hr />
      <Checkbox {...register('isCanceled')} label="Produkt har utgått" />
      <FormSubmitWrapper>
        <Button disabled={!isDirty}>Spara</Button>
      </FormSubmitWrapper>
    </form>
  );
};

export default EditProductForm;
