import { useGlobalStateValue } from 'stateContext';
import styled from 'styled-components';
import InputField from 'ui-library/InputField';
import Select from 'ui-library/Select';
import { TitleComponent } from '../ProductComponent/TitleComponent';

const DeliveryInformationWrapper = styled.div`
  margin-bottom: 1rem;
  & .inputs {
    display: flex;
    & > input,
    & > div {
      width: 50%;
    }
  }
`;

type Props = {
  disabled: boolean;
};
const DeliveryInformation = ({ disabled }: Props) => {
  const { currentCalculation, setValue } = useGlobalStateValue();
  const opts = [true, false].map((value) => ({ label: value ? 'Ja' : 'Nej', value }));
  const defaultValue = opts.find((opt) => opt.value === currentCalculation?.reloadingRequired);
  // console.log({ defaultValue });
  return (
    <DeliveryInformationWrapper>
      <TitleComponent type="banner" nameOverride="Leveransinformation" />
      <div className="inputs">
        <InputField
          defaultValue={currentCalculation?.deliveryWeekNumber}
          label="Leveransvecka"
          disabled={disabled}
          onChange={(e: any) => setValue('deliveryWeekNumber', e.target.value)}
        />
        <Select
          label="Omlastning krävs på byggplats"
          value={defaultValue}
          onChange={(opt: any) => {
            setValue('reloadingRequired', opt.value);
          }}
          options={opts}
        />
      </div>
    </DeliveryInformationWrapper>
  );
};

export default DeliveryInformation;
