import EditableField from 'ui-library/EditableField';
import { useSetProductValue } from 'api/price';

export type EditableContractorFieldProps = {
  value: any;
  path: string;
  property: string;
  disabled?: boolean;
};
export const EditableContractorField = ({
  value,
  path,
  property,
  disabled,
}: EditableContractorFieldProps) => {
  const setProductValue = useSetProductValue();

  const handleChange = (val: string) => {
    setProductValue(path, property, val);
  };

  return <EditableField onChange={handleChange} value={value} disabled={disabled} />;
};
