import { formatDate, formatPrice } from 'helpers';
import { houseNameWithChange } from 'hooks/usePdfMaker/documentsCommon';
import { ICalculation, IVersion } from 'models/calculationModel';
import { ICase } from 'models/models';
import { IPriceResponse } from 'models/priceModel';
import { documentTypes } from '../../../../constants';
import { formDataTableCell, makeCustomersRows, signingLines } from './helpers';

export const getDeliveryDeclarationCustomerPage = (
  currentCase: ICase,
  price: IPriceResponse,
  calculation: ICalculation,
  version: IVersion,
  gdpr = false,
) => {
  if (!currentCase || !price || !calculation) return null;

  const hasGarage = !calculation.currentHouse.house.parameters.garageType.productData.rules.isNoneProduct;
  const priceDiffFromContract = price.currentPriceForHouseAndGarage - price.contractPriceForHouseAndGarage;
  const qrData = {
    v: version,
    cid: currentCase.id,
    t: documentTypes.QUOTE,
  };
  const customerPage = [
    {
      table: {
        widths: [30, '*', 140, 40, 40],
        body: [
          [
            {
              image: 'logo',
              width: 30,
              // style: 'tableHeadRow',
              // border: //[true, false, true, false]
            },
            {
              text: [{ text: 'Slutlig tillverkningsorder', style: 'header' }],
            },
            {
              text: 'Enligt leveransdeklaration:',
              // style: 'tableHeadRow'
            },
            {},
            {},
          ],
        ],
      },
      layout: {
        fillColor: () => {
          return '#c3d69b';
        },
        vLineWidth: (i: number, node: any) => {
          return i === 0 || i === node.table.widths.length ? 1 : 0;
        },
        hLineWidth: (i: number, node: any) => {
          return i === 0 || i === node.table.body.length ? 1 : 0;
        },
      },
    },
    {
      table: {
        widths: ['*', '*', '*', '*', '*', '*', '*'],
        body: [
          // ROW
          [
            formDataTableCell('Säljare', 'BRA HUS från HEDLUNDS AB', 4),
            {},
            {},
            {},
            formDataTableCell('Org nr.', '556609-1996', 2),
            {},
            formDataTableCell('Avtalsnr.', `B-${currentCase.contractNumber}`),
          ],
          ...makeCustomersRows(currentCase.customers, gdpr),
          // ROW
          [
            formDataTableCell('Bostadsadress', gdpr ? '[...]' : currentCase.customers[0].address, 3),
            {},
            {},
            formDataTableCell('Postadress', gdpr ? '[...]' : currentCase.customers[0].postalAddress, 3),
            {},
            {},
            formDataTableCell('Tel. bostad', gdpr ? '[...]' : currentCase.customers[0].phoneHome),
          ],
          // ROW
          [
            formDataTableCell('Köparens e-mailadress', gdpr ? '[...]' : currentCase.customers[0].email, 3),
            {},
            {},
            formDataTableCell('Säljarens representant/säljkontor', currentCase.userName || '', 3),
            {},
            {},
            formDataTableCell('Tel.', ''),
          ],
          // ROW
          [
            formDataTableCell('Fastighetsbeteckning', currentCase.propertyDesignation, 2),
            {},
            formDataTableCell('Kommun', currentCase.propertyMunicipal, 2),
            {},
            formDataTableCell('Byggplatsadress', currentCase.propertyAddress, 3),
            {},
            {},
          ],
          // ROW
          [
            formDataTableCell('Hustyp', houseNameWithChange(calculation), 3),
            {},
            {},
            formDataTableCell('Ritningsnr.', currentCase.drawingNumberHouse?.toString()),
            formDataTableCell('Sidobyggnad', hasGarage ? 'Ja' : 'Nej'),
            formDataTableCell('Ritningsnr.', currentCase.drawingNumberGarage?.toString()),
            formDataTableCell('Rätvänd', !currentCase.isInverted ? 'Ja' : 'Nej'),
          ],
        ],
      },
      layout: {
        fillColor: () => {
          return '#d6d4cb';
        },
      },
    },
    {
      style: 'infoTable',
      table: {
        headerRows: 1,
        widths: ['*'],
        body: [
          // HEADER ROW
          [{ text: 'INFORMATION', style: 'tableHeader' }],
          // ROW
          [
            {
              text: 'För att fastställa tillverkning och leveranstider måste nedanstående handlingar bifogas denna slutliga tillverkningsorder.',
              style: 'bold',
            },
          ],
          // ROW
          [
            {
              text: 'OBS! Detta dokument gäller före annat som redovisas i övriga dokument eller ritningar',
              style: 'bold',
            },
          ],
        ],
      },
      layout: 'headerLineOnly',
    },
    {
      style: 'boldList',
      ol: ['Godkänd bygglovsritning', 'Färdplan', 'Lånelöfte/kreditintyg'],
    },
    {
      style: 'hr',
      table: {
        headerRows: 1,
        widths: ['*'],
        body: [[{ text: ' ', style: 'tableHeader' }], [' ']],
      },
      layout: 'headerLineOnly',
    },
    {
      table: {
        widths: [62, '*'],
        body: [
          [
            { text: 'Spärrförbindelse:', style: 'bold' },
            'Eller annan garanti skall vara Bra Hus tillhanda senast 10 arbetsveckor före planerad 1:a leverans',
          ],
          [
            {
              text: 'Tillverkningsordern utgör den slutgiltiga beställningen. Ändringar i omfattning eller utförande kan ej medges efter undertecknande.',
              style: 'bold',
              colSpan: 2,
            },
          ],
          [
            {
              text: 'BRA HUS förbehåller sig rätten till ändringar av konstruktionen och leveransutförande.',
              style: 'bold',
              colSpan: 2,
            },
          ],
        ],
      },
      layout: 'noBorders',
    },
    {
      style: 'infoTable',
      table: {
        headerRows: 1,
        widths: ['auto', '*'],
        body: [
          [{ text: 'Övrigt', style: 'tableHeader', fillColor: '#c3d69b', colSpan: 2 }, { text: '' }],
          [
            { text: 'Omlastning krävs till byggplats (Se AA-12):' },
            { text: calculation.reloadingRequired ? 'Ja' : 'Nej' },
          ],
          [{ text: 'Önskad leveransvecka:' }, { text: calculation.deliveryWeekNumber }],
        ],
      },
      layout: 'headerLineOnly',
    },
    {
      style: 'infoTable',
      table: {
        headerRows: 1,
        widths: ['*', 'auto'],
        body: [
          [
            { text: 'Ekonomi', style: 'tableHeader', fillColor: '#c3d69b' },
            { text: 'Kronor', style: 'tableHeader', fillColor: '#c3d69b' },
          ],
          [
            {
              text: `Avtalssumma enligt köpeavtal ${currentCase.caseNumber}-${currentCase.contractVersion}`,
            },
            { text: formatPrice(price.contractPriceForHouseAndGarage), fillColor: '#dddddd' },
          ],
          [
            { text: 'Summa tillägg/avdrag från denna slutliga tillverkningsorder' },
            { text: formatPrice(priceDiffFromContract), fillColor: '#dddddd' },
          ],
          [
            {
              text: 'Summa köpeavtal + tillägg/avdrag enligt denna slutliga tillverkningsorder inklusive moms',
            },
            { text: formatPrice(price.currentPriceForHouseAndGarage), fillColor: '#dddddd' },
          ],
        ],
      },
      layout: 'headerLineOnly',
    },
    {
      text: `Datum: ${formatDate(new Date(), 'yyyy-MM-dd')}`,
      style: 'date',
    },
    signingLines(currentCase.customers),
    {
      style: {
        margin: [0, 0, 0, 0],
      },
      margin: [0, 30, 0, 0],
      table: {
        widths: ['auto', '*'],
        body: [
          // HEADER ROW
          [{ text: 'Bra Hus från Hedlunds AB', style: 'tableHeader' }, { text: 'Org. nr. 556609-1996' }],
          // ROW
          [{ text: 'Järnvägsgatan 12' }, { text: '' }],
          // ROW
          [{ text: '790 70 Furudal' }, { text: '' }],
          // ROW
          [{ text: 'Telefon: 0258-31200' }, { text: '' }],
          // ROW
          [{ text: 'E-mail: brahus@hedlunds.se' }, { text: '' }],
        ],
      },
      layout: 'headerLineOnly',
    },
    {
      qr: btoa(JSON.stringify(qrData)),
      fit: 60,
      pageBreak: 'after',
      absolutePosition: { x: 500, y: 650 },
    },
  ];

  return customerPage;
};
