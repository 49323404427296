import { useQuery } from 'react-query';
import { adFetch } from 'adalConfig';
import { ICalculation, IHouseTemplate } from 'models/calculationModel';

export const useGetHouseTemplates = () => {
  const {
    data: houseTemplates,
    isFetching,
    isLoading,
  } = useQuery(['houseTemplates'], () => adFetch<IHouseTemplate[]>('/calculation/housetemplates'), {
    staleTime: Infinity,
  });
  return { houseTemplates, isFetching, isLoading };
};

export const useGetHouseTemplate = (calculationId?: number) => {
  const query = useQuery(
    ['houseTemplate', calculationId],
    () => adFetch<ICalculation>(`/calculation/housetemplate?calculationId=${calculationId}`),
    { enabled: Boolean(calculationId) },
  );
  return query;
};

export const useGetTemplateForProduct = (productNumber: number) => {
  const { houseTemplates } = useGetHouseTemplates();
  if (!houseTemplates) return undefined;

  const productTemplate = houseTemplates?.find((tmpl) =>
    [
      tmpl.catalogueHouseProductNumber,
      tmpl.garageProductNumber,
      tmpl.wildStrawberryHouseProductNumber,
    ].includes(productNumber),
  );

  return productTemplate;
};
