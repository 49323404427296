import styled from 'styled-components';
import { IProduct } from 'models/calculationModel';
import { useGlobalStateValue } from 'stateContext';
import ComponentGroupHeader from 'ui-library/ComponentGroupHeader';
import { FloorName } from 'models/models';
import { getTotalQuantity } from './getTotalQuantity';
import WindowProduct from './WindowProduct';

const WindowsAndDoorsWrapper = styled.div``;

type Props = {
  path: string;
  floor?: FloorName;
  disabled?: boolean;
};
const WindowsAndDoors = ({ path, floor, disabled }: Props) => {
  const { getValue } = useGlobalStateValue();
  const windows = getValue<IProduct>(path);
  const totalQuantity = getTotalQuantity(windows?.subProducts);

  return (
    <WindowsAndDoorsWrapper>
      <div className="container-header">
        <ComponentGroupHeader>Fönster och dörrar</ComponentGroupHeader>
      </div>
      <p>Antal snickerier, exklusive ytterdörrar: {totalQuantity}</p>
      {windows?.subProducts.map((_subProduct, idx) => (
        <WindowProduct
          key={_subProduct.productGroup}
          path={`${path}.subProducts[${idx}]`}
          floor={floor}
          disabled={disabled}
        />
      ))}
    </WindowsAndDoorsWrapper>
  );
};

export default WindowsAndDoors;
