// import React from "react";
import styled, { css } from 'styled-components';

type ButtonSize = 'small' | 'medium';

type Props = {
  variant?: 'secondary';
  icon?: boolean;
  size?: ButtonSize;
};
const StyledButton = styled.button<Props>`
  height: ${({ size }) => (size === 'small' ? '2rem' : '2.5rem')};
  padding: ${({ size }) => (size === 'small' ? '0.5rem 1rem' : '0.5rem 1rem')};
  ${({ size }) =>
    size === 'small' &&
    `
      font-size: 0.75rem;
    `}
  white-space: nowrap;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    display: block;
    width: 100%;
    color: #fff;
  }
  svg {
    margin-left: ${({ icon }) => (icon ? '0' : '0.5rem')};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryFocus};
  }
  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.65;
  }
  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      background-color: #fff;
      color: ${({ theme }) => theme.colors.primary};
      border: 1px solid;
      &:hover {
        background-color: ${({ theme }) => theme.colors.bgLight};
      }
    `}
`;

export const FormSubmitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colors.gray};
  padding-top: 1rem;
  margin-top: 1rem;
  button {
    margin-left: 0.5rem;
  }
`;

export default StyledButton;
