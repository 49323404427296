import { useQuery } from 'react-query';
import { adFetch } from 'adalConfig';
import { useCurrentCalculation } from './useCurrentCalculation';

export const useProductTemplate = () => {
  const { currentCalculation } = useCurrentCalculation();
  const { data: templates } = useQuery(
    ['templates'],
    () => adFetch<any>(`/templates?date=${currentCalculation?.date || 'false'}`),
    {
      staleTime: Infinity,
    },
  );

  return { templates };
};
