import addDays from 'date-fns/addDays';
import { ICalculation, IVersion } from 'models/calculationModel';
import { ICase } from 'models/models';
import { formatDate, formatPrice } from 'helpers';
import { IPriceResponse } from 'models/priceModel';
import { documentTypes } from '../../../constants';
import { getContractorBody, getCustomerRow, getTotalPriceIncVat, logo } from './deliveryDeclaration/helpers';
import { houseNameWithChange } from '../documentsCommon';

type Args = {
  version: IVersion;
  currentCase: ICase;
  price: IPriceResponse;
  calculation: ICalculation;
};

const getQuoteDocument =
  (gdpr: boolean) =>
  ({ version, currentCase, price, calculation }: Args) => {
    if (!version || !currentCase || !price || !calculation) return null;
    const qrData = {
      v: version,
      cid: currentCase.id,
      t: documentTypes.QUOTE,
    };
    const houseName = houseNameWithChange(calculation);
    const definition = {
      info: {
        title: 'Offert',
      },
      content: [
        {
          image: 'logo',
          width: 24,
        },
        {
          absolutePosition: { x: 480, y: 40 },
          qr: btoa(JSON.stringify(qrData)),
          fit: 60,
        },
        {
          table: {
            headerRows: 1,
            widths: ['auto', 20, '*', '*', '*', '*'],
            body: [
              [{ text: 'Kunduppgifter', style: { bold: true } }, '', '', '', '', ''],
              getCustomerRow('Namn', 'name', currentCase.customers, gdpr),
              getCustomerRow('Telefon', 'phoneHome', currentCase.customers, gdpr),
              getCustomerRow('E-mail', 'email', currentCase.customers, gdpr),
              getCustomerRow('Adress', 'address', currentCase.customers, gdpr),
              getCustomerRow('Postnummber och ort', 'postalAddress', currentCase.customers, gdpr),
              [{ text: 'Hustyp' }, '', { text: houseName, colSpan: 4 }, '', '', ''],
              [{ text: 'Leveransdeklaration' }, '', '', '', '', ''],
              [{ text: 'Datum' }, '', formatDate(version.dateSaved!, 'yyyy-MM-dd'), '', '', ''],
              [
                { text: 'Offerten giltig t.o.m.' },
                '',
                formatDate(addDays(new Date(version.dateSaved!), 30), 'yyyy-MM-dd'),
                '',
                '',
                '',
              ],
            ],
          },
          layout: 'noBorders',
          margin: [0, 30, 0, 0],
        },
        {
          text: 'BRA HUS VILLABYGGSATS',
          style: 'header',
        },
        {
          text: 'Byggsatspriser gäller under förutsättning att skriftligt köpekontrakt upprättats och undertecknats mellan firmatecknare för BRA HUS från Hedlunds AB och dig som kund.',
          style: 'microtext',
        },
        {
          table: {
            style: 'tableStyle',
            widths: ['*', '*', 'auto', 'auto'],
            body: [
              [{ text: '', colSpan: 2 }, '', 'Huvudbyggnad', 'Sidobyggnad'],
              [
                {
                  text: [
                    'Villabyggsats enligt offert nummer: ',
                    {
                      text: `${currentCase.caseNumber}-${version.version}`,
                      bold: true,
                    },
                  ],
                  colSpan: 2,
                },
                '',
                `${formatPrice(price?.currentHousePrice)} kr`,
                `${formatPrice(price?.currentGaragePrice)} kr`,
              ],
              [
                {
                  text: 'Totalbelopp inkl. moms materialsats huvudbyggnad samt ev. sidobyggnad',
                  style: { bold: true },
                  colSpan: 2,
                },
                '',
                `${formatPrice(price?.currentPriceForHouseAndGarage)} kr`,
                '',
              ],
            ],
          },
          layout: {
            fillColor: () => {
              return '#cccccc';
            },
            hLineWidth: () => {
              return 0;
            },
            vLineWidth: () => {
              return 0;
            },
          },
        },
        {
          text: 'Entreprenader',
          style: 'header',
        },
        {
          text: 'Priserna utgår från entreprenadformen delad entreprenad vilket innebär att du som kund träffar avtal med BRA HUS gällande leverans av materialsatsen samt träffar avtal med respektive entreprenör gällande entreprenaderna. Våra agenter bistår dig med upprättande av entreprenadkontrakt.',
          style: 'microtext',
        },
        {
          table: {
            widths: ['*', 'auto', 'auto'],
            body: getContractorBody(price, calculation),
          },
          layout: {
            fillColor: () => {
              return '#cccccc';
            },
            hLineWidth: () => {
              return 0;
            },
            vLineWidth: () => {
              return 0;
            },
          },
        },
        {
          table: {
            widths: ['*', 'auto'],
            body: [
              [
                'Totalbelopp inkl. moms entreprenader huvudbyggnad samt ev. sidobyggnad',
                `${formatPrice(price?.currentContractorPrices.totalPrice)} kr`,
              ],
            ],
          },
          margin: [0, 5, 0, 0],
          layout: {
            fillColor: () => {
              return '#cccccc';
            },
            hLineWidth: () => {
              return 0;
            },
            vLineWidth: () => {
              return 0;
            },
          },
        },
        {
          text: 'Slutsummering',
          style: 'header',
        },
        {
          text: 'Totalpriser enligt ovanstående förutsättningar',
          style: 'microtext',
        },
        {
          table: {
            widths: ['*', 'auto'],
            body: [
              [
                {
                  text: 'Totalbelopp inkl. moms materialsats och entreprenader för huvudbyggnad samt ev. sidobyggnad',
                  style: 'boldBody',
                },
                { text: `${formatPrice(getTotalPriceIncVat(price))} + kr`, alignment: 'right' },
              ],
            ],
          },
          layout: {
            fillColor: () => {
              return '#cccccc';
            },
            hLineWidth: () => {
              return 0;
            },
            vLineWidth: () => {
              return 0;
            },
          },
        },
        {
          text: 'Att observera vid val av värmekälla:',
          style: 'microtext',
          margin: [0, 7, 0, 3],
        },
        {
          text: 'Vid tidpunkten för framtagandet av bygglovshandlingar utför BRA HUS en energibalansberäkning på ert hus.Vid detta tillfälle kan en ändrig av vald värmekälla komma att vara nödvändig för att klara BBR- kraven gällande energiförbrukning och kraven på högsta tillåtna installerade effekt. Om vald värmekälla behöver ändras så kan detta medföra ökade investeringskostnader för dig som köpare.',
          style: 'microtext',
        },
        {
          text: 'Med Vänlig Hälsning',
          style: 'footerTop',
        },
        {
          image: 'logo',
          width: 15,
          style: 'footerPic',
        },
        {
          text: 'Agent för BRA HUS / ',
        },
        {
          text: currentCase.userName,
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 23, 0, 4],
        },
        microtext: {
          italics: true,
          fontSize: 6,
          margin: [0, 0, 0, 4],
        },
        boldBody: {
          bold: true,
        },
        footerPic: {
          margin: [0, 10, 0, 10],
        },
        footerTop: {
          margin: [0, 30, 0, 0],
        },
      },
      defaultStyle: {
        fontSize: 8,
      },
      images: {
        logo,
      },
    };
    return definition;
  };

export default getQuoteDocument;
