import { ICalculation, IVersion } from 'models/calculationModel';
import { ICase } from 'models/models';
import { IPriceResponse } from 'models/priceModel';
import { getDeliveryDeclarationCustomerPage } from './deliveryDeclarationCustomerPages';
import { getDeliveryDeclarationData } from './deliveryDeclarationData';
import deliveryDeclarationFrontPages from './deliveryDeclarationFrontPages';
import { frontPageImage } from './deliveryDeclarationImage';
import { logo } from './helpers';

type Args = {
  version: IVersion;
  currentCase: ICase;
  price: IPriceResponse;
  calculation: ICalculation;
  type: string;
};

const getDeliveryDeclarationDocument =
  (gdpr: boolean) =>
  ({ currentCase, price, calculation, type, version }: Args) => {
    const isDeliveryDeclaration = type === 'deliveryDeclaration';
    const title = isDeliveryDeclaration ? 'Leveransdeklaration' : 'Tillverkningsorder';
    const doc = {
      info: {
        title,
      },
      // content: [deliveryDeclarationFrontPages(version) /* this.data.customerPage, this.data.newLayout */],
      content: [
        deliveryDeclarationFrontPages(price, calculation, currentCase, isDeliveryDeclaration),
        isDeliveryDeclaration
          ? null
          : getDeliveryDeclarationCustomerPage(currentCase, price, calculation, version, gdpr),
        getDeliveryDeclarationData(price),
      ],

      pageBreakBefore: (
        currentNode: any,
        followingNodesOnPage: any /* nodesOnNextPage, previousNodesOnPage */,
      ) => {
        if (
          currentNode.style === 'subSectionHeadline' ||
          currentNode.style === 'subProductSubSectionHeadLine'
        ) {
          let closestTable;
          for (let i = 0; i < followingNodesOnPage.length; i += 1) {
            const n = followingNodesOnPage[i];
            if (n.style === 'infoTable') {
              closestTable = n;
              break;
            }
          }
          if (closestTable) {
            if (
              closestTable.pageNumbers.length > 1 ||
              closestTable.startPosition.pageNumber > currentNode.startPosition.pageNumber
            ) {
              return true;
            }
          }
        }

        return false;
      },

      footer: (/* page, pages */) => {
        return {
          columns: [
            {
              margin: [30, 0, 0, 0],
              text: `Bra Hus, ${title} ${currentCase.caseNumber}-${version.version}`,
            },
            {},
          ],
        };
      },

      styles: {
        header: {
          fontSize: 18,
          bold: true,
        },
        bold: {
          bold: true,
        },
        boldList: {
          bold: true,
          margin: [5, 5, 0, 0],
        },
        microtext: {
          italics: true,
          fontSize: 6,
        },
        tableHeadRow: {
          fillColor: '#b9c4a5',
          border: [false, false, false, false],
        },
        infoTable: {
          margin: [0, 10, 0, 0],
        },
        sectionHeadline: {
          fontSize: 18,
          bold: true,
          fillColor: '#DDDDDD',
          margin: [0, 20, 0, 0],
          border: [false, false, false, false],
        },
        subSectionHeadline: {
          fontSize: 14,
          bold: true,
          margin: [0, 18, 0, 0],
        },
        subProductSubSectionHeadLine: {
          fontSize: 10,
          bold: true,
          italics: true,
          margin: [10, 10, 0, 0],
        },
        sectionSubTitle: {
          fontSize: 10,
          margin: [0, 10, 0, 10],
        },
        tableHeader: {
          bold: true,
        },
        tableMicroText: {
          fontSize: 6,
        },

        hr: {
          margin: [0, 5, 0, 0],
        },
        date: {
          margin: [0, 20, 0, 10],
        },
        // INFOPAGES
        infoPages_sectionTitle: {
          fontSize: 14,
          bold: true,
          margin: [0, 18, 0, 0],
          italics: true,
        },
        infoPages_sectionSubTitle: {
          fontSize: 12,
          bold: true,
          margin: [0, 18, 0, 0],
          italics: true,
        },
        infoPages_paragraph: {
          fontSize: 11,
          margin: [0, 20, 0, 0],
        },
      },
      defaultStyle: {
        fontSize: 8,
      },
      images: {
        logo,
        frontPage: frontPageImage,
      },
    };
    return doc;
  };

export default getDeliveryDeclarationDocument;
