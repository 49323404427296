import { useCurrentCase } from 'api/case';
import { CaseStatuses } from 'models/models';
import { useCurrentUser } from './useCurrentUser';
import { useIsViewingOlderVersion } from './useIsViewingOlderVersion';

export const useCalculationIsDisabled = () => {
  const { currentUser } = useCurrentUser();
  const { currentCase } = useCurrentCase();
  const version = useIsViewingOlderVersion();

  // const { search } = useLocation();
  // const query = new URLSearchParams(search);
  // const version = query.get('version');

  const isDisabled =
    currentUser?.isSemiAdmin ||
    ((currentCase?.status || 0) >= CaseStatuses.SIGNED_CONSTRUCITON_ORDER && !currentUser?.isAdmin) ||
    Boolean(version);
  return isDisabled;
};
