import { useState, useEffect } from 'react';
import { IAdminProduct, IAdminProductGroup } from 'models/adminModels';
// import Table from 'ui-library/Table';
import { Table } from 'react-bootstrap';
import cloneDeep from 'lodash/cloneDeep';
import { useRecoilState } from 'recoil';
import { changedAdminProductsAtom } from 'atoms';
import Button from 'ui-library/Button';
import styled from 'styled-components';
import { useUpdateProducts } from 'Admin/api/product';

import { ProductTableRow } from './ProductTableRow';

const ButtonCell = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  border: none !important;
`;

type Props = {
  products: IAdminProduct[];
  productGroup: IAdminProductGroup;
};
const ProductTable = ({ products, productGroup }: Props) => {
  const [editingProducts, setEditingProducts] = useState(products);
  const [changedProductsList, setChangedProductsList] = useRecoilState(changedAdminProductsAtom);
  const { mutate: updateProducts, isLoading: isSavingProducts } = useUpdateProducts();

  useEffect(() => {
    setEditingProducts(products);
  }, [products]);

  const handleProductChange = (product: IAdminProduct) => {
    const idx = editingProducts.findIndex((pr) => pr.productNumber === product.productNumber);
    const arr = cloneDeep(editingProducts);
    arr[idx] = product;

    if (!changedProductsList?.find((pr) => pr.productNumber === product.productNumber)) {
      setChangedProductsList([...changedProductsList, product]);
    }
    setEditingProducts(arr);
  };
  const updateAllChanged = () => {
    if (changedProductsList.length > 0) {
      const productsToSave = editingProducts.filter((pr) =>
        changedProductsList.some((ch) => ch.productNumber === pr.productNumber),
      );
      updateProducts(productsToSave, {
        onSuccess: () => {
          setChangedProductsList([]);
        },
      });
    }
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Standard</th>
          <th>Benänming</th>
          <th>Brahus nettopris</th>
          <th>Pris ex. moms</th>
          <th>Pris ink. moms</th>
          <th>Diff mot standard</th>
          <th>Avdrag ink. moms</th>
          <th>
            <ButtonCell>
              <Button
                disabled={changedProductsList.length === 0 || isSavingProducts}
                onClick={updateAllChanged}
                size="small"
              >
                Spara alla
              </Button>
            </ButtonCell>
          </th>
        </tr>
      </thead>
      <tbody>
        {editingProducts.map((product) => {
          return (
            <ProductTableRow
              key={`${productGroup.productGroupNumber}-${product.productNumber}`}
              product={product}
              productGroup={productGroup}
              onChange={handleProductChange}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

export default ProductTable;
