import { IAdminProduct, IAdminProductGroup } from 'models/adminModels';
import Modal from 'ui-library/Modal';
import EditProductForm from 'Admin/Forms/EditProduct';

type Props = {
  show: boolean;
  onClose: () => void;
  product: IAdminProduct;
  adminProductGroup?: IAdminProductGroup;
  isNew?: boolean;
};

const EditProduct = ({ show, onClose, product, adminProductGroup, isNew }: Props) => {
  return (
    <Modal show={show} onClose={onClose} header={`${isNew ? 'Lägg till' : 'Editera'} produkt`} size="medium">
      <EditProductForm
        product={product}
        adminProductGroup={adminProductGroup}
        isNew={isNew}
        onClose={onClose}
      />
    </Modal>
  );
};

export default EditProduct;
