import { useMutation, useQuery, useQueryClient } from 'react-query';
import { adFetch } from 'adalConfig';
import { ICalculation, ISubProduct, IVersion } from 'models/calculationModel';
import { useGlobalStateValue } from 'stateContext';
import { useSetProductValue } from 'api/price';
import { useCurrentCase } from 'api/case';
import { useSetRecoilState } from 'recoil';
import { calculationHasChangesAtom } from 'atoms';
import { contractorTypeNames } from '../constants';

const useCalculationFromVersion = (version?: IVersion) => {
  const { currentCase, caseNumber } = useCurrentCase();
  const query = useQuery<ICalculation>(
    ['calculation', caseNumber, version?.version],
    () => adFetch<ICalculation>(`/calculation?caseId=${currentCase?.id}&houseVersion=${version?.version}`),
    {
      enabled: Boolean(currentCase?.id && version),
      staleTime: Infinity,
    },
  );

  return query;
};

type SaveCalculationRequest = {
  calculation: ICalculation;
  updateCalculationDate?: boolean | null;
  newCalculationDate?: string | null;
};
const useSaveCalculation = () => {
  const setCalculationHasChanges = useSetRecoilState(calculationHasChangesAtom);
  const { currentCase } = useCurrentCase();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ({ calculation, updateCalculationDate = null, newCalculationDate = null }: SaveCalculationRequest) =>
      adFetch(
        `/calculation?caseId=${currentCase?.id}&updateCalculationDate=${updateCalculationDate}&newCalculationDate=${newCalculationDate}`,
        {
          method: 'PUT',
          body: JSON.stringify(calculation),
        },
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['calculation']);
        queryClient.invalidateQueries(['versions']);
        queryClient.invalidateQueries(['latestCases']);
        setCalculationHasChanges(false);
      },
    },
  );
  return mutation;
};

const useSaveTemplateCalculation = () => {
  const mutation = useMutation((calculation: ICalculation) =>
    adFetch('/calculation/housetemplate', {
      method: 'PUT',
      body: JSON.stringify(calculation),
    }),
  );
  return mutation;
};

const useMergeGarageContractorProducts = () => {
  const { currentCalculation } = useGlobalStateValue();
  const setProductValue = useSetProductValue(0);

  const filterGarageProducts = (subProduct: ISubProduct) =>
    subProduct.products[0].productData.productGroupData.rules.isGarageProduct;

  const mergeGarageContractorProducts = (template: ICalculation) => {
    const newContractorCalculation = { ...currentCalculation.currentHouse.house.contractorCalculation };

    contractorTypeNames.forEach((type) => {
      const unresolved =
        template.currentHouse.house.contractorCalculation[type].fieldsThatRequireInput.subProducts.filter(
          filterGarageProducts,
        );
      const resolved =
        template.currentHouse.house.contractorCalculation[type].resolvedFields.subProducts.filter(
          filterGarageProducts,
        );

      unresolved.forEach((subProduct) => {
        const idx = currentCalculation?.currentHouse.house.contractorCalculation[
          type
        ].fieldsThatRequireInput.subProducts.findIndex((sp) => sp.productGroup === subProduct.productGroup);
        (newContractorCalculation[type].fieldsThatRequireInput.subProducts[idx] || {}).products =
          subProduct?.products;
      });
      resolved.forEach((subProduct) => {
        const idx = currentCalculation?.currentHouse.house.contractorCalculation[
          type
        ].resolvedFields.subProducts.findIndex((sp) => sp.productGroup === subProduct.productGroup);
        (newContractorCalculation[type].resolvedFields.subProducts[idx] || {}).products =
          subProduct?.products;
      });
    });

    setProductValue('contractorCalculation', null, newContractorCalculation);
  };
  return mergeGarageContractorProducts;
};

export {
  useCalculationFromVersion,
  useSaveCalculation,
  useSaveTemplateCalculation,
  useMergeGarageContractorProducts,
};
