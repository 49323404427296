import { useProductGroupDependencies } from 'Admin/AdminProductGroup/useHasDependencies';
import { useUpdateProductGroup, useUpdateSubProductField } from 'Admin/api/product';
import { formatDate } from 'helpers';
import { IAdminProductGroup, IAdminSubProduct } from 'models/adminModels';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Box from 'ui-library/Box';
import Button, { FormSubmitWrapper } from 'ui-library/Button';
import Checkbox from 'ui-library/Checkbox';
import InputField from 'ui-library/InputField';
import LabeledCollapse from 'ui-library/LabeledCollapse';
import ControlledSelect from 'ui-library/Select/ControlledSelect';
import { ProductGroupSubProductField } from './ProductGroupSubProductField';
import ProductGroupSubProducts from './SubProducts';

const validationTypeOptions = [
  {
    value: 'integer',
    label: 'Heltal',
  },
  {
    value: 'decimal',
    label: 'Decimaltal',
  },
  {
    value: 'notempty',
    label: 'Ej tom',
  },
];

type Props = {
  adminProductGroup: IAdminProductGroup;
};
const EditProductGroupForm = ({ adminProductGroup }: Props) => {
  // IAdminProductGroup should be used as type here instead of <any>, but it leads to ts error "Type of property 'adminSubProductGroups' circularly references itself in mapped type '{ [K in key..."
  // <Omit<IAdminProductGroup, 'adminSubProductGroups'>> gets rid of the problem as well, but it also gets rid of subProducts..
  const form = useForm<any>({
    defaultValues: {
      ...adminProductGroup,
      validFrom: formatDate(adminProductGroup?.validFrom || new Date()),
    },
  });
  const [subProductField, setSubProductField] = useState<IAdminSubProduct>();
  const { hasDependencies, productGroupDependencies } = useProductGroupDependencies(adminProductGroup);

  const { mutate: updateProductGroup } = useUpdateProductGroup();
  const { mutate: updateSubProductField } = useUpdateSubProductField();
  const {
    register,
    handleSubmit,
    control,
    // formState: { isDirty },
  } = form;
  const onSubmit = (productGroup: IAdminProductGroup) => {
    updateProductGroup(
      { ...productGroup, validFrom: null },
      {
        onSuccess: () => {
          toast.success('Produktgrupp sparad');
          if (subProductField) {
            const { validFrom: _validFrom, ...rest } = subProductField;
            updateSubProductField(rest);
          }
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Row>
          <Col md="6" lg="6">
            <InputField {...register('name')} label="Namn" />
            <InputField {...register('standardPriceIncludingVat')} label="Standardpris, ink. moms" />
            <InputField {...register('standardPriceExcludingVat')} label="Standardpris, ex. moms" />
            <InputField {...register('standardDeductionPrice')} label="Avdragspris" />
            <Checkbox {...register('includeStandardInOptions')} label="Inkludera standard som produkt" />
            <InputField {...register('standardProductName')} label="Namn för ej definierad standardprodukt" />
            <Checkbox
              {...register('hideQuantityAndUnitInDeliveryDeclaration')}
              label="Visa inte enhet eller antal i LD"
            />
            <Checkbox {...register('isAllowedToBeUndefined')} label="Är tillåten att vara odefinierad" />
            <Checkbox {...register('isGarageProduct')} label="Är garageprodukt" />
          </Col>
          <Col md="6" lg="6">
            <InputField {...register('validFrom')} label="Giltig från" readOnly />
            {hasDependencies && (
              <Box>
                <h5>Beroenden:</h5>
                <ul>
                  {productGroupDependencies?.map((pgDependency) => {
                    return <li key={pgDependency.id}>- {pgDependency.name}</li>;
                  })}
                </ul>
              </Box>
            )}
          </Col>
        </Row>
      </Container>
      <Box>
        <h5>Metadata</h5>
        <InputField {...register('metaData.title')} label="Titel (alternativt namn i t.ex. LD)" />
        <InputField {...register('metaData.description')} label="Beskrivning" />
        <InputField
          {...register('metaData.subProductHeadline')}
          label="Titel till underproduktkategori (LD)"
        />
      </Box>
      <Container>
        <Row>
          <Col md="4">
            <InputField {...register('metaData.unit')} label="Enhet" />
          </Col>
          <Col md="4">
            <InputField {...register('metaData.validationMin')} label="Validering (min)" />
          </Col>
          <Col md="4">
            <InputField {...register('metaData.validationMax')} label="Validering (max)" />
          </Col>
        </Row>
      </Container>
      <ProductGroupSubProductField
        adminProductGroup={adminProductGroup}
        subProductField={subProductField}
        setSubProductField={setSubProductField}
      />
      <ControlledSelect
        label="Valideringstyp"
        options={validationTypeOptions}
        control={control}
        name="validationType"
        placholder="Välj valideringstyp"
      />
      <LabeledCollapse label="Etiketter och placeholders">
        <Row>
          <Col md="6">
            <InputField {...register('labels.descriptionLabel')} label="Beskrivning, etikett" />
          </Col>
          <Col md="6">
            <InputField {...register('labels.descriptionPlaceholder')} label="Beskrivning, placeholder" />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <InputField
              {...register('labels.externalDeductionPriceLabel')}
              label="Externt avdragspris, etikett"
            />
          </Col>
          <Col md="6">
            <InputField
              {...register('labels.externalDeductionPricePlaceholder')}
              label="Externt avdragspris, placeholder"
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <InputField {...register('labels.externalPricePerPieceLabel')} label="Externt pris/st, etikett" />
          </Col>
          <Col md="6">
            <InputField
              {...register('labels.externalPricePerPiecePlaceholder')}
              label="Externt pris/st, placeholder"
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <InputField
              {...register('labels.externalTotalPriceLabel')}
              label="Externt pris totalt, etikett"
            />
          </Col>
          <Col md="6">
            <InputField
              {...register('labels.externalTotalPricePlaceholder')}
              label="Externt pris totalt, placeholder"
            />
          </Col>
        </Row>
      </LabeledCollapse>
      <LabeledCollapse label="Tillgänglig om hustyp är:">
        <Box>
          <Checkbox {...register('availableIfBasement')} label="Källare" />
          <Checkbox {...register('availableIfIntegratedGarage')} label="Integrerat garage" />
          <Checkbox {...register('availableIfSeparateGarage')} label="Fristående garage" />
          <Checkbox {...register('availableIfOneFloor')} label="Enplans" />
          <Checkbox {...register('availableIfOneAndAHalfFloors')} label="1 1/2-plans" />
          <Checkbox {...register('availableIfOneAndThreeQuartersFloors')} label="1 3/4-plans" />
          <Checkbox {...register('availableIfSplitLevel')} label="Souteräng" />
          <Checkbox {...register('availableIfTwoFloors')} label="Tvåplans" />
          <Checkbox {...register('availableIfCustomHouse')} label="Specialhus" />
          <Checkbox {...register('availableIfCatalogueHouse')} label="Kataloghus" />
          <Checkbox {...register('availableIfWildStrawberry')} label="Smultronställe" />
        </Box>
      </LabeledCollapse>
      <ProductGroupSubProducts form={form} productGroup={adminProductGroup} />
      <FormSubmitWrapper>
        <Button>Spara</Button>
      </FormSubmitWrapper>
    </form>
  );
};

export default EditProductGroupForm;
