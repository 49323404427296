import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { useSelectOptions } from 'hooks/useSelectOptions';
import { IProduct } from 'models/calculationModel';
import { SelectOption } from 'models/models';
import { useGlobalStateValue } from 'stateContext';
import Label from 'ui-library/Label';
import { useSetProductValue } from 'api/price';
import { ExtraProductFields, hasExtraFields } from './ExtraProductFields';

type Props = {
  path: string;
  disabled?: boolean;
  options?: SelectOption[];
  label?: string;
  hideLabel?: boolean;
  inProduct?: IProduct;
};

const StyledSelectWrapper = styled.div`
  /* margin-bottom: 1rem; */
`;

const ProductSelect = ({ path, disabled, options, label, hideLabel = false, inProduct }: Props) => {
  const { getOptionsForProduct } = useSelectOptions();
  const { getValue } = useGlobalStateValue();
  const setProductValue = useSetProductValue(0);

  const product = inProduct || getValue<IProduct>(path);
  const opts = options || getOptionsForProduct(product?.productData.productGroupData.productGroupNumber);

  const selectedOption = useMemo(() => {
    return product?.productData.productNumber !== -1
      ? {
          value: product?.productData.productNumber,
          label: product?.productData.name || '',
          data: product?.productData,
        }
      : null;
  }, [product]);

  useEffect(() => {
    if (opts.length > 1 && !selectedOption) {
      setProductValue(path, 'productData', opts[0].data, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (o: any) => {
    setProductValue(path, 'productData', o.data);
  };

  if (!product) return null;
  const showExtraFields = hasExtraFields(product);

  const lbl = label || product.productData.productGroupData.name || product.productData.name || 'Välj:';
  const showLabel = !hideLabel && opts?.length > 1;

  const showControl = opts.length > 1 || showExtraFields;

  if (!showControl) return null;

  return (
    <>
      <StyledSelectWrapper>
        {opts.length > 1 && (
          <>
            {showLabel && <Label>{lbl}</Label>}
            <Select
              onChange={handleChange}
              defaultValue={selectedOption || opts[0]}
              options={opts}
              disabled={disabled}
              isDisabled={disabled}
            />
          </>
        )}
        <ExtraProductFields product={product} disabled={disabled} path={path} />
      </StyledSelectWrapper>
    </>
  );
};

export default ProductSelect;
