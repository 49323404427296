export const defaultTheme = {
  colors: {
    primary: '#e30613',
    primaryFocus: '#ca0511',
    border: 'rgb(204, 204, 204)',
    borderLight: '#cccccc',
    fontColor: '#333333',
    bgDarkest: '#0e0e0e',
    bgDark: '#404040',
    bgLight: '#efefef',
    gray: '#9b9b9b',
    statuses: {
      noContract: '#72B9E9',
      pendingContract: '#357AA9',
      signedContract: '#833DB7',
      pendingConstructionOrder: '#8EC190',
      signedConstructionOrder: '#339837',
    },
    messages: {
      warning: '#ea9903',
      warningDark: '#e27300',
      success: '#0fa25a',
      successDark: '#028042',
    },
  },
};
