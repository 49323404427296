import { useHouseProperties } from 'hooks/useHouseProperties';
import { FloorName } from 'models/models';
import ProductComponent from '../ProductComponent';

type Props = {
  floor?: FloorName;
  disabled?: boolean;
};
const HeatingAndVentilation = ({ floor, disabled }: Props) => {
  const { isTwoFloors } = useHouseProperties();
  if (isTwoFloors) {
    return (
      <div>
        <ProductComponent
          path="parameters.heatingAndVentilationTwoFloors"
          floor={floor}
          disabled={disabled}
        />
      </div>
    );
  }
  return (
    <div>
      <ProductComponent path="parameters.heatingAndVentilationOneFloor" floor={floor} disabled={disabled} />
    </div>
  );
};

export default HeatingAndVentilation;
