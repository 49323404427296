import { AuthenticationContext } from 'react-adal';
import { AD_CLIENT_ID, AD_TENANT_ID, BACKEND_URL } from './constants';

const cacheLocation = 'localStorage' as const;
export const adalConfig = {
  tenant: AD_TENANT_ID ?? '',
  clientId: AD_CLIENT_ID ?? '',
  cacheLocation,
  redirectUri: window.location.origin,
};

export const authContext = new AuthenticationContext(adalConfig);
authContext.handleWindowCallback();

const handleResponse = (data: Response) => {
  if (data.status >= 400 && data.status < 500) {
    return data.text().then((responseStr) => {
      if (responseStr !== '') {
        const responseData = JSON.parse(responseStr);
        throw responseData;
      }
      throw new Error('Resource not found');
    });
  }

  if (data.status > 500) {
    throw new Error('Failed to fetch');
  }
  return data.text().then((responseStr) => {
    if (responseStr) {
      return JSON.parse(responseStr);
    }
    return null;
  });
};

const fetchFn = (url: string, options: any = {}, token: string | null, otherurl?: string) => {
  const opts = {
    ...options,
    headers: {
      ...options?.headers,
      Accept: 'application/json, text/plain, */*',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };
  return fetch(`${otherurl || BACKEND_URL}${url}`, opts).then(handleResponse);
};

export const adFetch = async <T extends unknown>(
  url: string,
  options: any = {},
  otherurl?: string,
): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    authContext.acquireToken(`${adalConfig.clientId}`, (_err, token) => {
      if (_err) {
        authContext.acquireTokenPopup(`${adalConfig.clientId}`, null, null, (_e, newToken) => {
          fetchFn(url, options, newToken, otherurl)
            .then((data) => {
              resolve(data as any);
              return data;
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
      return fetchFn(url, options, token, otherurl)
        .then((data) => {
          resolve(data as any);
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
};

// export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
