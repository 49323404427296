import { getHouseName } from 'helpers';
import { ICalculation } from 'models/calculationModel';
import { catalogueHouseChanges } from '../../constants';

export const pageBreak = () => {
  return {
    text: '',
    pageBreak: 'after',
  };
};

export const formDataTableCell = (microText: string, dataText = '', colSpan = 0) => {
  const cell = {
    text: [
      {
        text: `${microText}\n`,
        style: 'tableMicroText',
      },
      {
        text: dataText,
        style: { bold: true },
      },
    ],
    colSpan,
    border: [true, false, true, true],
  };
  return cell;
};

export const houseNameWithChange = (calculation: ICalculation) => {
  // const { catalogueHouse, wildStrawBerry } = calculation.currentHouse.house.parameters;

  // let templateHouseProduct = null;
  // if (catalogueHouse.productData.productNumber > -1) {
  //   templateHouseProduct = catalogueHouse;
  // }
  // if (wildStrawBerry.productData.productNumber > -1) {
  //   templateHouseProduct = wildStrawBerry;
  // }

  // if (!templateHouseProduct) {
  //   return 'Specialhus';
  // }
  // const catalogueHouseName = templateHouseProduct.productData.name;

  const houseName = getHouseName(calculation);

  const catalogueHouseChanged =
    !calculation.currentHouse.house.parameters.changedCatalogueHouse.productData.rules.isNoneProduct;
  let changeLabel = '';
  if (catalogueHouseChanged) {
    switch (calculation.currentHouse.house.parameters.changedCatalogueHouse.productData.productNumber) {
      case catalogueHouseChanges.MINOR_CHANGE:
        changeLabel = '(ä)';
        break;
      case catalogueHouseChanges.MAJOR_CHANGE_ONE_FLOOR:
      case catalogueHouseChanges.MAJOR_CHANGE_TWO_FLOOR:
        changeLabel = '(Ä)';
        break;
      default:
        changeLabel = '';
    }
  }

  return `${changeLabel} ${houseName}`;
};
