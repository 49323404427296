import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import NavTabs from 'ui-library/NavTabs';

type Props = {
  isHouseTemplate?: boolean;
};
const ContractorNav = ({ isHouseTemplate = false }: Props) => {
  const { caseNumber, templateCalculationId } =
    useParams<{ caseNumber: string; templateCalculationId: string }>();
  const id = isHouseTemplate ? templateCalculationId : caseNumber;
  const urlBase: string = isHouseTemplate ? 'husmall' : 'case';

  const links = [
    {
      to: `/${urlBase}/${id}/entreprenad/bygg`,
      label: 'Bygg',
    },
    {
      to: `/${urlBase}/${id}/entreprenad/vvs`,
      label: 'VVS',
    },
    {
      to: `/${urlBase}/${id}/entreprenad/el`,
      label: 'El',
    },
    {
      to: `/${urlBase}/${id}/entreprenad/grund`,
      label: 'Grund',
    },
    {
      to: `/${urlBase}/${id}/entreprenad/kakel`,
      label: 'Kakel',
    },
    {
      to: `/${urlBase}/${id}/entreprenad/malning`,
      label: 'Målning',
    },
    {
      to: `/${urlBase}/${id}/entreprenad/malning-utvandigt`,
      label: 'Målning, utvändigt',
    },
    {
      to: `/${urlBase}/${id}/entreprenad/generalentreprenad`,
      label: 'Generalentreprenadsarvode',
    },
  ];
  return (
    <NavTabs>
      {links.map(({ to, label }) => (
        <Link to={to} key={to}>
          {label}
        </Link>
      ))}
    </NavTabs>
  );
};

export default ContractorNav;
