import { ContractorRating } from 'Admin/Components/Contractors/ContractorRating';
import AddContractor from 'Admin/Modals/AddContractor';
import { useGetContractors } from 'api/contractors';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from 'ui-library/Box';
import Button from 'ui-library/Button';
import List from 'ui-library/List';
import Loader from 'ui-library/Loader';
import { Header } from './Customers';

const ContractorPage = () => {
  const [showAddContractor, setShowAddContractor] = useState(false);
  const { data: contractors, isLoading } = useGetContractors();
  if (isLoading) {
    return <Loader label="Hämtar entreprenörer" />;
  }
  return (
    <div>
      <Box>
        <Header>
          <h2>Entreprenörer</h2>
          <Button onClick={() => setShowAddContractor(true)}>+ Lägg till entreprenör</Button>
        </Header>
        <List>
          {contractors?.map(({ id, name, rating }) => {
            return (
              <Link key={id} to={`/admin/entreprenorer/${id}`}>
                {name}
                <ContractorRating rating={rating || 0} />
              </Link>
            );
          })}
        </List>
        <AddContractor show={showAddContractor} onClose={() => setShowAddContractor(false)} />
      </Box>
    </div>
  );
};

export default ContractorPage;
