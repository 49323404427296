import { useState } from 'react';
import Select from 'react-select';
import { IHouseTemplate } from 'models/calculationModel';
import { CaseTypes, ICustomer } from 'models/models';
import Button from 'ui-library/Button';
import Label from 'ui-library/Label';
import Modal from 'ui-library/Modal';
import { useCreateCase, useGetHouseTemplates } from 'Admin/api';
import Loader from 'ui-library/Loader';
import { caseTypes } from '../../constants';

type Props = {
  show: boolean;
  onClose: () => void;
  customer?: ICustomer;
};

const getTemplateOptions = (houseTemplates?: IHouseTemplate[], selectedCaseType?: CaseTypes) => {
  const catalogueHouseOptions = houseTemplates
    ?.filter((template) => template.catalogueHouseProductNumber > -1)
    .map((template) => ({ label: template.name, value: template.calculationId }));
  const wildsStrawberryHouseOptions = houseTemplates
    ?.filter((template) => template.wildStrawberryHouseProductNumber > -1)
    .map((template) => ({ label: template.name, value: template.calculationId }));

  let templateOptions: any = [];
  if (selectedCaseType === CaseTypes.CATALOGUE_HOUSE) {
    templateOptions = catalogueHouseOptions;
  }
  if (selectedCaseType === CaseTypes.WILD_STRAWBERRY) {
    templateOptions = wildsStrawberryHouseOptions;
  }

  return templateOptions;
};

const AddCase = ({ show, onClose, customer }: Props) => {
  const [selectedCaseType, setSelectedCaseType] = useState<CaseTypes | undefined>(CaseTypes.SPECIAL_HOUSE);
  const [selectedTemplateType, setSelectedTemplateType] = useState<number | undefined>();
  const { houseTemplates, isLoading: loadingTemplates } = useGetHouseTemplates();

  const templateOptions = getTemplateOptions(houseTemplates, selectedCaseType);

  const { createCase, isLoading } = useCreateCase(
    customer,
    selectedCaseType,
    houseTemplates?.find(
      (template) => template.calculationId === (selectedTemplateType || templateOptions?.[0]?.value),
    ),
  );

  const houseTypeOptions = Object.entries(caseTypes).map(([key, value]) => ({
    label: value.label,
    value: Number(key),
  }));

  return (
    <Modal show={show} onClose={onClose} header="Lägg till ärende">
      <Label>Välj hustyp</Label>
      <Select
        value={houseTypeOptions.find((opt) => opt.value === selectedCaseType)}
        options={houseTypeOptions}
        onChange={(opt) => {
          setSelectedCaseType(opt?.value);
        }}
      />
      {[CaseTypes.CATALOGUE_HOUSE, CaseTypes.WILD_STRAWBERRY].includes(selectedCaseType as CaseTypes) && (
        <>
          <Label>Välj {caseTypes[selectedCaseType!].label}</Label>
          <Select
            options={templateOptions}
            value={
              templateOptions?.find((opt: any) => opt.value === selectedTemplateType) ||
              templateOptions?.[0].value
            }
            onChange={(opt) => {
              setSelectedTemplateType(opt.value);
            }}
            isLoading={loadingTemplates}
            placeholder={loadingTemplates ? 'Hämtar mallar...' : 'Välj mall'}
          />
        </>
      )}
      <Button onClick={createCase} disabled={isLoading}>
        Skapa {isLoading && <Loader size="small" />}
      </Button>
    </Modal>
  );
};

export default AddCase;
