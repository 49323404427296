import styled from 'styled-components';
import Button from 'ui-library/Button';

const BigButton = styled(Button)`
  font-size: 1rem;
  line-height: 1.125rem;
  height: 3.75rem;
  background-color: ${({ theme }) => theme.colors.bgDark};
  box-shadow: 2px 4px 5px 0px rgb(0 0 0 / 38%);
  padding: 0.75rem 2.5rem;
  position: absolute;
  right: -3rem;
  top: 0;
  padding: 0;
  &:hover {
    background-color: ${({ theme }) => theme.colors.bgDarkest};
  }
  a {
    padding: 0.75rem 2.5rem;
    color: inherit;
    text-decoration: none;
  }
`;

export default BigButton;
