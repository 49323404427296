import styled from 'styled-components';
import { ICustomer } from 'models/models';
import Button from 'ui-library/Button';
import Message from 'ui-library/Message';

const WarningLabel = styled.span`
  color: #fff;
  background-color: ${({ theme }) => theme.colors.messages.warningDark};
  padding: 0.2em 0.6em 0.3em;
  border-radius: 0.25em;
  text-align: center;
  width: 100%;
  display: inline-block;
  font-size: 0.75rem;
`;

type Props = {
  duplicateCustomers: ICustomer[];
  createAnyway: () => void;
};
const DuplicateCustomerMessage = ({ duplicateCustomers, createAnyway }: Props) => {
  return (
    <Message variant="warning">
      <WarningLabel>OBS! Denna kund finns eventuellt redan inlagd i kunddatabasen</WarningLabel>
      <Button type="button" onClick={createAnyway}>
        Skapa ändå
      </Button>
      <p>Möjliga kunder:</p>
      <ul>
        {duplicateCustomers.map((customer) => (
          <li key={customer.id}>
            {customer.firstName} {customer.lastName}
          </li>
        ))}
      </ul>
    </Message>
  );
};

export default DuplicateCustomerMessage;
