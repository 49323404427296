/* eslint-disable import/no-cycle */
import styled from 'styled-components';
import { IProduct } from 'models/calculationModel';
import Textarea from 'ui-library/Textarea';
import CalculationInput from 'components/CalculationInput';
import { useSetProductValue } from 'api/price';
import { useEffect, useRef } from 'react';

const ProductFieldsWrapper = styled.div`
  padding: 1rem;
  margin-bottom: 0;
  background-color: #d9d9d9; // ${({ theme }) => theme.colors.bgDark};
`;

type ExtraProductFieldsProps = {
  product: IProduct;
  disabled?: boolean;
  path: string;
};

export const hasExtraFields = (product: IProduct) => {
  return (
    product.productData.rules.requiresQuantity ||
    product.productData.rules.requiresDescription ||
    product.productData.rules.requiresExternalTotalPrice ||
    product.productData.rules.requiresExternalPricePerPiece ||
    product.productData.rules.requiresExternalDeductionPrice
  );
};

export const ExtraProductFields = ({ product, disabled = false, path }: ExtraProductFieldsProps) => {
  const setProductValue = useSetProductValue();

  const descriptionRef = useRef<any>(null);
  const showExtraFields = hasExtraFields(product);
  const handleDescriptionChange = (e: any) => {
    setProductValue(path, 'description', e.target.value);
  };

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.addEventListener('input', handleDescriptionChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!showExtraFields) return null;

  const getPlaceholderLabelText = (type: string, defaultText?: string) => {
    if ((product.productData.labels as any)[type]) {
      return (product.productData.labels as any)[type];
    }
    if ((product.productData.productGroupData.labels as any)[type]) {
      return (product.productData.productGroupData.labels as any)[type];
    }
    return defaultText;
  };

  const description = (
    <Textarea
      label={getPlaceholderLabelText('descriptionLabel', 'Beskrivning')}
      defaultValue={product.description || ''}
      ref={descriptionRef}
      // onChange={handleDescriptionChange}
    />
  );
  const quantityInput = (
    // <InputField label="Antal" addonText="Antal" defaultValue={product.quantity} disabled={disabled} />
    <CalculationInput path={path} property="quantity" disabled={disabled} />
  );

  const externalPricePerPieceInput = (
    // <CalculationInput path={path} property="quantity" disabled={disabled} />
    <CalculationInput
      label={getPlaceholderLabelText('externalPricePerPieceLabel', 'Extern offert (pris per produkt)')}
      addonText="Antal"
      defaultValue={product.externalPricePerPiece}
      property="externalPricePerPiece"
      disabled={disabled}
      path={path}
    />
  );

  const externalPriceTotalInput = (
    <CalculationInput
      label={getPlaceholderLabelText('externalTotalPriceLabel', 'Extern offert (totalt pris)')}
      addonText="Kr"
      defaultValue={product.externalTotalPrice}
      property="externalTotalPrice"
      disabled={disabled}
      path={path}
    />
  );

  const externalDeductionInput = (
    <CalculationInput
      label={getPlaceholderLabelText('externalDeductionPriceLabel', 'Avdragspris')}
      addonText="Kr"
      defaultValue={product.externalDeductionPrice}
      property="externalDeductionPrice"
      disabled={disabled}
      path={path}
    />
  );

  return (
    <ProductFieldsWrapper>
      {product.productData.rules.requiresDescription && description}
      {product.productData.rules.requiresQuantity && quantityInput}
      {product.productData.rules.requiresExternalPricePerPiece && externalPricePerPieceInput}
      {product.productData.rules.requiresExternalTotalPrice && externalPriceTotalInput}
      {product.productData.rules.requiresExternalDeductionPrice && externalDeductionInput}
    </ProductFieldsWrapper>
  );
};
