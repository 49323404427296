import { useCurrentCase } from 'api/case';
import DocumentViewer from 'components/DocumentViewer';
import VersionsTable from 'components/VersionsTable';
import { useCalculationVersions } from 'hooks/useCalculationVersions';
import ActionButtons from './ActionButtons';

const ContractPage = () => {
  const { calculationVersions } = useCalculationVersions();
  const { currentCase } = useCurrentCase();

  if (!calculationVersions) return <>Hämtar versioner...</>;
  return (
    <div>
      <DocumentViewer />
      <VersionsTable
        versions={calculationVersions.versions.filter((version) =>
          currentCase?.quotesSent.includes(version.version),
        )}
        actionButtons={({ version }: any) => <ActionButtons version={version} />}
      />
    </div>
  );
};

export default ContractPage;
