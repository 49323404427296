import { useUpdateVersionComment } from 'hooks/useCalculationVersions';
import { IVersion } from 'models/calculationModel';
import { useState } from 'react';
import { useGlobalStateValue } from 'stateContext';
import EditableField from 'ui-library/EditableField';

type Props = {
  version: IVersion;
};
const CommentField = ({ version }: Props) => {
  const [newComment, setNewComment] = useState(version.comment);
  const { mutate: updateComment } = useUpdateVersionComment();
  const { currentCalculation } = useGlobalStateValue();
  const handleUpdateComment = () => {
    updateComment({
      comment: newComment,
      version: version.version,
      calculationId: currentCalculation.calculationId,
    });
  };
  return (
    <EditableField
      value={version.comment}
      onChange={(value: any) => {
        setNewComment(value);
      }}
      onKeyPress={(e: any) => {
        if (e.key === 'Enter') {
          handleUpdateComment();
        }
      }}
    />
  );
};

export default CommentField;
