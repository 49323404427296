import { IProduct, ISubProduct } from 'models/calculationModel';

export const showControls = (product: IProduct, parentSubProduct?: ISubProduct) => {
  const showInput =
    parentSubProduct?.rules.renderInput &&
    !(
      product.productData.rules.requiresExternalDeductionPrice ||
      product.productData.rules.requiresExternalTotalPrice ||
      product.productData.rules.requiresExternalPricePerPiece
    );
  let showSelect =
    !product.productData.productGroupData.rules.isAllowedToBeUndefined &&
    !parentSubProduct?.rules.hasSpecificProduct;
  if (parentSubProduct?.rules.renderSelect) {
    showSelect = true;
  }

  return { showInput, showSelect };
};
