import { ISubProduct } from 'models/calculationModel';
import { FloorName } from 'models/models';
import { useGlobalStateValue } from 'stateContext';
import DynamicProductList from '../DynamicProductList';
import { TitleComponent } from '../ProductComponent/TitleComponent';

type Props = {
  floor: FloorName;
};
const Bathroom = ({ floor }: Props) => {
  const { getValue } = useGlobalStateValue();
  const bathroomSubProducts = getValue<ISubProduct[] | undefined>(`${floor}.bathrooms.subProducts`);
  const subProductIdx = bathroomSubProducts?.findIndex((subProduct) => subProduct.productGroup === 69);
  const subProduct = subProductIdx !== undefined ? bathroomSubProducts?.[subProductIdx] : undefined;

  if (!subProduct) return null;

  return (
    <>
      <TitleComponent nameOverride="WC, dusch och bad" type="banner" />
      <DynamicProductList path={`${floor}.bathrooms.subProducts[${subProductIdx}]`} subProduct={subProduct} />
    </>
  );
};

export default Bathroom;
