import React, { useEffect, useState } from 'react';
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

type SortableComponentProps<T> = {
  item?: T;
  idx: number;
};
type SortableProps<T> = {
  listItems: { id: string; data?: T }[];
  Component: React.FC<SortableComponentProps<T>>;
  onDragEnd: (sortedList: { id: string; data?: T }[]) => void;
};
export function SortableList<T>({ listItems, Component, onDragEnd }: SortableProps<T>) {
  const [items, setItems] = useState(listItems.map((item) => item.id));
  const [tmpListItems, setTmpListItems] = useState<{ id: string; data?: T }[]>(listItems);

  const sensors = useSensors(useSensor(PointerSensor));

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setItems((itms) => {
        const oldIndex = itms.findIndex((i) => i === active?.id);
        const newIndex = itms.findIndex((i) => i === over?.id);

        return arrayMove(itms, oldIndex, newIndex);
      });
      setTmpListItems((lis) => {
        const oldIndex = lis.findIndex((i) => i.id === active?.id);
        const newIndex = lis.findIndex((i) => i.id === over?.id);

        return arrayMove(lis, oldIndex, newIndex);
      });
    }
  }
  useEffect(() => {
    onDragEnd(tmpListItems);
  }, [onDragEnd, tmpListItems]);
  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((id, idx) => {
          const item = listItems.find((i) => i.id === id)?.data;
          return <Component key={id} item={item} idx={idx} />;
        })}
      </SortableContext>
    </DndContext>
  );
}
