import { useForm } from 'react-hook-form';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';
import { ICustomer } from 'models/models';
import { defaultTheme } from 'theme';
import Button, { FormSubmitWrapper } from 'ui-library/Button';
import InputField from 'ui-library/InputField';
import { useDeleteCustomer, useUpdateCustomer } from 'Admin/api/customer';

type Props = {
  customer?: ICustomer;
};
const CustomerDetailsForm = ({ customer }: Props) => {
  const { register, handleSubmit, formState } = useForm<ICustomer>({
    defaultValues: customer,
  });
  const history = useHistory();
  const { mutate: updateCustomer } = useUpdateCustomer();
  const { mutate: deleteCustomer } = useDeleteCustomer();
  const { isDirty } = formState;

  const onSubmit = (values: ICustomer) => {
    updateCustomer(values, {
      onSuccess: () => {
        toast.success('Kund sparad');
      },
    });
  };
  const onDelete = () => {
    if (customer && customer.id) {
      deleteCustomer(customer.id, {
        onSuccess: () => {
          // toast.success('Kund borttagen');
          history.push('/admin/kunder');
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputField label="Förnamn" {...register('firstName')} />
      <InputField label="Efternamn" {...register('lastName')} />
      <InputField label="Adress" {...register('address')} />
      <InputField label="Postadress" {...register('postalAddress')} />
      <InputField label="Epost" {...register('email')} />
      <InputField label="Telefon (Hem)" {...register('phoneHome')} />
      <InputField label="Telefon (Jobb)" {...register('phoneWork')} />
      <InputField label="Telefon (Mobil)" {...register('phoneCell')} />
      <InputField label="Personnr./Org. nr." {...register('personalIdentityNumber')} />
      <FormSubmitWrapper>
        <Button type="button" variant="secondary" onClick={onDelete}>
          Ta bort kund <FaRegTrashAlt color={defaultTheme.colors.primary} />
        </Button>
        <Button disabled={!isDirty}>Spara</Button>
      </FormSubmitWrapper>
    </form>
  );
};

export default CustomerDetailsForm;
