import styled from 'styled-components';
import { CaseStatuses } from 'models/models';

export const StyledChipLabel = styled.span<any>`
  cursor: pointer;
  text-align: center;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  background: ${({ theme }) => theme.colors.statuses.noContract};
  border-radius: 1rem;
  color: #fff;
  &.small {
    font-size: 0.75rem;
    line-height: 1;
    height: 1.5rem;
  }
  ${({ type, theme }) => {
    let bg = '#333';
    switch (type) {
      case CaseStatuses.NO_CONTRACT:
        bg = theme.colors.statuses.noContract;
        break;
      case CaseStatuses.PENDING_CONTRACT:
        bg = theme.colors.statuses.pendingContract;
        break;
      case CaseStatuses.SIGNED_CONTRACT:
        bg = theme.colors.statuses.signedContract;
        break;
      case CaseStatuses.PENDING_CONSTRUCTION_ORDER:
        bg = theme.colors.statuses.pendingConstructionOrder;
        break;
      case CaseStatuses.SIGNED_CONSTRUCITON_ORDER:
        bg = theme.colors.statuses.signedConstructionOrder;
        break;
      default:
    }
    // if (type === CaseStatuses.NO_CONTRACT) {
    //   bg = theme.colors.statuses.noContract;
    // }
    return `background: ${bg}`;
  }}
`;

export type Props = {
  label: string;
  type: number;
  small?: boolean;
  active?: boolean;
  onClick?: any;
};

const ChipLabel = ({ label, type, small, active, onClick }: Props) => {
  return (
    <StyledChipLabel
      onClick={onClick}
      className={`chip ${small ? 'small' : ''} ${active ? 'active' : ''}`}
      type={type}
    >
      {active && <>√</>}
      {label}
    </StyledChipLabel>
  );
};

export default ChipLabel;
