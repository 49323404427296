import { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import { useForm, useFieldArray } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { useGetCustomers } from 'Admin/api';
import { useCurrentCase, useUpdateCase } from 'api/case';
import { CaseStatuses, ICase, ICustomer } from 'models/models';
import Box from 'ui-library/Box';
import Loader from 'ui-library/Loader';
import Button, { FormSubmitWrapper } from 'ui-library/Button';
import ChipLabel, { StyledChipLabel } from 'ui-library/ChipLabel';
import InputField from 'ui-library/InputField';
import LabeledCollapse from 'ui-library/LabeledCollapse';
import ControlledSelect from 'ui-library/Select/ControlledSelect';
import { useGetUsers } from 'api/users';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { formatDate } from 'helpers';
import { useGlobalStateValue } from 'stateContext';
import styled from 'styled-components';
import { useSaveCalculation } from 'api/calculation';
import { FaMinusCircle } from 'react-icons/fa';
import { useSetRecoilState } from 'recoil';
import { caseChangedAtom } from 'atoms';
import { caseStatuses } from '../../constants';

const CaseFormHeader = styled.h5`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & ${StyledChipLabel} {
    margin-left: 1rem;
  }
`;
const ContractNumber = styled.span`
  margin-right: 1rem;
  font-size: 2.25rem;
  font-weight: bold;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
const CustomerItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 240px;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
  svg {
    cursor: pointer;
  }
`;

const fixDates = (aCase?: ICase) => {
  if (!aCase) return {};
  const format = 'yyyy-MM-dd';
  const fixedCase = {
    ...aCase,
    createdAt: aCase.createdAt && formatDate(aCase.createdAt, format),
    drawingGarageCreatedDate:
      aCase.drawingGarageCreatedDate && formatDate(aCase.drawingGarageCreatedDate, format),
    drawingGarageChangedDate:
      aCase.drawingGarageChangedDate && formatDate(aCase.drawingGarageChangedDate, format),
    drawingHouseChangedDate:
      aCase.drawingHouseChangedDate && formatDate(aCase.drawingHouseChangedDate, format),
    drawingHouseCreatedDate:
      aCase.drawingHouseCreatedDate && formatDate(aCase.drawingHouseCreatedDate, format),
  };
  return fixedCase;
};

const CaseDetailsForm = () => {
  const [open, setOpen] = useState(true);
  const { currentCase } = useCurrentCase();
  const { currentCalculation } = useGlobalStateValue();
  const { customers } = useGetCustomers();
  const { data: agents } = useGetUsers();
  const { currentUser } = useCurrentUser();
  const { mutate: saveCase, isLoading } = useUpdateCase();
  const setCaseChanged = useSetRecoilState(caseChangedAtom);
  const { mutate: saveCalculation, isLoading: isSettingNewDate } = useSaveCalculation();
  const { register, handleSubmit, control, formState, reset } = useForm<ICase>({
    defaultValues: fixDates(currentCase),
  });
  const { isDirty } = formState;
  const {
    fields: caseCustomers,
    append,
    remove: removeCustomer,
  } = useFieldArray({ control, name: 'customers' });

  useEffect(() => {
    setCaseChanged(isDirty);
  }, [isDirty, setCaseChanged]);

  const onSubmit = (data: ICase) => {
    const updatedCase = { ...currentCase, ...data };
    saveCase(updatedCase, {
      onSuccess(savedCase) {
        reset(savedCase as ICase);
      },
    });
  };

  const addCustomer = (customer?: ICustomer) => {
    if (customer) {
      append(customer);
    }
  };

  const setNewCalculationDate = () => {
    if (window.confirm('Vill du verkligen sätta nytt beräkningsdatum?')) {
      // console.log({ currentCalculation });
      saveCalculation({
        calculation: {
          ...currentCalculation,
          currentHouse: { ...currentCalculation.currentHouse, comment: 'Nytt beräkningsdatum' },
        },
        updateCalculationDate: true,
      });
    }
  };

  if (!currentCase) {
    return <Loader label="Hämtar ärende" />;
  }
  const readOnly = currentCase.status >= CaseStatuses.SIGNED_CONTRACT || currentUser?.isSemiAdmin;

  return (
    <LabeledCollapse
      open={open}
      onLabelClick={() => setOpen(!open)}
      label={
        <CaseFormHeader>
          <div>
            {currentCase?.name}
            <ChipLabel
              small
              label={(caseStatuses as any)[currentCase?.status || 0].label}
              type={currentCase?.status || 0}
            />
          </div>
          {Boolean(currentCase?.contractNumber && currentCase.contractNumber > 0) && (
            <ContractNumber>B-{currentCase.contractNumber}</ContractNumber>
          )}
        </CaseFormHeader>
      }
    >
      {isLoading && <Loader />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Container>
            <Row>
              <Col>
                <InputField readOnly={readOnly} label="Ärendnamn" {...register('name')} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <InputField label="Skapad" placeholder="Skapad" readOnly {...register('createdAt')} />
              </Col>
              <Col md={6}>
                <InputField
                  label="Avtal nummer"
                  placeholder="Avtal nummer"
                  readOnly
                  {...register('contractNumber')}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <InputField
                  label="Ritningsnr. hus"
                  placeholder="Ritningsnr. hus"
                  readOnly={readOnly}
                  {...register('drawingNumberHouse')}
                />
              </Col>
              <Col md={4}>
                <InputField
                  type="date"
                  label="Skapad"
                  placeholder="Skapad"
                  readOnly={readOnly}
                  {...register('drawingHouseCreatedDate')}
                />
              </Col>
              <Col md={4}>
                <InputField
                  type="date"
                  label="Senast ändrad"
                  placeholder="Senast ändrad"
                  readOnly={readOnly}
                  {...register('drawingHouseChangedDate')}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <InputField
                  label="Ritningsnr. garage"
                  placeholder="Ritningsnr. garage"
                  readOnly={readOnly}
                  {...register('drawingNumberGarage')}
                />
              </Col>
              <Col md={4}>
                <InputField
                  type="date"
                  label="Skapad"
                  placeholder="Skapad"
                  readOnly={readOnly}
                  {...register('drawingGarageCreatedDate')}
                />
              </Col>
              <Col md={4}>
                <InputField
                  type="date"
                  label="Senast ändrad"
                  placeholder="Senast ändrad"
                  readOnly={readOnly}
                  {...register('drawingGarageChangedDate')}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <InputField
                  label="Index basmånad"
                  placeholder="Index basmånad"
                  readOnly={readOnly}
                  {...register('indexBaseMonth')}
                />
              </Col>
              <Col md={4}>
                <InputField
                  readOnly={readOnly}
                  label="Index basår"
                  placeholder="Index basår"
                  {...register('indexBaseYear')}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ControlledSelect
                  options={[true, false].map((value) => ({ label: !value ? 'Ja' : 'Nej', value }))}
                  control={control}
                  name="isInverted"
                  label="Rätvänd?"
                  disabled={readOnly}
                />
              </Col>
            </Row>
            {Boolean(currentCase?.status < CaseStatuses.SIGNED_CONTRACT && currentUser?.isAdmin) && (
              <Row>
                <Col>
                  <hr />
                  <Box>
                    <h5>Sätt nytt beräkningsdatum</h5>
                    <p>
                      <b>Beräkningsdatum</b> {currentCalculation && formatDate(currentCalculation.date || '')}
                    </p>
                    <Button
                      type="button"
                      size="small"
                      onClick={setNewCalculationDate}
                      disabled={isSettingNewDate}
                    >
                      Sätt nytt beräkningsdatum
                    </Button>
                  </Box>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <hr />
                <h5>Kunder</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <ul>
                  {caseCustomers.map((customer, idx) => (
                    <CustomerItem key={customer.id}>
                      <span>
                        √&nbsp;
                        <Link to={`/admin/kunder/${customer.id}`}>
                          {customer.firstName} {customer.lastName}
                        </Link>
                      </span>
                      {Boolean(idx > 0 && !readOnly) && (
                        <FaMinusCircle
                          title="Ta bort kund"
                          onClick={() => {
                            removeCustomer(idx);
                          }}
                        />
                      )}
                    </CustomerItem>
                  ))}
                </ul>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <div>Lägg till kund</div>
                <Select
                  options={customers?.map((customer) => ({
                    label: `${customer.firstName} ${customer.lastName}`,
                    value: customer.id,
                    data: customer,
                  }))}
                  isDisabled={readOnly}
                  placeholder="Sök kund..."
                  controlShouldRenderValue={false}
                  isOptionDisabled={(option) => !!caseCustomers.find((f) => f.id === option.value)}
                  onChange={(d) => {
                    addCustomer(d?.data);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputField readOnly={readOnly} label="Fastighet adress" {...register('propertyAddress')} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <InputField
                  readOnly={readOnly}
                  label="Fastighetsbeteckning"
                  {...register('propertyDesignation')}
                />
              </Col>
              <Col md={6}>
                <InputField readOnly={readOnly} label="Kommun" {...register('propertyMunicipal')} />
              </Col>
            </Row>
            {currentUser?.isAdmin && (
              <Row>
                <Col>
                  <ControlledSelect
                    control={control}
                    options={Object.entries(agents || {}).map(([agentId, name]) => {
                      return {
                        value: agentId,
                        label: name,
                      };
                    })}
                    label="Ärendets agent:"
                    name="userId"
                    disabled={readOnly}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <FormSubmitWrapper>
                  <Button disabled={!isDirty || isLoading}>Spara ärende</Button>
                </FormSubmitWrapper>
              </Col>
            </Row>
          </Container>
        </Box>
      </form>
    </LabeledCollapse>
  );
};

export default CaseDetailsForm;
