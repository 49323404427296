import { useState } from 'react';
import { IAdminProductGroup } from 'models/adminModels';
import SearchBar from 'ui-library/SearchBar';
import ProductGroupList from 'Admin/ProductGroupList';
import { useGetProductGroups } from 'Admin/api/product';
import Loader from 'ui-library/Loader';

type Props = {
  adminProductGroups?: IAdminProductGroup[];
  isFetching: boolean;
};
const HouseProductsList = ({ adminProductGroups, isFetching }: Props) => {
  const { isLoading } = useGetProductGroups();
  const [search, setSearch] = useState('');
  const doSearch = (e: any) => {
    setSearch(e.target.value);
  };
  const productFilter = (product: IAdminProductGroup) => {
    if (!search || search === '') return true;
    const s = search.toLocaleLowerCase();
    if (product.name.toLocaleLowerCase().includes(s)) {
      return true;
    }

    const found = product.adminSubProductGroups.some((subProduct) => {
      if (subProduct.name.toLocaleLowerCase().includes(s)) {
        return true;
      }
      return subProduct.products.some((prod) => prod.name.toLocaleLowerCase().includes(s));
    });
    return found;
  };
  if (isLoading) return <Loader size="large" label="Hämtar produkter" />;
  if (!adminProductGroups) return null;
  return (
    <>
      <SearchBar placeholder="Sök produkt" onChange={doSearch} />
      <ProductGroupList
        adminProductGroups={adminProductGroups.filter(productFilter)}
        isFetching={isFetching}
      />
    </>
  );
};

export default HouseProductsList;
