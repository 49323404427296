import { IAdminProduct, IAdminProductGroup } from 'models/adminModels';
import { useCallback, useMemo, useState } from 'react';
import Modal from 'ui-library/Modal';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from 'styled-components';
import StyledButton, { FormSubmitWrapper } from 'ui-library/Button';
import { useUpdateProducts } from 'Admin/api/product';
import { SortableList } from 'ui-library/SortableList';

const StyledLi = styled.li`
  border: 1px dashed #ddd;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  background: #fff;
  cursor: grab;
`;

type ProductItemProps = {
  item?: IAdminProduct;
};
const ProductItem = ({ item }: ProductItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: `${item?.productNumber}`,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  if (!item) return null;
  return (
    <StyledLi ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {item.name}
    </StyledLi>
  );
};

type Props = {
  show: boolean;
  onClose: () => void;
  productGroup: IAdminProductGroup;
};
const SortProductsModal = ({ show, onClose, productGroup }: Props) => {
  const [changed, setChanged] = useState(false);
  const [sortedProducts, setSortedProducts] = useState<(IAdminProduct | undefined)[]>();
  const products = useMemo(() => productGroup.products.filter((pr) => !pr.isCanceled), [productGroup]);
  const { mutate: saveProducts } = useUpdateProducts();

  const onDragEnd = useCallback(
    (sorted: any) => {
      const hasChanged =
        JSON.stringify(sorted.map((item: any) => item.data?.productNumber)) !==
        JSON.stringify(products.map((pr) => pr.productNumber));
      setChanged(hasChanged);
      if (hasChanged) {
        setSortedProducts(sorted.map((item: any) => ({ ...item.data, validFrom: null })));
      }
    },
    [products],
  );

  const list = SortableList<IAdminProduct>({
    listItems: products.map((product) => ({ id: `${product.productNumber}`, data: product })),
    Component: ProductItem,
    onDragEnd,
  });

  const saveSorting = () => {
    const productsWithUpdatedSorting = sortedProducts
      ?.filter((pr) => pr !== undefined)
      .map((pr, idx) => ({ ...pr, sortOrder: idx })) as IAdminProduct[];

    saveProducts(productsWithUpdatedSorting, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  if (!show) return null;
  return (
    <Modal header="Sortera produkter" show={show} onClose={onClose}>
      <h5>{productGroup.name}</h5>
      {list}
      <FormSubmitWrapper>
        <StyledButton disabled={!changed} onClick={saveSorting}>
          Spara
        </StyledButton>
      </FormSubmitWrapper>
    </Modal>
  );
};

export default SortProductsModal;
