import { forwardRef } from 'react';
import ReactSelect, { Props as SelectProps } from 'react-select';
import Label from 'ui-library/Label';

interface Props extends SelectProps {
  hideLabel?: boolean;
  label?: string;
  disabled?: boolean;
}
const Select = forwardRef(({ hideLabel, label, ...props }: Props, ref) => {
  return (
    <div>
      {Boolean(label && !hideLabel) && <Label>{label}</Label>}
      <ReactSelect isDisabled={props.disabled} {...props} ref={ref as any} />
    </div>
  );
});

export default Select;
