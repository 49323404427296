export interface IContractor {
  id: number | null;
  orgNumber: string;
  name: string;
  address: string;
  postalAddress: string;
  phone: string;
  phoneCell: string;
  email: string;
  contactPerson: string;
  contractorTypeId: number | null;
  certifiering: string;
  counties: number[] | null;
  rating: number | null;
}

type Labels = {
  descriptionLabel?: string | null;
  descriptionPlaceholder?: string | null;
  externalDeductionPriceLabel?: string | null;
  externalDeductionPricePlaceholder?: string | null;
  externalPricePerPieceLabel?: string | null;
  externalPricePerPiecePlaceholder?: string | null;
  externalTotalPriceLabel?: string | null;
  externalTotalPricePlaceholder?: string | null;
};
type ProductGroupMetaData = {
  description: string | null;
  subProductHeadline: string | null;
  tables: any[];
  title: string | null;
};

type ProductGroupRules = {
  availableIfBasement: boolean;
  availableIfCatalogueHouse: boolean;
  availableIfCustomHouse: boolean;
  availableIfIntegratedGarage: boolean;
  availableIfOneAndAHalfFloors: boolean;
  availableIfOneAndThreeQuartersFloors: boolean;
  availableIfOneFloor: boolean;
  availableIfSeparateGarage: boolean;
  availableIfSplitLevel: boolean;
  availableIfTwoFloors: boolean;
  availableIfWildStrawberry: boolean;
  hideQuantityAndUnitInDeliveryDeclaration: boolean;
  includeStandardInOptions: boolean;
  isAllowedToBeUndefined: boolean;
  isGarageProduct: boolean;
  logicOverrideType: number;
};

type ProductRules = {
  includeSubProducts: boolean;
  isContainer: boolean;
  isNoneProduct: boolean;
  requiresDescription: boolean;
  requiresExternalDeductionPrice: boolean;
  requiresExternalPricePerPiece: boolean;
  requiresExternalTotalPrice: boolean;
  requiresQuantity: boolean;
};

type SubProductRules = {
  allowMultipleRows: boolean;
  hasSpecificProduct: boolean;
  hideInConfigurator: boolean;
  isDeliveredSeparately: boolean;
  renderInput: boolean;
  renderSelect: boolean;
  useQuantityFromParent: boolean;
};

type Validation = {
  max: number;
  min: number;
  type: string;
};

export interface IProductGroupData {
  labels: Labels;
  metaData: ProductGroupMetaData;
  name: string;
  productDependencies: any;
  productGroupNumber: number;
  rules: ProductGroupRules;
  standardProductName: string;
  unit: string;
  validation: Validation;
}

export interface IProductData {
  labels: Labels;
  maxOverride: number | null;
  minOverride?: number | null;
  metaData?: any;
  name: string | null;
  productDependencies: any;
  productGroupData: IProductGroupData;
  productNumber: number;
  rules: ProductRules;
  unit: string;
  validation: Validation;
}

export interface ISubProduct {
  definedQuantityInEachParent: number;
  labelOverride: string;
  name: string;
  productGroup: number;
  productNumber: number;
  products: IProduct[];
  rules: SubProductRules;
}

export interface IProduct {
  contractorExcludePrice: boolean;
  contractorManualPrice?: number | null;
  contractorManualQuantity?: number | null;
  description: string;
  externalDeductionPrice: number;
  externalPricePerPiece: number;
  externalTotalPrice: number;
  isUndefinedStandardProduct: boolean;
  nodeId: number;
  productData: IProductData;
  quantity: number;
  subProducts: ISubProduct[];
}

export interface IFrontDoor {
  definedMainEntranceDoubleDoors: IProduct[];
  definedMainEntranceSingleDoors: IProduct[];
  definedOtherEntranceDoubleDoors: IProduct[];
  definedOtherEntranceSingleDoors: IProduct[];
  standardMainEntranceDoubleDoors: IProduct;
  standardMainEntranceSingleDoors: IProduct;
  standardOtherEntranceDoubleDoors: IProduct;
  standardOtherEntranceSingleDoors: IProduct;
}

export type ContractorTypeName =
  | 'construction'
  | 'electricity'
  | 'exteriorPainting'
  | 'painting'
  | 'foundation'
  | 'plumbing'
  | 'tiling';

export interface IHouseContractorCalculations extends Record<ContractorTypeName, IContractorCalculation> {
  generalContractor: boolean;
  generalContractorPrice: number;
}

export interface IHouseProducts {
  parameters: {
    basementType: BasementTypes;
    floorType: FloorTypes;
    centralVacuum: IProduct;
    sauna: IProduct;
    lowerHalfProducts: IProduct;
    windowsAndDoors: IProduct;
    catalogueHouse: IProduct;
    changedCatalogueHouse: IProduct;
    wildStrawBerry: IProduct;
    garageType: IProduct;
    numberOfCorners: IProduct;
  };
  basement: {
    bathrooms: IProduct;
    standardParquet: IProduct;
    bearingWoodWallsQuantity: IProduct;
    definedInteriorDoors: IProduct[];
    definedInteriorLShapedStairs: IProduct[];
    // osv...
  };
  firstFloor: {
    balcony: IProduct;
  };
  garage: {
    area: IProduct;
    definedInteriorDoors: IProduct[];
    definedWindows: IProduct;
    frontDoor: IFrontDoor;
    garageDoorAccessories: IProduct;
    garageDoors: IProduct[];
    garageInsulation: IProduct;
    garageWallMeters: IProduct;
    garageWallSquareMeters: IProduct;
    lowerHalfProducts: IProduct;
    nonBearingWoodWallsQuantity: IProduct;
    standardGarageDoor: IProduct;
    standardInteriorDoors: IProduct;
    templateProduct: IProduct;
    upperHalfProducts: IProduct;
    windowsAndDoors: IProduct;
  };
  contractorCalculation: IHouseContractorCalculations;
}

export interface IHouse {
  autoSave: boolean;
  comment: string;
  dateSaved: string;
  house: IHouseProducts;
  toIsValid: boolean;
  username: string;
  version: number;
}

export interface ICalculationLog {
  hasContent: boolean;
  productsRemoved: any[];
  subProductsRemoved: any[];
  subProductsAdded: any[];
  subProductsChanged: any[];
}

export interface ICalculation {
  calculationId: number;
  contractorsFromContract: IContractor[] | null;
  currentContractors: IContractor[] | null;
  currentHouse: IHouse;
  houseFromContract?: IHouse;
  currentHouseLatestVersion: number;
  date: string;
  deliveryWeekNumber: number;
  log: ICalculationLog;
  reloadingRequired: boolean;
  templateName?: string;
}

export interface IVersion {
  autoSave: boolean;
  comment: string;
  dateSaved: string;
  house: any;
  toIsValid: boolean;
  username: string;
  version: number;
}

export interface IVersionResponse {
  autosave: any;
  versions: IVersion[];
}

export enum HouseTemplateTypes {
  SPECIAL_HOUSE,
  CATALOGUE_HOUSE,
  WILD_STRAWBERRY,
}

export interface IHouseTemplate {
  calculationId: number;
  catalogueHouseProductNumber: number;
  garageProductNumber: number;
  name: string;
  savedHouses: IVersion[];
  wildStrawberryHouseProductNumber: number;
}

export interface IContractorCalculation {
  fieldsThatRequireInput: IProduct;
  otherDescription: string;
  otherPrice: number;
  priceAdjustment: number;
  resolvedFields: IProduct;
}

export enum FloorTypes {
  SINGLE_FLOOR = 0,
  ONE_AND_ONE_HALF = 1,
  ONE_AND_THREE_QUARTERS = 2,
  TWO_FLOORS = 3,
}

export enum BasementTypes {
  NO_BASEMENT = 0,
  BASEMENT = 1,
  SPLIT_LEVEL = 2, // Souteräng
}

export enum PorchTypes {
  NO_PORCH = 0,
  PORCH_BALCONY_3M = 1,
  PORCH_BALCONY_4M = 2,
}
export enum GarageTypes {
  NO_GARAGE = -1,
  STAND_ALONE = 400086,
  INTEGRATED = 400085,
}

export enum HouseShapes {
  SQUARE = 0,
  ANGLE = 1,
  MULTIANGLE = 2,
}
