import { matchPath, useLocation } from 'react-router';
import styled, { css } from 'styled-components';

type NavVariant = 'regular' | 'tabs';

const NavWrapper = styled.div`
  display: flex;
  align-items: stretch;
`;
type NavListProps = {
  variant: NavVariant;
};

const NavList = styled.ul<NavListProps>`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.bgDark};
  ${({ variant }) =>
    variant === 'tabs' &&
    css`
      background-color: transparent;
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    `}
`;

const BaseNavItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: inherit;
    padding: 0.6rem 1rem;
    &.disabled-link {
      pointer-events: none;
      color: #8e8e8e;
    }
  }
  &.isActive {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid;
    border-bottom: 1px solid #fff;
  }
`;

const RegularItem = styled(BaseNavItem)`
  color: #fff;
  a {
    color: #fff;
  }
  &.isActive {
    a {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
const NavTabItem = styled(BaseNavItem)`
  color: #000;
  &.isActive {
    margin-bottom: -1px;
  }
`;

type Props = {
  children: JSX.Element[];
  variant?: NavVariant;
  exact?: boolean;
};
const NavTabs = ({ children, variant = 'regular', exact = true }: Props) => {
  const location = useLocation();
  const NavItem = variant === 'regular' ? RegularItem : NavTabItem;
  return (
    <NavWrapper>
      <NavList variant={variant}>
        {children.map((item) => {
          const match = matchPath(location.pathname, {
            path: item.props.to.split('?')[0],
            exact,
            strict: false,
          });
          return (
            <NavItem key={item.props.to} className={`${item.props.className} ${match ? 'isActive' : ''}`}>
              {item}
            </NavItem>
          );
        })}
      </NavList>
    </NavWrapper>
  );
};

export default NavTabs;
