import { useMutation, useQuery, useQueryClient } from 'react-query';
import { adFetch } from 'adalConfig';
import { IHouseTemplate } from 'models/calculationModel';
import { CaseTypes, ICase, ICustomer, ICustomerCase } from 'models/models';
import { useHistory } from 'react-router';
import { caseTypes } from '../../constants';

export const useGetCustomers = () => {
  const {
    data: customers,
    isFetching,
    isLoading,
  } = useQuery(['customers'], () => adFetch<ICustomer[]>('/customer'), {
    staleTime: Infinity,
  });

  return { customers, isLoading, isFetching };
};

export const useGetCustomerCases = (id: string) => {
  const {
    data: customerCases,
    isFetching,
    isLoading,
  } = useQuery(['customer', 'cases', id], () => adFetch<ICustomerCase[]>(`/customer/${id}/cases`), {
    staleTime: Infinity,
  });
  return { customerCases, isFetching, isLoading };
};

const prepareCaseItem = (
  customer?: ICustomer,
  caseBlank?: ICase,
  caseType?: CaseTypes,
  selectedTemplate?: IHouseTemplate,
): Partial<ICase> | false => {
  if (!caseBlank ?? !caseType ?? !customer) return false;
  const name = `${customer.firstName} ${customer.lastName} - ${
    selectedTemplate ? selectedTemplate.name : caseTypes[caseType].label
  }`;
  const newCase: Partial<ICase> = {
    ...caseBlank,
    name,
    customers: [customer],
    contractors: [],
    contractsSent: [],
    quotesSent: [],
    constructionOrdersSent: [],
    type: caseType,
    createdAt: null,
    templateCalculationId: selectedTemplate?.calculationId,
  };

  return newCase;
};
export const useCreateCase = (
  customer?: ICustomer,
  caseType?: CaseTypes,
  selectedTemplate?: IHouseTemplate,
) => {
  const { data: caseTemplate } = useQuery(['caseTemplate'], () => adFetch<ICase>('/case/template'), {
    staleTime: Infinity,
  });
  const history = useHistory();

  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess } = useMutation(
    (caseItem: Partial<ICase>) => adFetch('/case', { method: 'POST', body: JSON.stringify(caseItem) }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customer', 'cases']);
      },
    },
  );

  const createCase = () => {
    const newCase = prepareCaseItem(customer, caseTemplate, caseType, selectedTemplate);
    if (newCase) {
      mutate(newCase, {
        onSuccess: (createdCase: any) => {
          history.push(`/case/${createdCase.caseNumber}`);
          queryClient.invalidateQueries(['latestCases']);
        },
      });
    }
  };

  return { createCase, isLoading, isSuccess };
};

type CreateCustomerRequest = {
  customer: ICustomer;
  skipDuplicateCheck?: boolean;
};
export const useCreateCustomer = (mutationOptions?: any) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (request: CreateCustomerRequest) =>
      adFetch<ICustomer>(`/customer${request.skipDuplicateCheck ? '?skipDuplicateCheck=true' : ''}`, {
        method: 'POST',
        body: JSON.stringify(request.customer),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customers']);
      },
      ...mutationOptions,
    },
  );
  return mutation;
};

export const useUpdateCustomer = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (customer: ICustomer) =>
      adFetch('/customer', {
        method: 'PUT',
        body: JSON.stringify(customer),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customers']);
      },
    },
  );
  return mutation;
};

export const useDeleteCustomer = () => {
  const mutation = useMutation((customerId: number) =>
    adFetch(`/customer/${customerId}`, {
      method: 'DELETE',
    }),
  );
  return mutation;
};
