import { BasementTypes, FloorTypes, GarageTypes, IProduct } from 'models/calculationModel';
import { useGlobalStateValue } from 'stateContext';

export const useHouseProperties = () => {
  const { getValue } = useGlobalStateValue();
  const basementType = getValue<BasementTypes>('parameters.basementType') || 0;
  const floorType = getValue<FloorTypes>('parameters.floorType') || 0;
  const garageType = getValue<IProduct>('parameters.garageType') || 0;
  const porch = getValue<IProduct>('parameters.porch');
  const catalogueHouseProduct = getValue<IProduct>('parameters.catalogueHouse');
  const wildStrawberryProduct = getValue<IProduct>('parameters.wildStrawBerry');

  const isCatalogueHouse = catalogueHouseProduct?.productData.productNumber !== -1;
  const isWildStrawberry = wildStrawberryProduct?.productData.productNumber !== -1;
  const isSpecialHouse = !isCatalogueHouse && !isWildStrawberry;

  const hasBasement = basementType > BasementTypes.NO_BASEMENT;
  const hasTopFloor = floorType > FloorTypes.SINGLE_FLOOR;
  return {
    hasGarage: garageType && !garageType.productData.rules.isNoneProduct,
    hasBasement,
    hasTopFloor,
    hasPorch: porch && !porch.productData.rules.isNoneProduct,
    isOneFloor: floorType === FloorTypes.SINGLE_FLOOR,
    isTwoFloors: hasBasement || hasTopFloor,
    isOneAndAHalfFloors: floorType === FloorTypes.ONE_AND_ONE_HALF,
    isOneAndThreeQuartersFloors: floorType === FloorTypes.ONE_AND_THREE_QUARTERS,
    isIntegratedGarage: garageType?.productData?.productNumber === GarageTypes.INTEGRATED,
    isCatalogueHouse,
    isWildStrawberry,
    isSpecialHouse,
  };
};
