import { useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { useSaveCalculation, useSaveTemplateCalculation } from 'api/calculation';
import { useGlobalStateValue } from 'stateContext';
import Button, { FormSubmitWrapper } from 'ui-library/Button';
import InputField from 'ui-library/InputField';
import Modal from 'ui-library/Modal';
import { useQueryClient } from 'react-query';
import Loader from 'ui-library/Loader';

const SaveCalculation = () => {
  const [comment, setComment] = useState('');
  const [showSave, setShowSave] = useState(false);
  const { mutate, isLoading: savingCalculation } = useSaveCalculation();
  const { mutate: mutateTemplate, isLoading: savingTemplate } = useSaveTemplateCalculation();
  const { currentCalculation, setValue } = useGlobalStateValue();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const isTemplate = pathname.includes('husmall');

  const saveTemplate = () => {
    mutateTemplate(currentCalculation, {
      onSuccess: () => {
        toast.success('Mall sparad');
      },
    });
  };
  const saveCaseCalculation = () => {
    setValue('currentHouse.comment', comment);
    mutate(
      { calculation: currentCalculation },
      {
        onSuccess: () => {
          toast.success('Kalkyl sparad');
          setComment('');
          setShowSave(false);
          queryClient.invalidateQueries(['versions']);
        },
      },
    );
  };

  const doSave = (e: any) => {
    e.preventDefault();
    if (isTemplate) {
      saveTemplate();
    } else {
      saveCaseCalculation();
    }
  };

  const handleCommentInput = (e: any) => {
    setComment(e.target.value);
  };
  return (
    <>
      {!isTemplate && (
        <Button type="button" onClick={() => setShowSave(true)} disabled={savingTemplate}>
          Spara kalkyl
        </Button>
      )}
      {isTemplate && (
        <Button type="button" onClick={doSave}>
          {savingTemplate && <Loader />} Spara mall
        </Button>
      )}

      <Modal header="Ange kommentar" show={showSave} onClose={() => setShowSave(false)}>
        <form onSubmit={doSave}>
          <InputField onChange={handleCommentInput} />
          <FormSubmitWrapper>
            {savingCalculation && <Loader />}
            <Button onClick={doSave} disabled={comment === '' || savingCalculation}>
              Spara
            </Button>
          </FormSubmitWrapper>
        </form>
      </Modal>
    </>
  );
};

export default SaveCalculation;
