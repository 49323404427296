import { createContext, useContext, useState } from 'react';
import { IVersion } from 'models/calculationModel';
import { DocumentType } from 'hooks/usePdfMaker';

type PdfContextShape = {
  documentType?: DocumentType;
  setDocumentType?: (type: DocumentType | undefined) => void;
  documentVersion?: IVersion;
  setDocumentVersion?: (version: IVersion) => void;
  openDocument: (document: DocumentType, version: IVersion) => void;
  clearDocument?: () => void;
  documentUrlData?: string;
  setDocumentUrlData: (urlData?: string) => void;
};

const defaultContext = {
  openDocument: () => {},
  setDocumentUrlData: () => {},
};

const PdfContext = createContext<PdfContextShape>(defaultContext);

export const usePdfContext = () => useContext<PdfContextShape>(PdfContext);

export const PdfContextProvider = ({ children }: any) => {
  const [documentUrlData, setDocumentUrlData] = useState<string>();
  const [documentType, setDocumentType] = useState<DocumentType>();
  const [documentVersion, setDocumentVersion] = useState<IVersion>();

  const clearDocument = () => {
    setDocumentType(undefined);
    setDocumentVersion(undefined);
    setDocumentUrlData(undefined);
  };

  const openDocument = (document: DocumentType, version: IVersion) => {
    setDocumentType(document);
    setDocumentVersion(version);
  };

  const value: PdfContextShape = {
    documentType,
    setDocumentType,
    documentVersion,
    setDocumentVersion,
    clearDocument,
    openDocument,
    documentUrlData,
    setDocumentUrlData,
  };
  return <PdfContext.Provider value={value}>{children}</PdfContext.Provider>;
};
