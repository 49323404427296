import { FloorName } from 'models/models';
import UndefinedProducts from '../UndefinedProducts';

type Props = {
  floor: FloorName;
  disabled?: boolean;
};
const DefinedFrontDoors = ({ floor, disabled }: Props) => {
  return (
    <>
      <UndefinedProducts
        path={`${floor}.frontDoor.definedMainEntranceSingleDoors`}
        disabled={disabled}
        overrideTitle="Huvudentré"
      />
      <UndefinedProducts
        path={`${floor}.frontDoor.definedMainEntranceDoubleDoors`}
        disabled={disabled}
        overrideTitle="Huvudentré - dubbeldörrar"
      />
      <UndefinedProducts
        path={`${floor}.frontDoor.definedOtherEntranceSingleDoors`}
        disabled={disabled}
        overrideTitle="Groventré"
      />
      <UndefinedProducts
        path={`${floor}.frontDoor.definedOtherEntranceDoubleDoors`}
        disabled={disabled}
        overrideTitle="Groventré - dubbeldörrar"
      />
    </>
  );
};

export default DefinedFrontDoors;
