import { ISubProduct } from 'models/calculationModel';

export const productFilter = (isGarageTemplate: boolean) => (subProduct: ISubProduct) => {
  if (isGarageTemplate) {
    return (
      subProduct.products[0].productData.productGroupData.rules.isGarageProduct &&
      subProduct.productNumber > 0
    );
  }
  return subProduct.productNumber > 0;
};

export const setAllExcluded = (e: any) => {
  return (row: ISubProduct) => {
    return {
      ...row,
      products: [
        {
          ...row.products[0],
          contractorExcludePrice: e.target.checked,
        },
      ],
    };
  };
};
