import { useMemo } from 'react';
import { usePrice } from 'api/price';
import { IValidationItem } from 'models/priceModel';
import Box from 'ui-library/Box';
import LabeledCollapse from 'ui-library/LabeledCollapse';
import Table from 'ui-library/Table';
import Loader from 'ui-library/Loader';
import DownloadAsCsv from './DownloadAsCsv';

const Log = () => {
  const { price, isLoading } = usePrice();

  const columns: any = useMemo(
    () => [
      {
        Header: 'Produkt',
        accessor: 'label',
        Cell: ({ row: { original } }: any) => {
          if (original.price === null) {
            return <b>{original.label}</b>;
          }
          return <span>{original.label}</span>;
        },
      },
      {
        Header: 'Pris',
        accessor: 'price',
      },
      {
        Header: 'Antal',
        accessor: 'quantity',
      },
      {
        Header: 'Totalt',
        accessor: 'calculated',
      },
    ],
    [],
  );
  if (isLoading) return <Loader label="Hämtar loggar" />;
  if (!price) return null;

  return (
    <Box bgColor="#fff">
      <LabeledCollapse label="Loggning">
        <Box>
          <LabeledCollapse label="Prisuträkning" open>
            <DownloadAsCsv data={price?.log} />
            <Table columns={columns} data={price?.log} />
          </LabeledCollapse>
          <LabeledCollapse label="Ändringar från standard">
            <Table columns={columns} data={price?.filteredLog} />
          </LabeledCollapse>
          {price?.deliveryDeclarationValidation?.validationItemDictionary && (
            <LabeledCollapse label="Kvar att definiera">
              {Object.entries(price.deliveryDeclarationValidation.validationItemDictionary).map(
                ([key, val]: [string, IValidationItem]) => (
                  <div key={key}>
                    <h4>{key}</h4>
                    <ul>
                      {val.messages.map((message) => (
                        <li key={`${message.productGroupNumber}-${message.subSection}`}>{message.message}</li>
                      ))}
                    </ul>
                  </div>
                ),
              )}
            </LabeledCollapse>
          )}
        </Box>
      </LabeledCollapse>
    </Box>
  );
};

export default Log;
