import CalculationInput from 'components/CalculationInput';
import { FloorName } from 'models/models';
import styled from 'styled-components';
import Box from 'ui-library/Box';

type Props = {
  floor: FloorName;
};

const FrontDoorRow = styled.div`
  display: flex;
  & > .input-field {
    width: 50%;
  }
`;
const UndefinedFrontDoors = ({ floor }: Props) => {
  return (
    <Box>
      <FrontDoorRow>
        <CalculationInput
          path={`${floor}.frontDoor.standardMainEntranceSingleDoors`}
          label="Huvudentré - enkeldörr"
        />
        <CalculationInput
          path={`${floor}.frontDoor.standardMainEntranceDoubleDoors`}
          label="Huvudentré - dubbeldörr"
        />
      </FrontDoorRow>
      <FrontDoorRow>
        <CalculationInput
          path={`${floor}.frontDoor.standardOtherEntranceSingleDoors`}
          label="Groventré - enkeldörr"
        />
        <CalculationInput
          path={`${floor}.frontDoor.standardOtherEntranceDoubleDoors`}
          label="Groventré - dubbeldörr"
        />
      </FrontDoorRow>
    </Box>
  );
};

export default UndefinedFrontDoors;
