import {
  useDeleteSubProductField,
  useGetSubProductFields,
  useUpdateSubProductFields,
} from 'Admin/api/product';
import EditSubProduct from 'Admin/Modals/EditSubProduct';
import { IAdminProductGroup, IAdminSubProduct } from 'models/adminModels';
import { useCallback, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import Box from 'ui-library/Box';
import Button from 'ui-library/Button';
import { SortableList } from 'ui-library/SortableList';
import SortableSubProductItem, { SubProductItem } from './SortableProductGroupItem';

type Props = {
  form: any;
  productGroup: IAdminProductGroup;
};
const ProductGroupSubProducts = ({ form, productGroup }: Props) => {
  const [showEditSubProduct, setShowEditSubProduct] = useState<IAdminProductGroup>();
  const [sortedSubProducts, setSortedSubProducts] = useState<IAdminProductGroup[]>();
  const [isSorting, setIsSorting] = useState(false);
  const [sortingChanged, setSortingChanged] = useState(false);
  const [subProductFieldToDelete, setSubProductFieldToDelete] = useState<number>();
  const { mutate: updateSubProductFields } = useUpdateSubProductFields();
  const subProductFieldsResponses = useGetSubProductFields(
    productGroup.adminSubProductGroups.map((pg) => pg.subProductFieldNumber),
  );

  const subProductFields = subProductFieldsResponses?.map((spf) => spf?.data);

  useDeleteSubProductField(subProductFieldToDelete);
  const { control } = form;
  const { fields: subProductGroups, remove } = useFieldArray<any>({
    control,
    name: 'adminSubProductGroups',
  });

  const subProducts: unknown = subProductGroups;

  const handleRemove = (idx: number, subProduct?: IAdminProductGroup) => () => {
    if (window.confirm(`vill du verkligen ta bort ${subProduct?.name} som underproduktkategori?`)) {
      setSubProductFieldToDelete(subProduct?.subProductFieldNumber);
      remove(idx);
    }
  };

  const onDragEnd = useCallback(
    (sorted: any) => {
      // console.log({ sorted });
      const isChanged =
        JSON.stringify(sorted.map((item: any) => Number(item.id))) !==
        JSON.stringify(productGroup.adminSubProductGroups.map((sp) => sp.productGroupNumber));
      setSortingChanged(isChanged);
      if (isChanged) {
        setSortedSubProducts(sorted.map((item: any) => item.data));
      }
    },
    [productGroup],
  );

  const showEditSubProductForm = (subProduct?: IAdminProductGroup) => () => {
    setShowEditSubProduct(subProduct);
  };

  let list = SortableList<IAdminProductGroup>({
    listItems: (subProducts as IAdminProductGroup[]).map((pg) => ({
      id: `${pg.productGroupNumber}`,
      data: pg,
    })),
    Component: ({ item, idx }) => (
      <SortableSubProductItem
        subProduct={item}
        idx={idx}
        handleRemove={handleRemove}
        showEditSubProductForm={showEditSubProductForm(item)}
      />
    ),
    onDragEnd,
  });

  if (!isSorting) {
    list = (
      <>
        {(subProducts as IAdminProductGroup[]).map((pg, idx) => {
          return (
            <SubProductItem
              key={pg.productGroupNumber}
              subProduct={pg}
              idx={idx}
              handleRemove={handleRemove}
              showEditSubProductForm={showEditSubProductForm(pg)}
            />
          );
        })}
      </>
    );
  }

  const handleSaveOrder = () => {
    const updatedSorting = (subProductFields as IAdminSubProduct[]).map((spf) => {
      const newSortIndex = sortedSubProducts?.findIndex(
        (item) => item.subProductFieldNumber === spf.subProductFieldNumber,
      );
      return {
        ...spf,
        sortIndex: newSortIndex,
        validFrom: null,
      };
    });
    updateSubProductFields(updatedSorting as IAdminSubProduct[]);
  };

  return (
    <Box>
      <h4>Underproduktgrupper</h4>
      {(subProducts as IAdminProductGroup[]).length > 0 && (
        <div>
          <ul>{list}</ul>
          <Button type="button" onClick={() => setIsSorting(!isSorting)}>
            {!isSorting ? <>Ändra ordning</> : <>Gå ur sorteringsläge</>}
          </Button>
          {isSorting && (
            <Button type="button" onClick={handleSaveOrder} disabled={!sortingChanged}>
              Spara ny ordning
            </Button>
          )}
        </div>
      )}
      <EditSubProduct
        onClose={() => setShowEditSubProduct(undefined)}
        subProduct={showEditSubProduct}
        parentProductGroup={productGroup}
      />
    </Box>
  );
};

export default ProductGroupSubProducts;
