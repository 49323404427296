import { usePrice } from 'api/price';
import styled from 'styled-components';

const CounterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 10rem;
  left: 50px;
`;
const CounterCircle = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #028042;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const NoiLeft = styled.span`
  font-size: 3.5rem;
  color: #fff;
  font-weight: 300;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

const ConstructionOrderCounter = () => {
  const { price } = usePrice();
  return (
    <CounterWrapper>
      <span>Kvar att definiera</span>
      <CounterCircle>
        <NoiLeft>{price?.deliveryDeclarationValidation.totalLeftToDefine}</NoiLeft>
      </CounterCircle>
    </CounterWrapper>
  );
};

export default ConstructionOrderCounter;
