import { Col, Container, Row } from 'react-bootstrap';
import ControlSelector from 'components/ControlSelector';
import { useCurrentCalculation } from 'hooks/useCurrentCalculation';
import { useHouseProperties } from 'hooks/useHouseProperties';
import Box from 'ui-library/Box';
import StvoHeader from 'layout/StvoHeader';
import { FloorName } from 'models/models';
import Loader from 'ui-library/Loader';
import { useConfig } from 'hooks/useConfig';

type FloorProduct = string | { name: string; label: string };
export interface IFloor {
  name: FloorName;
  productsUpper: string[];
  productsLower: string[];
  floorProducts: FloorProduct[];
}

const floorProductMap = (floorName: FloorName) => (product: FloorProduct) => {
  let label = '';
  let productName = '';
  if (typeof product === 'string') {
    productName = product;
  } else {
    productName = product.name;
    label = product.label;
  }
  return (
    <ControlSelector
      floor={floorName}
      productName={productName}
      label={label}
      key={`${floorName}.${productName}`}
    />
  );
};

const testProductHouseProperty = (houseProperties: any) => (product: FloorProduct) => {
  const { isOneAndAHalfFloors, isOneAndThreeQuartersFloors, isIntegratedGarage } = houseProperties;
  const productName = typeof product === 'string' ? product : product.name;
  if (['twoSpaceCup', 'threeSpaceCup'].includes(productName)) {
    return isOneAndAHalfFloors || isOneAndThreeQuartersFloors;
  }
  if (
    [
      'sunRoof',
      'roofSlope',
      'hasMansardRoof',
      'floorBeamParametersOneAndAHalfFloors',
      'floorBeamParameters',
    ].includes(productName)
  ) {
    return isOneAndAHalfFloors;
  }
  if (['garageWallSquareMeters', 'garageWallMeters'].includes(productName)) {
    return isIntegratedGarage;
  }
  return true;
};

type Props = {
  floorName: FloorName;
};
const Floor = ({ floorName }: Props) => {
  const { fetchingCalculation } = useCurrentCalculation();
  const houseProperties = useHouseProperties();

  const { data: floorConfig } = useConfig<any>('floor');

  const floor = floorConfig?.find((item: any) => item.name === floorName);

  if (!floor) {
    return <p>Kunde inte hitta våning</p>;
  }

  const { productsUpper, productsLower, floorProducts } = floor;

  if (fetchingCalculation) {
    return <Loader label="Hämtar kalkyl" />;
  }
  return (
    <>
      <Box bgColor="#fff">
        <Container>
          <Row>
            <Col>
              {floorProducts
                .slice(0, floorProducts.length / 2)
                .filter(testProductHouseProperty(houseProperties))
                .map(floorProductMap(floorName))}
            </Col>
            <Col>
              {floorProducts
                .slice(floorProducts.length / 2)
                .filter(testProductHouseProperty(houseProperties))
                .map(floorProductMap(floorName))}
            </Col>
          </Row>
        </Container>
        <Box>
          {productsUpper.filter(testProductHouseProperty(houseProperties)).map(floorProductMap(floorName))}
        </Box>
      </Box>
      <StvoHeader>Definierad standard eller tillval (framtida STVO)</StvoHeader>
      <Box bgColor="#fff">
        <Box>
          {productsLower.filter(testProductHouseProperty(houseProperties)).map(floorProductMap(floorName))}
        </Box>
      </Box>
    </>
  );
};

export default Floor;
