import styled from 'styled-components';
import { ContractorRating } from 'Admin/Components/Contractors/ContractorRating';
import { useCurrentCase, useUpdateCase } from 'api/case';
import { useGetContractors } from 'api/contractors';
import Select from 'ui-library/Select';
import { FaTrash } from 'react-icons/fa';
import { IContractor } from 'models/calculationModel';
import { ContractorType } from '../../../constants';

export const StyledContractorBox = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.bgLight};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  margin-bottom: 0.5rem;
`;

const ContractorItem = styled.div`
  padding: 1rem;
  background-color: #d6d6d6;
  border: 1px solid ${({ theme }) => theme.colors.border};
  margin-bottom: 1rem;
  position: relative;
`;

const ContactItem = styled.p`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;
const DeleteButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  margin: 0;
  padding: 0;
  line-height: 1;
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primaryFocus};
    }
  }
`;

type Props = {
  contractorType: ContractorType;
};
export const ContractorBox = ({ contractorType }: Props) => {
  const { data: allContractors } = useGetContractors();
  const { currentCase } = useCurrentCase();
  const { mutate: updateCase, isLoading } = useUpdateCase();
  const contractors = currentCase?.contractors.filter((c) => c.contractorTypeId === contractorType.id);

  const handleDelete = (contractor: IContractor) => () => {
    if (currentCase) {
      updateCase({
        ...currentCase,
        contractors: currentCase.contractors.filter((c) => c.id !== contractor.id),
      });
    }
  };
  const handleSelect = (option: any) => {
    if (currentCase) {
      updateCase({
        ...currentCase,
        contractors: [...currentCase?.contractors, option.value],
      });
    }
  };
  return (
    <StyledContractorBox>
      <h4>{contractorType.type}</h4>
      {contractors?.map((contractor) => (
        <ContractorItem key={contractor.id}>
          <DeleteButton onClick={handleDelete(contractor)}>
            <FaTrash />
          </DeleteButton>
          <h5>
            {contractor.name} <ContractorRating rating={contractor.rating || 0} />
          </h5>
          <ContactItem>
            <b>Telefon:</b> {contractor.phoneCell}
          </ContactItem>
          <ContactItem>
            <b>E-post:</b> {contractor.email}
          </ContactItem>
        </ContractorItem>
      ))}
      <Select
        placeholder={`Lägg till ${contractorType.type}`}
        options={allContractors
          ?.filter((c) => c.contractorTypeId === contractorType.id)
          .filter((c) => !currentCase?.contractors.find((caseContractor) => caseContractor.id === c.id))
          .map((contractor) => ({ value: contractor, label: contractor.name }))}
        isDisabled={isLoading}
        onChange={handleSelect}
      />
    </StyledContractorBox>
  );
};
