import { useQuery, useQueryClient } from 'react-query';
// import JSum from 'jsum';
import { useParams } from 'react-router-dom';
import { adFetch } from 'adalConfig';
import { objectToParams } from 'helpers';
import { ICase } from 'models/models';
import { useGlobalStateValue } from 'stateContext';
import { IPriceResponse } from 'models/priceModel';
import { ICalculation } from 'models/calculationModel';
import { useDebouncedCallback } from 'use-debounce';
import { useSetRecoilState } from 'recoil';
import { calculationHasChangesAtom } from 'atoms';
import { useCurrentCase } from './case';

export const usePrice = (versionCalculation?: ICalculation, withLD = false) => {
  const { currentCalculation } = useGlobalStateValue();
  const { currentCase } = useCurrentCase();
  const { templateCalculationId: templateCalculationIdParam } =
    useParams<{ templateCalculationId: string }>();
  const { id, type, status, templateCalculationId, templateVersion, contractVersion } =
    currentCase || ({} as ICase);

  const queryString = objectToParams({
    caseId: id || -1,
    caseType: type || 0,
    status: status || 0,
    templateCalculationId: templateCalculationId || -1,
    contractVersion: contractVersion || -1,
    templateVersion: templateVersion || -1,
    generateLDModel: withLD,
    // validateSelectedProducs: true,
  });

  const priceCalculation = versionCalculation || currentCalculation;

  // const chksum = JSum.digest(priceCalculation || {}, 'SHA256', 'hex');

  const {
    data: price,
    isFetching,
    isLoading,
  } = useQuery(
    ['price', id, priceCalculation?.currentHouse.version, withLD],
    ({ signal }) => {
      return adFetch<IPriceResponse>(`/house/price?${queryString}`, {
        method: 'POST',
        body: JSON.stringify(priceCalculation),
        signal,
      });
    },
    {
      staleTime: 60 * 60 * 1000,
      enabled: Boolean(id || templateCalculationIdParam) && Boolean(priceCalculation),
      keepPreviousData: false,
    },
  );

  return { price, isFetching, isLoading };
};

export const useInvalidatePrice = () => {
  const queryClient = useQueryClient();
  const invalidatePrice = () => {
    queryClient.invalidateQueries(['price']);
  };
  return invalidatePrice;
};

export const useSetProductValue = (delay = 500) => {
  const queryClient = useQueryClient();
  const { setProductValue: _setProductValue } = useGlobalStateValue();
  const setCalculationHasChanges = useSetRecoilState(calculationHasChangesAtom);
  const invalidatePrice = useInvalidatePrice();

  const debouncedHandleChange = useDebouncedCallback(
    (path: string, property?: string | null, value?: any, noInvalidate = false) => {
      _setProductValue(path, property, value);
      if (!noInvalidate) {
        setCalculationHasChanges(true);
        invalidatePrice();
      }
    },
    delay,
  );

  const setProductValue = (path: string, property?: string | null, value?: any, noInvalidate = false) => {
    queryClient.cancelQueries(['price']);
    debouncedHandleChange(path, property, value, noInvalidate);
  };
  return setProductValue;
};
