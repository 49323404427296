import styled from 'styled-components';
import Box from 'ui-library/Box';

const CaseWrapper = styled(Box)`
  width: 1170px;
  margin: 0 auto;
  background-color: #fff;
`;

export default CaseWrapper;
