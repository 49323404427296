import { useEffect, useRef } from 'react';
import { InputProps } from 'react-html-props';
import { IProduct } from 'models/calculationModel';
import { useGlobalStateValue } from 'stateContext';
import InputField from 'ui-library/InputField';
import { useSetProductValue } from 'api/price';

interface Props extends InputProps {
  path: string;
  label?: string;
  property?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  addonText?: string;
  productName?: string;
  inProduct?: IProduct;
}

const CalculationInput = ({
  path,
  property = 'quantity',
  label,
  disabled,
  addonText,
  hideLabel = false,
  productName,
  inProduct,
  ...rest
}: Props) => {
  const { getValue } = useGlobalStateValue();
  const setProductValue = useSetProductValue();

  const handleChange = (e: any) => {
    setProductValue(path, property, e.target.value);
  };

  const inputRef = useRef<any>(null);

  const product = inProduct || (getValue(path) as IProduct);
  const labelText = label || product?.productData?.name || product?.productData?.productGroupData.name;

  useEffect(() => {
    if (inputRef.current) {
      const defaultValue = getValue(path, property) as string;
      if (!Number.isNaN(defaultValue)) {
        inputRef.current.defaultValue = defaultValue;
      }
    }
  }, [getValue, path, property]);

  return (
    <>
      <InputField
        label={labelText}
        placeholder="Antal"
        addonText={
          addonText || product?.productData?.unit || product?.productData?.productGroupData.unit || undefined
        }
        disabled={disabled}
        onChange={handleChange}
        ref={inputRef as any}
        hideLabel={hideLabel}
        {...rest}
      />
    </>
  );
};

export default CalculationInput;
