import { adFetch } from 'adalConfig';
import { IContractor } from 'models/calculationModel';
import { useMutation, useQuery } from 'react-query';

export const useGetContractors = () => {
  const q = useQuery(['contractors'], () => adFetch<IContractor[]>('/contractor'));

  return q;
};

export const useUpdateContractor = (isAdding?: boolean) => {
  const mutation = useMutation((customer: IContractor) =>
    adFetch('/contractor', {
      method: isAdding ? 'POST' : 'PUT',
      body: JSON.stringify(customer),
    }),
  );
  return mutation;
};

export const useDeleteContractor = () => {
  const mutation = useMutation((id: number | null) =>
    adFetch(`/contractor/${id}`, {
      method: 'DELETE',
    }),
  );
  return mutation;
};
