import { useCallback, useState } from 'react';
import { FaPen } from 'react-icons/fa';
import styled from 'styled-components';
import { InputPropsWithoutRef } from 'react-html-props';
import InputField from '../InputField';

const EditableFieldWrapper = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    cursor: pointer;
    margin-left: 0.5rem;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

interface Props extends InputPropsWithoutRef {
  value?: any;
  onChange?: (e: any) => void;
  disabled?: boolean;
}

const EditableField = ({ value, onChange, disabled, ...props }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const handleChange = useCallback(
    (e: any) => {
      if (onChange) onChange(e.currentTarget.value);
    },
    [onChange],
  );
  const handleBlur = useCallback(() => {
    setIsEditing(false);
  }, []);

  const inputRef = useCallback(
    (node: HTMLInputElement) => {
      if (node !== null) {
        node.addEventListener('input', handleChange);
        node.addEventListener('blur', handleBlur);
        node.focus();
      }
      return () => {
        if (node !== null) {
          node.removeEventListener('input', handleChange);
          node.addEventListener('blur', handleBlur);
        }
      };
    },
    [handleChange, handleBlur],
  );

  if (!isEditing) {
    return (
      <EditableFieldWrapper className={disabled ? 'disabled' : ''}>
        {value} <FaPen onClick={() => setIsEditing(true)} />
      </EditableFieldWrapper>
    );
  }

  return <InputField ref={inputRef} {...props} hideLabel defaultValue={value} disabled={disabled} />;
};

export default EditableField;
