import { IProduct } from 'models/calculationModel';
import styled from 'styled-components';
import ComponentGroupHeader from 'ui-library/ComponentGroupHeader';
import { TitleComponentType } from './useLayoutConfig';

const ProductTitle = styled.label`
  font-size: 1rem;
  font-weight: bold;
`;

type Props = {
  product?: IProduct;
  isToplevelTitle?: boolean;
  regularTitle?: boolean;
  nameOverride?: string;
  type?: TitleComponentType;
};
export const TitleComponent = ({ product, nameOverride, isToplevelTitle, regularTitle, type }: Props) => {
  // const {
  //   productGroupData: { name: groupName },
  // } = productData;
  const groupName = product?.productData.productGroupData.name;
  const title = nameOverride || groupName;
  if (type === 'none') return null;
  if (regularTitle || type === 'label') {
    return <ProductTitle>{title}</ProductTitle>;
  }
  if (isToplevelTitle || type === 'banner') {
    return <ComponentGroupHeader>{title}</ComponentGroupHeader>;
  }
  return null;
};
