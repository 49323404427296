import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { useCurrentCase } from 'api/case';
import CaseWrapper from 'layout/CaseWrapper';
import Loader from 'ui-library/Loader';
import { PdfContextProvider } from 'hooks/usePdfMaker/PdfContext';
import { caseChangedAtom } from 'atoms';
import { useRecoilValue } from 'recoil';
import Box from 'ui-library/Box';

import CaseDetailsForm from './CaseDetailsForm';
import CaseNavigation from './CaseNavigation';
import ContractPage from './Contract';
import ConstructionOrder from './ConstructionOrder';
import Quote from './Quote';
import CaseContractors from './Contractors';
import UnsavedChangesAlert from './UnsavedChangesAlert';
import PriceList from './PriceList';

const CaseProcessPage = styled.div`
  position: relative;
  padding: 4rem 1.5rem;
`;

const Case = () => {
  const { currentCase } = useCurrentCase();
  const caseHasUnsavedChanges = useRecoilValue(caseChangedAtom);

  if (!currentCase) {
    return <Loader size="large" label="Hämtar ärende" />;
  }
  return (
    <CaseWrapper>
      <CaseDetailsForm />
      <CaseNavigation />
      {caseHasUnsavedChanges && (
        <Box>
          <UnsavedChangesAlert />
        </Box>
      )}

      <CaseProcessPage>
        <PdfContextProvider>
          <Switch>
            <Route path="/case/:caseNumber" exact>
              <Quote />
            </Route>
            <Route path="/case/:caseNumber/avtal" exact>
              <ContractPage />
            </Route>
            <Route path="/case/:caseNumber/tillverkningsorder" exact>
              <ConstructionOrder />
            </Route>
            <Route path="/case/:caseNumber/entreprenorer" exact>
              <CaseContractors />
            </Route>
            <Route path="/case/:caseNumber/prislista" exact>
              <PriceList />
            </Route>
          </Switch>
        </PdfContextProvider>
      </CaseProcessPage>
    </CaseWrapper>
  );
};

export default Case;
