import { useCurrentUser } from 'hooks/useCurrentUser';
import { Link } from 'react-router-dom';
import NavTabs from 'ui-library/NavTabs';

const TopNavigation = () => {
  const { currentUser } = useCurrentUser();
  const pages = [
    {
      title: 'Produkter',
      path: 'produkter',
      show: currentUser?.isAdmin,
    },
    {
      title: 'Entreprenad',
      path: 'entreprenad',
      show: currentUser?.isAdmin,
    },
    {
      title: 'Kataloghus',
      path: 'kataloghus',
      show: currentUser?.isAdmin,
    },
    {
      title: 'Kunder',
      path: 'kunder',
      show: true,
    },
    {
      title: 'Entreprenörer',
      path: 'entreprenorer',
      show: true,
    },
    {
      title: 'Prislista',
      path: 'prislista',
      show: true,
    },
    {
      title: 'Konfiguration',
      path: 'konfiguration',
      show: currentUser?.userName === 'admin',
    },
  ];

  return (
    <NavTabs variant="tabs" exact={false}>
      {pages
        .filter((page) => page.show)
        .map(({ title, path }) => {
          const fullPath = `/admin/${path}`;
          return (
            <Link key={path} to={fullPath}>
              {title}
            </Link>
          );
        })}
    </NavTabs>
  );
};

export default TopNavigation;
