import { ICase, IProductionCostCalculationGroup, IProductionCostCalculationRow } from 'models/models';
import { IPriceResponse } from 'models/priceModel';
import { dynamicRows, getPriceFromRow, rowHasUserValue } from 'routes/Case/Quote/productionCalculationCost';

export const createHeader = (text: string) => {
  return {
    text,
    style: { fontSize: 11, bold: true },
    margin: [0, 5, 0, 5],
  };
};

export const createParagraph = (text: string) => {
  return {
    text,
    style: { fontSize: 11 },
    margin: [0, 0, 0, 8],
  };
};

export const titleText = (text: string) => {
  return {
    text,
    style: {
      bold: true,
    },
  };
};

export const getCustomers = (currentCase: ICase, gdpr: boolean) => {
  const customers: any = [];
  currentCase.customers.forEach((customer, i) => {
    const customerRow = [];
    customerRow.push(titleText(`Köpare ${i + 1}`));
    customerRow.push(gdpr ? '[...]' : `${customer.firstName} ${customer.lastName}`);
    customerRow.push({ text: '' });
    customerRow.push({ text: '' });

    customers.push(customerRow);
  });
  return customers;
};

type DataRowArgs = {
  title: string;
  comment: string | number;
  isSumRow?: boolean;
  columnValues: (string | number)[];
};
export const createDataRow = ({ title, comment, isSumRow = false, columnValues }: DataRowArgs) => {
  const [value1, value2, value3, value4] = columnValues;
  const row = [];
  const cellStyle = isSumRow ? 'sumCellFill' : 'cellFill';
  const _title = {
    colSpan: 3,
    text: title,
    style: cellStyle,
  };
  const _comment = {
    colSpan: 2,
    text: comment,
    style: cellStyle,
  };

  row.push(_title);
  row.push('');
  row.push('');

  row.push({ text: value1 === null ? '-' : value1, style: isSumRow ? cellStyle : '' });
  row.push({ text: value2 === null ? '-' : value2, style: isSumRow ? cellStyle : '' });
  row.push({ text: value3 === null ? '-' : value3, style: cellStyle });
  row.push({ text: value4 === null ? '-' : value4, style: cellStyle });
  row.push(_comment);

  return row;
};

const getColumnValues = (row: IProductionCostCalculationRow, price: IPriceResponse) => {
  const columns = ['column1', 'column2', 'column3', 'column4'];
  const hasUserValue = rowHasUserValue(row);
  if (Object.values(dynamicRows).includes(row.rowNumber) && !hasUserValue) {
    const dynamicPrice = getPriceFromRow(row.rowNumber, price);
    if ([dynamicRows.GARAGE_COST, dynamicRows.HOUSE_COST].includes(row.rowNumber)) {
      return [dynamicPrice, 0, 0, 0];
    }
    return [0, dynamicPrice, 0, 0];
  }
  return columns.map((col: any) => (row as any)[col].value);
};

export const renderGroupRows = (groups: IProductionCostCalculationGroup[], price: IPriceResponse) => {
  const rows: any = [];

  groups.forEach((group) => {
    const columnTotals: Record<string, number> = {
      column1: 0,
      column2: 0,
      column3: 0,
      column4: 0,
    };

    group.rows.forEach((row) => {
      const columnValues = getColumnValues(row, price);
      columnValues.forEach((colValue, idx) => {
        columnTotals[`column${idx + 1}`] += colValue;
      });
      const renderedRow = createDataRow({
        title: row.name,
        columnValues,
        comment: row.comment,
      });
      rows.push(renderedRow);
    });

    const groupName = group.name === 'Produktionskostnad' ? 'Uppräkning' : group.name;
    rows.push(
      createDataRow({
        title: `SUMMA - ${groupName}`,
        columnValues: Object.values(columnTotals),
        isSumRow: true,
        comment: Object.values(columnTotals).reduce((acc, curr) => acc + curr),
      }),
    );
  });

  return rows;
};
