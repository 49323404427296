import { houseNameWithChange, pageBreak } from 'hooks/usePdfMaker/documentsCommon';
import { ICalculation } from 'models/calculationModel';
import { ICase } from 'models/models';
import { IPriceResponse } from 'models/priceModel';
import {
  createHeader,
  createParagraph,
  createSubHeader,
  createTableList,
  getConstructionDrawingsTable,
  renderGarbo,
  _t,
} from './helpers';

const deliveryDeclarationFrontPages = (
  price: IPriceResponse,
  calculation: ICalculation,
  currentCase: ICase,
  isDeliveryDeclaration: boolean,
) => {
  if (!price || !calculation || !currentCase) return null;
  const { deliveryDeclarationSource } = price;
  const { constructionDrawings } = deliveryDeclarationSource;
  const constructionDrawingsTable = getConstructionDrawingsTable(
    constructionDrawings.list[0].productField.subProducts,
  );
  // const isDeliveryDeclaration = type === 'deliveryDeclaration'; // Fix this;
  const houseName = houseNameWithChange(calculation);

  const frontPages = [
    {
      text: isDeliveryDeclaration ? 'LEVERANS-' : 'TILLVERKNINGS-',
      alignment: 'center',
      margin: [0, 120, 0, 0],
      style: {
        fontSize: 65,
        color: '#808080',
      },
    },
    {
      text: isDeliveryDeclaration ? 'DEKLARATION' : 'ORDER',
      alignment: 'center',
      style: {
        fontSize: 65,
        color: '#808080',
      },
    },
    {
      text: `${currentCase.customers[0].firstName} ${currentCase.customers[0].lastName} - ${houseName}`,
      alignment: 'center',
      margin: [0, 50, 0, 50],
      style: {
        fontSize: 24,
        color: '#808080',
      },
    },
    {
      image: 'frontPage',
      fit: [200, 200],
      alignment: 'center',
      pageBreak: 'after',
    },

    // INFO PAGES
    createHeader('ALLMÄN ÖVERSIKT'),
    createHeader('FÖRETAGET'),
    createParagraph(
      'Bra Hus ingår i en koncern där moderbolaget är Hedlunds Trävaru AB som grundades 1903. I koncernen ingår även sågverksföretaget Hedlunds Timber AB. Vi har genom detta kontroll på hela kedjan från skog till färdigt hus. Alla våra hus tillverkas inomhus i vår produktionsanläggning skyddade från påverkan av väder och vind. Vår kvalitet, där vårt högkvalitativa virke är en viktig faktor, är omtalat mycket hög och utgör själva grunden i vår verksamhet.',
    ),
    createParagraph(
      'Vår affärsidé är: \n ”Vi erbjuder kundanpassade gedigna kvalitetshus som över tiden ger kunderna ett bestående värde”',
    ),
    createHeader('HUVUDKONTOR'),
    createParagraph([
      _t('Bra Hus från Hedlunds AB \n Järnvägsgatan 12, 790 70 FURUDAL \n Tel 0258-312 00\n'),
      { text: 'www.brahus.se', link: 'http://www.brahus.se', decoration: 'underline' },
    ]),
    createHeader('LEVERANSDEKLARATION'),
    createParagraph(
      'Leveransdeklarationen är en detaljerad beskrivning över vad som ingår i materialleveransen från Bra Hus. Den beskriver varje byggnadsdel i skrift på ett sådant sätt att även dimensioner samt övrig teknisk information framgår.',
    ),
    createParagraph(
      'Bra Hus förbehåller sig rätten till ändringar i material och leveransutförande samt att vid behov ersätta berörd produkt med likvärdig.',
    ),
    createParagraph(
      'Bra Hus leveransdeklaration beskriver husens utgångsstandard. Kompletteringar eller förändringar kan göras. Byggplatsarbeten ingår ej i Bra Hus leverans. Kunden ansvarar för ingåendet av entreprenadavtal med respektive entreprenör.',
    ),
    createHeader('VÅR KONSTRUKTION'),
    createParagraph(
      'Vår mer än 110 åriga erfarenhet har gett oss ovärderliga erfarenheter och kunskaper gällande husproduktion. Dessa har vi sedan vävt in i vår produkt genom välarbetade och genomtänkta konstruktioner och lösningar.',
    ),
    createParagraph(
      '”Det bestående värdet” hämtat ur vår affärsidé är invävt i alla delar av verksamheten och har resulterat i att vi i dag stoltserar med en av marknadens absolut bästa produkter.',
    ),
    createParagraph(
      'Vår yttervägg är uppbyggd enligt 3-skiktsprincipen och innehåller inte mindre än 285 mm isolering. Insidan av ytter & innerväggar är dessutom alltid klädd med både OSB- och gipsskivor, vilket minimerar risken för sprickbildningar samt underlättar för dig som kund när du vill montera något på insidan väggen till exempel en TV. Som underlag för yttertaket använder vi oss av 22 mm råspont vilket ger en stabil och mycket säker konstruktion.',
    ),
    pageBreak(),

    // PAGE 2
    createHeader('BYGGSYSTEM'),
    createSubHeader('BOSTADSHUS'),
    createParagraph(
      'Markbjälklag: Vi förutsätter platta på mark, ingår ej.\n Ytterväggar: Storblockselement med inmonterade fönster och fönsterdörrar.\n Takstolar: Prefabricerade CE-märkta takstolar.',
    ),
    createSubHeader('SIDOBYGGNADER'),
    createParagraph(
      'Markbjälklag: Vi förutsätter platta på mark, ingår ej.\n Ytterväggar: Storblockselement med inmonterade fönster. \n Takstolar: Prefabricerade CE-märkta takstolar.',
    ),
    createHeader('FÖRSÄLJNING'),
    createSubHeader('BRA HUS AGENTER ÄR FÖRUTOM HUSLEVERANSEN BEHJÄLPLIGA MED'),

    createTableList([
      'Råd och hjälp vid tomt- och val av husmodell',
      'Kostnadskalkyler',
      'Upprättande av bygglovsansökan',
      'Råd och hjälp vid upphandling av entreprenader och upprättande av entreprenadkontrakt',
    ]),
    createSubHeader('FÖRSÄLJNINGSVILLKOR, PRISER'),
    createParagraph(
      'Priset gäller hus samt eventuell sidobyggnad enligt denna Leveransdeklaration/Tillverkningsorder. Avtalat pris gäller om leverans sker inom angivet antal månader i köpeavtalet räknat från avtalsdatum.',
    ),
    createSubHeader('LEVERANSBESTÄMMELSER'),
    createParagraph('TMF:s Allmänna Avtalsvillkor AA tillämpas.'),
    createSubHeader('TRANSPORTER'),
    createParagraph(
      'Vår materialleverans fraktas med lastbil till byggplatsen. Fraktkostnaden är inkluderad i huspriset*. Den inkluderade frakten förutsätter framkomlighet med 24 meters bil och släp. Ytterligare information kring detta finns att läsa i TMF ́s Allmänna Avtalsvillkor AA.',
    ),
    createParagraph('För leveransens omhändertagande gäller särskilda anvisningar.'),
    createParagraph(
      '*) Fritt byggplats inom Sverige. Eventuella färjetransporter samt broavgifter ingår ej. Lossningskostnader ingår ej',
    ),
    createSubHeader('BETALNING'),
    createParagraph('Betalningsvillkor enligt köpeavtal.'),
    createHeader('ARBETE, MONTERING'),
    createParagraph(
      'Byggplatsarbeten ingår ej i materialleveransen. Kunden ansvarar för ingåendet av entreprenadavtal med respektive entreprenör.',
    ),

    createHeader('FÖRTECKNING ÖVER HANDLINGAR'),
    createTableList([
      'Köpeavtal',
      'Gällande ritningar',
      'Leveransdeklaration',
      'Slutlig tillverkningsorder',
      'Övriga åberopade handlingar',
      'Godtagbar säkerhet vilken skall lämnas senast 10 veckor före leverans',
    ]),
    createSubHeader('RITNINGAR'),
    constructionDrawingsTable,
    createParagraph('*) Ingår endast då system/material köps av Bra Hus.'),
    createHeader('FÖRSÄKRING'),
    createParagraph('Från byggstart och 12 månader framåt skyddas byggherren av en husbyggarförsäkring.'),
    renderGarbo(deliveryDeclarationSource),
    pageBreak(),
  ];

  return frontPages;
};

export default deliveryDeclarationFrontPages;
