import { Switch, Route, Redirect } from 'react-router';
import styled from 'styled-components';
import PriceList from 'routes/Case/PriceList';
import { useGetProductGroups } from './api';
import Config from './Components/Config';
import HouseProductsList from './Components/Products/HouseProductsList';
import AdminWrapper from './layout/AdminWrapper';
import CatalogueHouses from './routes/CatalogueHouses';
import ContractorDetails from './routes/ContractorDetails';
import ContractorProducts from './routes/ContractorProducts';
import ContractorPage from './routes/Contractors';
import CustomerDetails from './routes/CustomerDetails';
import Customers from './routes/Customers';
import TopNavigation from './TopNavigation';

const AdminContent = styled.div`
  background-color: #fff;
`;

const Admin = () => {
  const { productGroupsResponse, isFetching } = useGetProductGroups();

  return (
    <AdminWrapper>
      <TopNavigation />
      <AdminContent>
        <Switch>
          <Route path="/admin" exact>
            <Redirect to="/admin/produkter" />
          </Route>
          <Route path="/admin/produkter">
            <HouseProductsList
              adminProductGroups={productGroupsResponse?.adminProductGroups}
              isFetching={isFetching}
            />
          </Route>
          <Route path="/admin/entreprenad">
            <ContractorProducts
              productGroups={productGroupsResponse?.contractorProductGroups}
              isFetching={isFetching}
            />
          </Route>
          <Route path="/admin/kataloghus">
            <CatalogueHouses productGroups={productGroupsResponse?.catalogueHouses} isFetching={isFetching} />
          </Route>
          <Route path="/admin/kunder" exact>
            <Customers />
          </Route>
          <Route path="/admin/kunder/:id" exact>
            <CustomerDetails />
          </Route>
          <Route path="/admin/entreprenorer/" exact>
            <ContractorPage />
          </Route>
          <Route path="/admin/entreprenorer/:id">
            <ContractorDetails />
          </Route>
          <Route path="/admin/prislista">
            <PriceList />
          </Route>
          <Route path="/admin/konfiguration">
            <Config />
          </Route>
        </Switch>
      </AdminContent>
    </AdminWrapper>
  );
};

export default Admin;
