import { useCurrentCase, useUpdateCase } from 'api/case';
import { IVersion } from 'models/calculationModel';
import { ICase } from 'models/models';
import StyledButton from 'ui-library/Button';
import Modal from 'ui-library/Modal';
import { usePdfContext } from 'hooks/usePdfMaker/PdfContext';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

type Props = {
  show: boolean;
  onClose: () => void;
  version: IVersion;
};
const GenerateConstructionOrderModal = ({ show, onClose, version }: Props) => {
  const { currentCase } = useCurrentCase();
  const { openDocument } = usePdfContext();

  const { mutate: saveCase, isLoading } = useUpdateCase();
  const queryClient = useQueryClient();

  const handleOpen =
    (send = false) =>
    () => {
      openDocument('constructionOrder', version);
      if (send && !currentCase?.constructionOrdersSent.includes(version.version)) {
        const updatedContracts = currentCase?.constructionOrdersSent
          ? [...currentCase?.constructionOrdersSent, version.version]
          : [version.version];
        const updatedCase = { ...currentCase, constructionOrdersSent: updatedContracts };
        saveCase(updatedCase as ICase, {
          onSuccess() {
            toast.success('Tillverkningsorder skickad');
            queryClient.invalidateQueries(['versions']);
            queryClient.invalidateQueries(['case']);
            onClose();
          },
        });
      }
      if (!send) {
        onClose();
      }
    };

  return (
    <Modal header="Generera avtal" show={show} onClose={onClose}>
      <>
        <StyledButton variant="secondary" onClick={handleOpen()} disabled={isLoading}>
          Generera och förandsgranska
        </StyledButton>
        <StyledButton onClick={handleOpen(true)} disabled={isLoading}>
          Generera och skicka
        </StyledButton>
      </>
    </Modal>
  );
};

export default GenerateConstructionOrderModal;
