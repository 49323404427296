import { Link, useParams } from 'react-router-dom';
import { useHouseProperties } from 'hooks/useHouseProperties';
import NavTabs from 'ui-library/NavTabs';
import { usePrice } from 'api/price';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';
import { useIsViewingOlderVersion } from 'hooks/useIsViewingOlderVersion';

const StyledBadge = styled(Badge)`
  color: #fff;
  background-color: ${({ theme }) => theme.colors.messages.warning};
  margin-left: 0.25rem;
  border-radius: 10px;
`;

type Props = {
  isHouseTemplate?: boolean;
};
const HouseNav = ({ isHouseTemplate }: Props) => {
  const { hasGarage, hasTopFloor, hasBasement } = useHouseProperties();
  const isViewingOlderVersion = useIsViewingOlderVersion();
  const { price } = usePrice();

  const { caseNumber, templateCalculationId } =
    useParams<{ caseNumber: string; templateCalculationId: string }>();
  const urlBase = isHouseTemplate ? 'husmall' : 'case';
  const id = isHouseTemplate ? templateCalculationId : caseNumber;

  const links = [
    {
      to: `/${urlBase}/${id}/huskalkyl/hustyp`,
      label: 'Hustyp',
      leftToDefine: price?.deliveryDeclarationValidation.validationItemDictionary?.parameters?.numOfMessages,
    },
    {
      to: `/${urlBase}/${id}/huskalkyl/bottenvaning`,
      label: 'Bottenvåning',
      leftToDefine: price?.deliveryDeclarationValidation.validationItemDictionary?.firstFloor?.numOfMessages,
    },
    {
      to: `/${urlBase}/${id}/huskalkyl/overvaning`,
      label: 'Övervåning',
      className: hasTopFloor ? '' : 'disabled-link',
      leftToDefine: price?.deliveryDeclarationValidation.validationItemDictionary?.secondFloor?.numOfMessages,
    },
    {
      to: `/${urlBase}/${id}/huskalkyl/kallare`,
      label: 'Källare',
      className: hasBasement ? '' : 'disabled-link',
      leftToDefine: price?.deliveryDeclarationValidation.validationItemDictionary?.basement?.numOfMessages,
    },
    {
      to: `/${urlBase}/${id}/huskalkyl/garage`,
      label: 'Garage',
      className: hasGarage ? '' : 'disabled-link',
      leftToDefine: price?.deliveryDeclarationValidation.validationItemDictionary?.garage?.numOfMessages,
    },
  ];
  return (
    <NavTabs>
      {links.map(({ to, label, className, leftToDefine }) => {
        const link = isViewingOlderVersion ? `${to}?version=${isViewingOlderVersion}` : to;
        return (
          <Link key={to} to={link} className={className}>
            {label}
            {leftToDefine && <StyledBadge>{leftToDefine}</StyledBadge>}
          </Link>
        );
      })}
    </NavTabs>
  );
};

export default HouseNav;
