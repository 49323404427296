import { useConfig } from 'hooks/useConfig';
import { IProduct } from 'models/calculationModel';

export type TitleComponentType = 'none' | 'banner' | 'label';
type LayoutSettings = {
  titleComponentType?: TitleComponentType;
  undefinedProductsTitleType?: TitleComponentType;
  fullSizeInput?: boolean;
  showSelect?: boolean;
};

export const useLayoutConfig = () => {
  const { data: config } = useConfig<any>('productGroupLayouts');

  const getLayoutSettingsForProduct = (product: IProduct): LayoutSettings | null => {
    const settings = config?.[product.productData.productGroupData.productGroupNumber];
    return (settings as LayoutSettings) || null;
  };
  return getLayoutSettingsForProduct;
};
