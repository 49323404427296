import { useMutation, useQuery, useQueryClient } from 'react-query';
import { adFetch } from 'adalConfig';
import { useCurrentCase } from 'api/case';

import { objectToParams } from 'helpers';
import { IVersionResponse } from 'models/calculationModel';

const useCalculationVersions = () => {
  const { currentCase } = useCurrentCase();

  const paramStr = objectToParams({ caseId: currentCase?.id });
  const {
    data: calculationVersions,
    isFetching,
    isLoading,
  } = useQuery(
    ['versions', currentCase?.id],
    () => adFetch<IVersionResponse>(`/calculation/versions?${paramStr}`),
    {
      staleTime: Infinity,
      enabled: Boolean(currentCase),
    },
  );

  return { calculationVersions, isFetching, isLoading };
};

type UpdateCommentRequest = {
  comment: string;
  version: number;
  calculationId: number;
};

const useUpdateVersionComment = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ({ comment, version, calculationId }: UpdateCommentRequest) =>
      adFetch(
        `/calculation/comment?comment=${comment}&calculationId=${calculationId}&houseVersion=${version}`,
        {
          method: 'PUT',
        },
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['versions']);
      },
    },
  );
  return mutation;
};

export { useCalculationVersions, useUpdateVersionComment };
