import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { adFetch } from 'adalConfig';
import { useCurrentCase } from 'api/case';
import { ICalculation, IHouse } from 'models/calculationModel';
import { useGlobalStateValue } from 'stateContext';
import { useSetRecoilState } from 'recoil';
import { calculationHasChangesAtom } from 'atoms';
import { useIsViewingOlderVersion } from './useIsViewingOlderVersion';

type UseCurrentCalc = {
  currentCalculation: ICalculation | undefined;
  fetchingCalculation: boolean;
  getHouse: () => IHouse | undefined;
};

type HouseVersion = 'currentHouse' | 'houseFromContract';

const useCurrentCalculation = (houseVersion: HouseVersion = 'currentHouse'): UseCurrentCalc => {
  const { currentCase, caseNumber } = useCurrentCase();
  const { templateCalculationId } = useParams<{ templateCalculationId: string }>();
  const { setCurrentCalculation } = useGlobalStateValue();
  const setCalculationHasChanged = useSetRecoilState(calculationHasChangesAtom);
  const version = useIsViewingOlderVersion();

  const { data: initialCalculation, isFetching } = useQuery<ICalculation>(
    ['calculation', caseNumber, version],
    () =>
      adFetch<ICalculation>(
        `/calculation?caseId=${currentCase?.id}${version ? `&houseVersion=${version}` : ''}`,
      ),
    {
      enabled: Boolean(currentCase?.id),
      staleTime: Infinity,
    },
  );

  const { data: initialHouseTemplateCalculation, isFetching: isFetchingTemplate } = useQuery<ICalculation>(
    ['templateCalculation', templateCalculationId],
    () => adFetch<ICalculation>(`/calculation/housetemplate?calculationId=${templateCalculationId}`),
    {
      enabled: Boolean(templateCalculationId),
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    if (initialCalculation) {
      setCurrentCalculation(initialCalculation);
      setCalculationHasChanged(false);
    }
    if (initialHouseTemplateCalculation) {
      setCurrentCalculation(initialHouseTemplateCalculation);
      setCalculationHasChanged(false);
    }
  }, [initialCalculation, initialHouseTemplateCalculation, setCurrentCalculation, setCalculationHasChanged]);

  const getHouse = (): IHouse | undefined => {
    return initialCalculation?.[houseVersion];
  };

  return {
    currentCalculation: initialCalculation,
    fetchingCalculation: isFetching || isFetchingTemplate,
    getHouse,
  };
};

export { useCurrentCalculation };
