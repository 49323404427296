import { useState } from 'react';
import styled from 'styled-components';
import { ISubProduct } from 'models/calculationModel';
import { useGlobalStateValue } from 'stateContext';
import LabeledCollapse from 'ui-library/LabeledCollapse';
import { FloorName } from 'models/models';
import ProductComponent from '../ProductComponent';
import { getTotalQuantity } from './getTotalQuantity';

const WindowProductWrapper = styled.div`
  margin-left: 10px;
  border-left: 2px solid ${({ theme }) => theme.colors.primary};
`;

type Props = {
  path: string;
  floor?: FloorName;
  disabled?: boolean;
};
const WindowProduct = ({ path, floor, disabled }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { getValue } = useGlobalStateValue();
  const subProduct = getValue<ISubProduct>(path);

  const product = subProduct.products.length > 0 ? subProduct.products[0] : null;

  const totalQuantity = getTotalQuantity(product?.subProducts || []);
  const label = totalQuantity ? `${subProduct.name} (${totalQuantity})` : subProduct.name;
  return (
    <WindowProductWrapper>
      <LabeledCollapse label={label} open={isOpen} onLabelClick={() => setIsOpen(!isOpen)}>
        {product && product?.subProducts?.length > 0 && (
          <>
            {product.subProducts.map((_sp, idx) => {
              const key = _sp.productGroup;
              if (_sp.rules.allowMultipleRows) {
                return (
                  <ProductComponent
                    key={key}
                    path={`${path}.products[0]`}
                    floor={floor}
                    disabled={disabled}
                  />
                );
              }
              return (
                <WindowProduct
                  key={key}
                  path={`${path}.products[0].subProducts[${idx}]`}
                  floor={floor}
                  disabled={disabled}
                />
              );
            })}
          </>
        )}
      </LabeledCollapse>
    </WindowProductWrapper>
  );
};

export default WindowProduct;
