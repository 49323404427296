import { useGetSubProductField } from 'Admin/api/product';
import SubProductForm from 'Admin/Forms/SubProduct';
import { IAdminProductGroup } from 'models/adminModels';
import Modal from 'ui-library/Modal';

type Props = {
  onClose: () => void;
  subProduct?: IAdminProductGroup;
  parentProductGroup: IAdminProductGroup;
};
const EditSubProduct = ({ onClose, subProduct, parentProductGroup }: Props) => {
  const { data: subProductField } = useGetSubProductField(subProduct?.subProductFieldNumber);

  return (
    <Modal
      size="medium"
      header={`Subprodukt (${subProduct?.name})`}
      show={subProductField !== undefined}
      onClose={onClose}
    >
      {subProductField && (
        <SubProductForm
          subProductField={subProductField}
          subProduct={subProduct}
          parentProductGroup={parentProductGroup}
          onClose={onClose}
        />
      )}
    </Modal>
  );
};

export default EditSubProduct;
