import { useGetHouseTemplates } from 'Admin/api';
import { useGetHouseTemplate } from 'Admin/api/template';
import { useMergeGarageContractorProducts } from 'api/calculation';
import { useSetProductValue } from 'api/price';
import { IProduct } from 'models/calculationModel';
import { useEffect, useState } from 'react';
import { useGlobalStateValue } from 'stateContext';
import Select from 'ui-library/Select';

const GarageSelector = () => {
  const [selectedCalculationId, setSelectedCalculationId] = useState<number>();
  const { getValue } = useGlobalStateValue();
  const { houseTemplates } = useGetHouseTemplates();
  const { data: garageTemplate } = useGetHouseTemplate(selectedCalculationId);
  const setProductValue = useSetProductValue(0);
  const mergeGarageTemplateProducts = useMergeGarageContractorProducts();

  useEffect(() => {
    if (garageTemplate) {
      setProductValue('garage', '', garageTemplate.currentHouse.house.garage, true);
      setTimeout(() => {
        setProductValue('garageTemplate', '', garageTemplate.currentHouse.house.garage);
      }, 10);
      mergeGarageTemplateProducts(garageTemplate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [garageTemplate]);

  const garageTemplates = houseTemplates?.filter((tmpl) => tmpl.garageProductNumber !== -1);
  const handleChange = (option: any) => {
    setSelectedCalculationId(option.value.calculationId);
  };
  const templateProduct = getValue<IProduct>('garage.templateProduct');
  const selectedTemplate = garageTemplates?.find(
    (tmpl) => tmpl.garageProductNumber === templateProduct?.productData.productNumber,
  );

  return (
    <Select
      placeholder="Välj garagemall"
      label="Garagemall"
      options={garageTemplates?.map((tmpl) => ({ value: tmpl, label: tmpl.name }))}
      onChange={handleChange}
      value={{ label: selectedTemplate?.name, value: selectedTemplate }}
    />
  );
};

export default GarageSelector;
