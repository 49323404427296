import { useEffect } from 'react';
import { Link, Redirect, Route, Switch, useParams, Prompt } from 'react-router-dom';
import { matchPath } from 'react-router';
import styled from 'styled-components';

import FormWrapper from 'layout/FormWrapper';
import CalculatorWrapper from 'layout/CalculatorWrapper';
import CalculationPrice from 'components/PriceBox';
import Log from 'components/Log';
import { useCurrentCase } from 'api/case';
import { CaseStatuses } from 'models/models';
import ConstructionOrderCounter from 'components/ConstructionOrderValidation/Counter';
import BigButton from 'ui-library/Button/BigButton';
import { FaChevronLeft } from 'react-icons/fa';
import { useIsViewingOlderVersion } from 'hooks/useIsViewingOlderVersion';
import WarningBox from 'ui-library/WarningBox';
import { useRecoilValue } from 'recoil';
import { calculationHasChangesAtom } from 'atoms';
// import RemovedProducts from 'components/RemovedProducts';

import Contractor from './Contractor/Contractor';
import House from './House/House';

import CalculatorNav from './House/CalculatorNav';

import HouseTemplate from './House/HouseTemplate';

const BackButton = styled(BigButton)`
  position: fixed;
  top: 5rem;
  left: -0.5rem;
  width: 220px;
  svg {
    margin-left: 0;
  }
`;

const NavWrapper = styled.div`
  margin-top: -10px;
`;
const OlderVersionWarningWrapper = styled.div`
  width: 53.4%;
  margin: 0 auto;
  position: fixed;
  top: 40px;
  left: 18.3%;
  z-index: 100;
`;

const Calculator = () => {
  const calculationHasChanges = useRecoilValue(calculationHasChangesAtom);
  const { caseNumber } = useParams<{ caseNumber: string }>();
  const { currentCase } = useCurrentCase();
  const isViewingOlderVersion = useIsViewingOlderVersion();

  const isConstructionOrder = Boolean(
    currentCase?.status && currentCase?.status >= CaseStatuses.SIGNED_CONTRACT,
  );
  useEffect(() => {
    window.onbeforeunload = function OnBeforeClose(e: any) {
      if (calculationHasChanges) {
        e.returnValue = '';
        return true;
      }
      return null;
    };
  }, [calculationHasChanges]);

  return (
    <CalculatorWrapper>
      {/* <RemovedProducts /> */}
      <Prompt
        when={calculationHasChanges}
        message={(location) => {
          const match = matchPath(location.pathname, {
            path: '/case/:caseNumber/(huskalkyl|entreprenad)',
            exact: false,
          });
          if (!match) {
            return window.confirm('Du har osparade ändringar. Om du lämnar sidan kan dom gå förlorade.');
          }
          return true;
        }}
      />
      {isViewingOlderVersion && (
        <OlderVersionWarningWrapper>
          <WarningBox>
            <b>OBS!</b> Du tittar på en äldre version av det här ärendet (version {isViewingOlderVersion})
          </WarningBox>
        </OlderVersionWarningWrapper>
      )}
      <BackButton>
        {currentCase && (
          <Link
            to={`/case/${currentCase.caseNumber}${
              currentCase.status >= CaseStatuses.SIGNED_CONTRACT ? '/tillverkningsorder' : ''
            }`}
          >
            <FaChevronLeft /> Tillbaka till ärende
          </Link>
        )}
        {!currentCase && (
          <Link to="/admin/kataloghus">
            <FaChevronLeft /> Tillbaka till kataloghus
          </Link>
        )}
      </BackButton>
      <CalculationPrice />
      {isConstructionOrder && <ConstructionOrderCounter />}
      <FormWrapper>
        <NavWrapper>
          <CalculatorNav />
        </NavWrapper>
        <Switch>
          <Route path="/case/:caseNumber/huskalkyl" exact>
            <Redirect
              to={`/case/${caseNumber}/huskalkyl/hustyp${
                isViewingOlderVersion ? `?version=${isViewingOlderVersion}` : ''
              }`}
            />
          </Route>
          <Route path="/case/:caseNumber/entreprenad">
            <Contractor />
          </Route>
          <Route path="/case/:caseNumber/huskalkyl">
            <House />
          </Route>
          <Route path="/husmall/:templateCalculationId">
            <HouseTemplate />
          </Route>
        </Switch>
      </FormWrapper>
      <FormWrapper>
        <Log />
      </FormWrapper>
    </CalculatorWrapper>
  );
};

export default Calculator;
