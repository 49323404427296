import { useGetProductGroups } from 'Admin/api/product';
import { IAdminProductGroup } from 'models/adminModels';
import { useCurrentCalculation } from 'hooks/useCurrentCalculation';
import StandardBadge from 'ui-library/StandardBadge';
import styled from 'styled-components';
import Loader from 'ui-library/Loader';
import { useCurrentCase } from 'api/case';
import Box from 'ui-library/Box';

const StyledPriceListItem = styled.div`
  border-left: 3px solid ${({ theme }) => theme.colors.primary};
  padding-left: 1rem;
`;

type Props = {
  productGroup: IAdminProductGroup;
};
const PriceListItem = ({ productGroup }: Props) => {
  return (
    <StyledPriceListItem>
      <h4>{productGroup.name}</h4>
      <div>
        <table className="table table-striped table-hover">
          <thead>
            <th>Standard</th>
            <th>Namn</th>
            <th>Pris inkl. moms</th>
            <th>Avdragspris inkl. moms</th>
          </thead>
          <tbody>
            {productGroup.products.map((product) => {
              return (
                <tr>
                  <td>
                    <ul className="standard-product">
                      {product.isStandardCustom && (
                        <StandardBadge title="Standard, specialhus" type="custom">
                          S
                        </StandardBadge>
                      )}
                      {product.isStandardCatalogue && (
                        <StandardBadge title="Standard, kataloghus" type="catalogue">
                          K
                        </StandardBadge>
                      )}
                      {product.isStandardCatalogueWildStrawberry && (
                        <StandardBadge title="Standard, smultronställe" type="wildStrawBerry">
                          Sm
                        </StandardBadge>
                      )}
                      {product.excludeFromWildStrawberry && (
                        <span className="fa fa-stack">
                          <StandardBadge title="Exkluderad från smultronställe" type="wildStrawBerry">
                            Sm
                          </StandardBadge>
                          <i
                            className="fa fa-ban fa-stack-2x"
                            title="Exkluderad från smultronställe"
                            style={{ marginTop: '1px', marginLeft: '-5px' }}
                          />
                        </span>
                      )}
                    </ul>
                  </td>
                  <td>{product.name}</td>
                  <td>{product.priceIncludingVat}</td>
                  <td>{product.deductionPrice}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {productGroup.adminSubProductGroups.length > 0 &&
          productGroup.adminSubProductGroups.map((subProductGroup) => {
            return <PriceListItem key={subProductGroup.id} productGroup={subProductGroup} />;
          })}
      </div>
    </StyledPriceListItem>
  );
};

const PriceList = () => {
  const { currentCalculation } = useCurrentCalculation();
  const { currentCase } = useCurrentCase();
  const { isLoading, productGroupsResponse: productGroups } = useGetProductGroups(currentCalculation?.date);
  if (isLoading) return <Loader label="Hämtar prislista..." />;
  return (
    <Box>
      <h2>Prislista {currentCase?.name}</h2>
      {productGroups?.adminProductGroups.map((productGroup) => (
        <PriceListItem key={productGroup.id} productGroup={productGroup} />
      ))}
    </Box>
  );
};

export default PriceList;
