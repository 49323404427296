import { useGetProductGroupsDict, useGetProductsDict } from 'Admin/api/product';
import { IDependency } from 'models/adminModels';
import Button from 'ui-library/Button';
import Checkbox from 'ui-library/Checkbox';
import InputField from 'ui-library/InputField';
import Modal from 'ui-library/Modal';
import ControlledSelect from 'ui-library/Select/ControlledSelect';
import { dependencyEnums } from '../../constants';

type Props = {
  show: boolean;
  onClose: () => void;
  onSave: (dependency: IDependency) => void;
  path: string;
  form: any;
  control: any;
};
const ProductDependencyForm = ({ show, onClose, onSave, path, form, control }: Props) => {
  const { register, getValues, watch } = form;
  const { data: allProducts = {} } = useGetProductsDict({ date: false });
  const { data: allProductGroups = {} } = useGetProductGroupsDict({ date: false });
  if (!show) return null;

  const currentSourceType = watch(`${path}.sourceType`);

  const options = {
    conditionType: [
      { label: 'Ingen', value: dependencyEnums.conditionType.none },
      { label: 'Lika med', value: dependencyEnums.conditionType.source_equal },
      { label: 'Större än', value: dependencyEnums.conditionType.source_greater },
      { label: 'Större än eller lika med', value: dependencyEnums.conditionType.source_greater_or_equal },
      { label: 'Mindre än', value: dependencyEnums.conditionType.source_smaller },
      { label: 'Mindre än eller lika med', value: dependencyEnums.conditionType.source_smaller_or_equal },
    ],
    operationType: [
      { label: 'Lägg till värde till mål', value: dependencyEnums.operationType.add_to_target_value },
      {
        label: 'Rensa om källa ej existerar',
        value: dependencyEnums.operationType.clear_if_source_does_not_exist,
      },
      { label: 'Rensa om källa existerar', value: dependencyEnums.operationType.clear_if_source_exists },
      { label: 'Skriv över målets värde', value: dependencyEnums.operationType.replace_target_value },
      {
        label: 'Sätt mål till 1 om källa ej existerar',
        value: dependencyEnums.operationType.set_target_to_1_if_source_does_not_exist,
      },
      {
        label: 'Sätt mål till 1 om källa existerar',
        value: dependencyEnums.operationType.set_target_to_1_if_source_exists,
      },
      { label: 'Gör inget', value: dependencyEnums.operationType.do_nothing_just_run_modifier },
    ],
    productFieldPropertyType: [
      { label: 'Proptype antal', value: dependencyEnums.productFieldPropertyType.proptype_quantity },
      {
        label: 'Proptype antal rader',
        value: dependencyEnums.productFieldPropertyType.proptype_number_of_added_products,
      },
    ],
    sourceType: [
      { label: 'Alla från produktkategori', value: dependencyEnums.sourceType.all_from_product_group },
      { label: 'Specifik produkt', value: dependencyEnums.sourceType.specific_product },
    ],
    modifyOperator: [
      { label: 'Addera', value: dependencyEnums.modifyOperator.add },
      { label: 'Subtrahera', value: dependencyEnums.modifyOperator.subtract },
      { label: 'Dividera', value: dependencyEnums.modifyOperator.divide },
      { label: 'Multiplicera', value: dependencyEnums.modifyOperator.multiply },
    ],
  };
  const handleSave = () => {
    const updated = getValues(path);
    onSave(updated);
  };
  return (
    <Modal show={show} onClose={onClose} header="Editera/lägg till beroende">
      Produktberoende
      <ControlledSelect
        label="Operationstyp"
        name={`${path}.operationType`}
        options={options.operationType}
        control={control}
      />
      <ControlledSelect
        label="Produktfält källa"
        name={`${path}.sourcePropertyType`}
        options={options.productFieldPropertyType}
        control={control}
      />
      <ControlledSelect
        label="Produktfält mål"
        name={`${path}.targetPropertyType`}
        options={options.productFieldPropertyType}
        control={control}
      />
      <ControlledSelect
        label="Källtyp"
        name={`${path}.sourceType`}
        options={options.sourceType}
        control={control}
      />
      <ControlledSelect
        disabled={currentSourceType !== dependencyEnums.sourceType.specific_product}
        label="Kopplad produkt"
        name={`${path}.sourceID`}
        options={Object.entries(allProducts).map(([productNumber, productName]) => ({
          label: productName,
          value: productNumber,
        }))}
        control={control}
      />
      <ControlledSelect
        disabled={currentSourceType !== dependencyEnums.sourceType.all_from_product_group}
        label="Kopplad produktgrupp"
        name={`${path}.sourceID`}
        options={Object.entries(allProductGroups).map(([productGroupNumber, productName]) => ({
          label: productName,
          value: productGroupNumber,
        }))}
        control={control}
      />
      <ControlledSelect
        label="Modifieringsoperator"
        name={`${path}.resultModifyOperator`}
        options={options.modifyOperator}
        control={control}
      />
      <InputField
        label="Resultat modifieringsvärde"
        {...register(`${path}.resultModifyValue`, { valueAsNumber: true })}
      />
      <ControlledSelect
        label="Villkorstyp"
        name={`${path}.conditionType`}
        options={options.conditionType}
        control={control}
      />
      <InputField label="Villkorsvärde" {...register(`${path}.conditionValue`, { valueAsNumber: true })} />
      <InputField label="Köordning" {...register(`${path}.resolveOrder`, { valueAsNumber: true })} />
      <div>
        <h4>Flaggor</h4>
        <Checkbox label="Bara för enplansvilla" {...register(`${path}.onlyForOneStoryHouse`)} />
        <Checkbox label="Bara för tvåplansvilla" {...register(`${path}.onlyForTwoStoryHouse`)} />
        <Checkbox
          label="Inkludera bara källor från samma våning"
          {...register(`${path}.onlyIncludeSourcesFromSameFloor`)}
        />
      </div>
      <Button type="button" onClick={handleSave}>
        Spara
      </Button>
    </Modal>
  );
};

export default ProductDependencyForm;
