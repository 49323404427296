import { useCurrentUser } from 'hooks/useCurrentUser';
import { IVersion } from 'models/calculationModel';
import { useState } from 'react';
import Button from 'ui-library/Button';
import LabeledCollapse from 'ui-library/LabeledCollapse';
import { useRecoilValue } from 'recoil';
import { caseChangedAtom } from 'atoms';
import { ButtonWrapper } from '../Quote/ActionButtons';
import BNumberModal from './BNumberModal';
import GenerateModal from './GenerateModal';

type Props = {
  version: IVersion;
};
const ActionButtons = ({ version }: Props) => {
  const caseHasUnsavedChanges = useRecoilValue(caseChangedAtom);
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [showBNumberModal, setShowBNumberModal] = useState(false);
  const { currentUser } = useCurrentUser();

  const handleOpenContract = () => {
    setShowGenerateModal(true);
  };
  const handleSign = () => {
    setShowBNumberModal(true);
  };

  return (
    <>
      <LabeledCollapse label="...">
        <ButtonWrapper>
          <Button disabled={caseHasUnsavedChanges} onClick={handleOpenContract}>
            Generera avtal
          </Button>
          {currentUser?.isAdmin && (
            <Button disabled={caseHasUnsavedChanges} onClick={handleSign}>
              Ange avtal påskrivet
            </Button>
          )}
        </ButtonWrapper>
      </LabeledCollapse>
      <GenerateModal show={showGenerateModal} onClose={() => setShowGenerateModal(false)} version={version} />
      <BNumberModal show={showBNumberModal} onClose={() => setShowBNumberModal(false)} version={version} />
    </>
  );
};

export default ActionButtons;
