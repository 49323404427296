import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body{
    font-size: 16px;
    padding: 5rem 0px 3rem 0px;
    background-color: #ecf0f1;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  }

  label {
    margin-bottom: 0.25rem;
    margin-top: 0.5rem;
  }

  body:after {
    background: url("assets/brahusbg.png");
    background-size: cover;
    opacity: 0.2;
    filter: alpha(opacity=20);
    content: "";
    top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: fixed;
      z-index: -1;
  }

  h1,h2,h3,h4,h5{
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
  }
  ul,li{
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`;

export default GlobalStyle;
