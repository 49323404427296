import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import List from 'ui-library/List';
import { useGetCustomerCases, useGetCustomers } from 'Admin/api';
import CustomerDetailsForm from 'Admin/Components/Customer/CustomerDetailsForm';
import { AddCase } from 'Admin/Modals';
import Loader from 'ui-library/Loader';
import { useCurrentUser } from 'hooks/useCurrentUser';

const CustomerDetailsWrapper = styled.div`
  margin: 20px;
  padding: 20px;
  margin-top: 0;
`;
const CaseList = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.bgLight};
`;

const CustomerDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [showAddCase, setShowAddCase] = useState(false);
  const { customers, isLoading } = useGetCustomers();
  const { currentUser } = useCurrentUser();
  const { customerCases, isLoading: loadingCases, isFetching: fetchingCases } = useGetCustomerCases(id);
  if (isLoading) return <Loader label="hämtar kund" />;

  const currentCustomer = customers?.find((c) => c.id === Number(id));

  return (
    <CustomerDetailsWrapper>
      <h3>
        {currentCustomer?.firstName} {currentCustomer?.lastName}
      </h3>
      <Row>
        <Col sm="12" md="6">
          <CustomerDetailsForm customer={currentCustomer} />
        </Col>
        <Col sm="12" md="6">
          <CaseList>
            <h4>Ärenden</h4>
            {(loadingCases || fetchingCases) && <Loader label="Hämtar ärenden" />}
            <List>
              {customerCases?.map(({ name, caseNumber, userId }) => {
                const disabled =
                  currentUser?.userId !== userId && !(currentUser?.isAdmin || currentUser?.isSemiAdmin);
                return (
                  <Link key={caseNumber} to={`/case/${caseNumber}`} className={disabled ? 'disabled' : ''}>
                    {name}
                  </Link>
                );
              })}
              <a onClick={() => setShowAddCase(true)}>
                <b>+ Lägg till nytt</b>
              </a>
            </List>
          </CaseList>
        </Col>
      </Row>
      <AddCase show={showAddCase} onClose={() => setShowAddCase(false)} customer={currentCustomer} />
    </CustomerDetailsWrapper>
  );
};

export default CustomerDetails;
