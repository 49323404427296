import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { usePrice } from 'api/price';
import { formatPrice } from 'helpers';
import Loader from 'ui-library/Loader';

const ContractorPriceWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bgDark};
  color: #fff;
  padding: 2.5rem;
  hr {
    color: #fff;
    border-color: #fff;
  }
`;
const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  label {
    font-weight: bold;
  }
`;
const Price = styled.span<any>`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.875rem;
  font-weight: 300;
  ${({ isLoading }) =>
    isLoading &&
    `
    opacity: 0.5;
  `}
`;
type Props = {
  type: string;
};
const ContractorPrice = ({ type }: Props) => {
  const { price, isLoading, isFetching } = usePrice();

  if (!price) return null;
  const currentContractorPrice = price?.currentContractorPrices[type];

  const loading = isLoading || isFetching;
  return (
    <ContractorPriceWrapper>
      <Container>
        <Row>
          <Col md={6}>&nbsp;</Col>
          <Col md={6}>
            <PriceRow>
              <label>Totalt exkl. moms</label>
              <Price isLoading={loading}>
                {loading && <Loader />}
                {currentContractorPrice && formatPrice(currentContractorPrice.totalPrice)}
              </Price>
            </PriceRow>
            <hr />
            <PriceRow>
              <label>Totalt inkl. moms</label>
              <Price isLoading={loading}>
                {loading && <Loader />}
                {currentContractorPrice && formatPrice(Math.round(currentContractorPrice.totalPrice * 1.25))}
              </Price>
            </PriceRow>
          </Col>
        </Row>
      </Container>
    </ContractorPriceWrapper>
  );
};

export default ContractorPrice;
