import { useInvalidatePrice } from 'api/price';
import { useGlobalStateValue } from 'stateContext';
import Checkbox from 'ui-library/Checkbox';

export const ExcludeCheckbox = ({
  path,
  property,
  onChange,
  disabled,
  label,
}: {
  path: string;
  property: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  label?: string;
}) => {
  const { setProductValue, getValue } = useGlobalStateValue();
  const invalidatePrice = useInvalidatePrice();
  const handleChange = (e: any) => {
    setProductValue(path, property, e.target.checked);
    if (onChange) {
      onChange(e);
    }
    invalidatePrice();
  };

  return (
    <Checkbox
      disabled={disabled}
      label={label}
      checked={getValue(path, property) || false}
      onChange={handleChange}
    />
  );
};
