import Button from 'ui-library/Button';
import { FaPen, FaTrash } from 'react-icons/fa';
import styled from 'styled-components';
import { IAdminProductGroup } from 'models/adminModels';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const StyledSubProductItem = styled.li`
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
  & > div {
    display: flex;
  }
`;
const StyledSortableSubProductItem = styled(StyledSubProductItem)`
  cursor: grab;
  border: 1px dashed #ddd;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
`;

type Props = {
  subProduct?: IAdminProductGroup;
  idx: number;
  showEditSubProductForm: (subProduct?: IAdminProductGroup) => void;
  handleRemove: (idx: number, subProduct?: IAdminProductGroup) => () => void;
};
const SortableSubProductItem = ({ subProduct }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: `${subProduct?.productGroupNumber}`,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <StyledSortableSubProductItem
      key={subProduct?.id}
      style={style}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      {subProduct?.name} ({subProduct?.subProductFieldNumber})
    </StyledSortableSubProductItem>
  );
};

export const SubProductItem = ({ subProduct, handleRemove, showEditSubProductForm, idx }: Props) => {
  return (
    <StyledSubProductItem>
      {subProduct?.name} ({subProduct?.subProductFieldNumber})
      <div>
        <Button size="small" icon variant="secondary" type="button" onClick={() => showEditSubProductForm()}>
          <FaPen />
        </Button>
        <Button size="small" type="button" icon onClick={handleRemove(idx, subProduct)}>
          <FaTrash />
        </Button>
      </div>
    </StyledSubProductItem>
  );
};

export default SortableSubProductItem;
