import { Link, matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import logo from 'assets/logo.png';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { FaUserCircle } from 'react-icons/fa';
import Button from 'ui-library/Button';
import { authContext } from 'adalConfig';

const StyledHeader = styled.div`
  min-height: 3.5625rem;
  /* width: 100%; */
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  background-color: #fff;
  display: flex;
  z-index: 100;
  border-bottom: 1px solid #ddd;
  align-items: center;
`;

const Logo = styled.div`
  background-color: #0906e3;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 18.125rem;
  margin-right: 1rem;
  height: 3.5625rem;
  h3 {
    font-size: 1.5rem;
  }
  img {
    height: 2.25rem;
    width: 2.25rem;
    margin-right: 1rem;
  }
`;

const TopNavWrapper = styled.ul`
  display: flex;
  li {
    color: ${({ theme }) => theme.colors.primary};
    height: 3.5625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3.125rem;
    a {
      font-size: 1.125rem;
      text-decoration: none;
      color: inherit;
    }
    &.isActive {
      border-bottom: 4px solid;
    }
  }
`;

const TopNav = () => {
  const { pathname } = useLocation();

  const matchStart =
    Boolean(
      matchPath(pathname, {
        path: '/',
        exact: false,
        strict: false,
      }),
    ) && !pathname.includes('admin');
  const matchAdmin = Boolean(
    matchPath(pathname, {
      path: '/admin',
      exact: false,
      strict: false,
    }),
  );

  return (
    <TopNavWrapper>
      <li className={matchStart ? 'isActive' : ''}>
        <Link to="/">Start</Link>
      </li>
      <li className={matchAdmin ? 'isActive' : ''}>
        <Link to="/admin">Administration</Link>
      </li>
    </TopNavWrapper>
  );
};

const LoggedInUser = styled.div`
  margin-left: auto;
  margin-right: 1rem;
  svg {
    margin-right: 0.5rem;
  }
`;

const Header = () => {
  const { currentUser } = useCurrentUser();
  return (
    <StyledHeader>
      <Logo>
        <img src={logo} alt="BraHus" />
        <h3>Huskonfigurator</h3>
      </Logo>
      <TopNav />
      <LoggedInUser>
        <FaUserCircle />
        Inloggad: {currentUser?.userName}
      </LoggedInUser>
      <Button onClick={() => authContext.logOut()}>Logga ut</Button>
    </StyledHeader>
  );
};

export default Header;
