import { IProduct, ISubProduct } from 'models/calculationModel';
import { useGlobalStateValue } from 'stateContext';
import DynamicProductList from 'components/ProductComponents/DynamicProductList';
import { FloorName } from 'models/models';
import { useLayoutConfig } from '../ProductComponent/useLayoutConfig';
import { TitleComponent } from '../ProductComponent/TitleComponent';

const getLeftToDefine = (baseProduct: IProduct, definedProducts: IProduct[]) => {
  const target = baseProduct.quantity;
  const currentlyDefined = definedProducts.reduce((acc, curr) => acc + curr.quantity, 0);

  return target - currentlyDefined;
};

type Props = {
  path: string;
  floor?: FloorName;
  disabled?: boolean;
  overrideTitle?: string;
};
const UndefinedProducts = ({ path, floor, disabled, overrideTitle }: Props) => {
  const { getValue } = useGlobalStateValue();
  const getLayoutSettingsForProduct = useLayoutConfig();

  const parts = path.split('.');
  let floorName = '';
  let definedProductsName = '';
  let subFloorName = '';

  if (parts.length === 2) {
    [floorName, definedProductsName] = parts;
  }
  if (parts.length === 3) {
    [floorName, subFloorName, definedProductsName] = parts;
    floorName = `${floorName}.${subFloorName}`;
  }

  // Products that does not follow the "standard/defined" pattern, hence hack:
  let standardProductName = definedProductsName.replace('defined', 'standard');
  if (standardProductName.includes('Eaves')) {
    standardProductName = 'undefinedEaves';
  }
  if (definedProductsName === 'garageDoors') {
    standardProductName = 'standardGarageDoor';
  }
  if (definedProductsName === 'definedInteriorRoofs') {
    standardProductName = 'standardInteriorRoof'; // Aaahh...
  }

  const definedProductsPath = `${floorName}.${definedProductsName}`;
  const standardProductPath = `${floorName}.${standardProductName}`;

  const baseProduct = getValue<IProduct>(standardProductPath);
  const products = getValue<IProduct[]>(definedProductsPath);

  if (!baseProduct || !products) return null;

  const leftToDefine = getLeftToDefine(baseProduct, products);

  const fakeParentSubProduct = {
    rules: {
      renderInput: true,
      renderSelect: true,
    },
    products,
    productGroup: baseProduct.productData.productGroupData.productGroupNumber,
  } as ISubProduct;
  const layoutSettings = getLayoutSettingsForProduct(baseProduct);

  return (
    <>
      {layoutSettings?.undefinedProductsTitleType && (
        <TitleComponent product={baseProduct} type={layoutSettings?.undefinedProductsTitleType} />
      )}
      Kvar att definiera: {leftToDefine}st
      <DynamicProductList
        subProduct={fakeParentSubProduct}
        path={definedProductsPath}
        property=""
        floor={floor}
        disabled={disabled}
        overrideTitle={overrideTitle}
      />
    </>
  );
};

export default UndefinedProducts;
