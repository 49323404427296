import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { ICustomer } from 'models/models';
import Button, { FormSubmitWrapper } from 'ui-library/Button';
import InputField from 'ui-library/InputField';
import { useCreateCustomer } from 'Admin/api/customer';
import DuplicateCustomerMessage from './DuplicateCustomerMessage';

const FormWrapper = styled.form`
  width: 100%;
  .input-field {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
`;

const customerTemplate: ICustomer = {
  id: null,
  firstName: '',
  lastName: '',
  address: '',
  postalAddress: '',
  phoneWork: '',
  phoneHome: '',
  phoneCell: '',
  email: '',
  personalIdentityNumber: '',
  municipality: '',
};

const TwoFields = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .input-field {
    width: 100%;
  }
`;

const CustomerAddForm = () => {
  const [duplicateCustomers, setDuplicateCustomers] = useState<any>();
  const history = useHistory();
  const { mutate } = useCreateCustomer();
  const { register, handleSubmit, formState } = useForm<ICustomer>({
    defaultValues: customerTemplate,
  });
  const queryClient = useQueryClient();
  const onSave = (newCustomer: ICustomer, skipDuplicateCheck?: boolean) => {
    mutate(
      { customer: newCustomer, skipDuplicateCheck },
      {
        onSuccess: (createdCustomer: ICustomer) => {
          queryClient.invalidateQueries(['customers']);
          toast.success(`Kund: ${createdCustomer.firstName} ${createdCustomer.lastName} skapad`);
          history.push(`/admin/kunder/${createdCustomer.id}`);
        },
        onError: (error: any) => {
          if (error.possibleDuplicates) {
            setDuplicateCustomers(error.possibleDuplicates);
          }
        },
      },
    );
  };
  const { isDirty } = formState;
  return (
    <FormWrapper onSubmit={handleSubmit((customer) => onSave(customer))}>
      {duplicateCustomers && (
        <DuplicateCustomerMessage
          duplicateCustomers={duplicateCustomers}
          createAnyway={() => handleSubmit((customer) => onSave(customer, true))()}
        />
      )}
      <TwoFields>
        <InputField label="Förnamn" {...register('firstName')} />
        <InputField label="Efternamn" {...register('lastName')} />
      </TwoFields>
      <InputField label="Adress" {...register('address')} />
      <InputField label="Postadress" {...register('postalAddress')} />
      <TwoFields>
        <InputField label="Epost" {...register('email')} />
        <InputField label="Telefon (Hem)" {...register('phoneHome')} />
      </TwoFields>
      <TwoFields>
        <InputField label="Telefon (Jobb)" {...register('phoneWork')} />
        <InputField label="Telefon (Mobil)" {...register('phoneCell')} />
      </TwoFields>
      <InputField label="Personnr./Org. nr." {...register('personalIdentityNumber')} />
      <FormSubmitWrapper>
        <Button disabled={!isDirty}>Spara</Button>
      </FormSubmitWrapper>
    </FormWrapper>
  );
};

export default CustomerAddForm;
