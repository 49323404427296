import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { adFetch } from 'adalConfig';
import { objectToParams } from 'helpers';
import {
  IAdminProduct,
  IAdminProductGroup,
  IAdminProductGroupsResponse,
  IAdminSubProduct,
} from 'models/adminModels';
import toast from 'react-hot-toast';

export const useGetProductGroups = (date?: string) => {
  const queryStr = objectToParams({
    date: date || 'false',
  });

  const {
    data: productGroupsResponse,
    isFetching,
    isLoading,
  } = useQuery(
    ['admin', 'productGroups', date],
    () => adFetch<IAdminProductGroupsResponse>(`/admin/allproductgroupsandproducts?${queryStr}`, {}),
    {
      staleTime: Infinity,
    },
  );
  return { productGroupsResponse, isFetching, isLoading };
};

type DictRequest = {
  date?: string | false;
};
export const useGetProductsDict = ({ date = false }: DictRequest) => {
  const queryStr = objectToParams({
    date,
  });
  const query = useQuery(
    ['admin', 'productGroupsDict'],
    () => adFetch<any>(`/product/allproducts?${queryStr}`),
    {
      staleTime: Infinity,
    },
  );
  return query;
};

export const useGetProductGroupsDict = ({ date = false }: DictRequest) => {
  const queryStr = objectToParams({
    date,
  });
  const query = useQuery(
    ['admin', 'productsDict'],
    () => adFetch<Record<number, string>>(`/product/allproductgroups?${queryStr}`, {}),
    {
      staleTime: Infinity,
    },
  );
  return query;
};

export const useUpdateProduct = (options?: any) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (product: IAdminProduct) =>
      adFetch('/admin/product', {
        method: 'POST',
        body: JSON.stringify(product),
      }),
    {
      onSuccess: () => {
        toast.success('Produkt sparad');
        queryClient.invalidateQueries(['admin', 'productGroups']);
      },
      ...options,
    },
  );
  return mutation;
};

export const useUpdateProducts = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (products: IAdminProduct[]) =>
      adFetch('/admin/products', {
        method: 'POST',
        body: JSON.stringify(products),
      }),
    {
      onSuccess: () => {
        toast.success('Produkt sparad');
        queryClient.invalidateQueries(['admin', 'productGroups']);
      },
    },
  );
  return mutation;
};

export const useCloneProduct = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (product: IAdminProduct) =>
      adFetch(
        `/admin/product/copy?name=${encodeURI(`${product.name} (kopia)`)}&productNumber=${
          product.productNumber
        }`,
        {
          method: 'POST',
        },
      ),
    {
      onSuccess: () => {
        toast.success('Produkt klonad');
        queryClient.invalidateQueries(['admin', 'productGroups']);
      },
    },
  );
  return mutation;
};

export const useGetSubProductField = (id?: number, options?: any) => {
  const query = useQuery(
    ['subProductField', id],
    () => adFetch<IAdminSubProduct>(`/admin/subproductfield?date=false&subProductFieldNumber=${id}`),
    {
      enabled: id !== undefined && id !== -1,
      retry: false,
      ...options,
    },
  );
  return query;
};

export const useGetSubProductFields = (ids: number[], enabled = true) => {
  const requests = ids.map((id) => ({
    queryKey: ['subProductField', id],
    queryFn: () => adFetch<IAdminSubProduct>(`/admin/subproductfield?date=false&subProductFieldNumber=${id}`),
    enabled,
    staleTime: Infinity,
  }));
  const queries = useQueries(requests);
  return queries;
};

export const useUpdateSubProductField = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (subProductField: IAdminSubProduct) =>
      adFetch('/admin/subproductfields', {
        method: 'POST',
        body: JSON.stringify(subProductField),
      }),
    {
      onSuccess: () => {
        toast.success('Subprodukt sparad');
        queryClient.invalidateQueries(['admin', 'productGroups']);
      },
    },
  );

  return mutation;
};

export const useUpdateSubProductFields = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (subProductFields: IAdminSubProduct[]) =>
      adFetch('/admin/subproductfieldsarray', {
        method: 'POST',
        body: JSON.stringify(subProductFields),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin', 'productGroups']);
        toast.success('Sparat');
      },
    },
  );
  return mutation;
};

export const useDeleteSubProductField = (subProductFieldId?: number) => {
  const { mutate: deleteSubProductField } = useMutation((subProduct) =>
    adFetch('/admin/subproductfields', { method: 'DELETE', body: JSON.stringify(subProduct) }),
  );
  const queryClient = useQueryClient();

  useGetSubProductField(subProductFieldId, {
    onSuccess: (subProduct: any) => {
      deleteSubProductField(subProduct, {
        onSuccess: () => {
          queryClient.invalidateQueries(['admin', 'productGroups']);
        },
        onSettled: () => {
          queryClient.invalidateQueries(['admin', 'productGroups']);
        },
      });
      // console.log('got subproduct, now delete', subProduct);
    },
  });
};

export const useUpdateProductGroup = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (productGroup: IAdminProductGroup) =>
      adFetch('/admin/productgroups', {
        method: 'POST',
        body: JSON.stringify(productGroup),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin', 'productGroupsDict']);
        queryClient.invalidateQueries(['admin', 'productGroups']);
      },
    },
  );
  return mutation;
};
