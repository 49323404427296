import { useUpdateProductGroup, useUpdateSubProductField } from 'Admin/api/product';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { getTemplate } from 'templates';
import Button, { FormSubmitWrapper } from 'ui-library/Button';
import Checkbox from 'ui-library/Checkbox';
import InputField from 'ui-library/InputField';
import { PageProps } from './pageProps';

export const NewProductGroup = ({ productGroup, onClose }: PageProps) => {
  const [step, setStep] = useState(0);
  const queryClient = useQueryClient();
  const [productGroupTemplate, setProductGroupTemplate] = useState<any>(getTemplate('productGroupTemplate'));
  const [subProductFieldTemplate, setSubProductFieldTemplate] = useState<any>(
    getTemplate('subProductFieldTemplate'),
  );
  const { mutate: saveProductGroup } = useUpdateProductGroup();
  const { mutate: saveSubProductField } = useUpdateSubProductField();

  const handleSetName = (e: any) => {
    setProductGroupTemplate({ ...productGroupTemplate, name: e.target.value });
    setSubProductFieldTemplate({ ...subProductFieldTemplate, name: e.target.value });
  };

  const saveSubProduct = () => {
    const subProductToSave = {
      ...subProductFieldTemplate,
      parentProductGroupNumber: productGroup.productGroupNumber,
    };
    saveProductGroup(productGroupTemplate, {
      onSuccess: (response: any) => {
        subProductToSave.childProductGroupNumber = response.productGroupNumber;
        saveSubProductField(subProductToSave, {
          onSuccess: () => {
            onClose();
            queryClient.invalidateQueries(['admin', 'productGroups']);
          },
        });
      },
    });
  };

  return (
    <>
      {step === 0 && <InputField label="Namn" onChange={handleSetName} />}
      {step === 1 && (
        <Checkbox
          label="Användare kan lägga till flera rader (ex. Innerdörrar)"
          checked={subProductFieldTemplate.allowMultipleRows}
          onChange={(e) => {
            setSubProductFieldTemplate({ ...subProductFieldTemplate, allowMultipleRows: e.target.checked });
          }}
        />
      )}
      {step === 2 && (
        <Checkbox
          label="Ska man kunna ange antal för denna underproduktkategori?"
          checked={!subProductFieldTemplate.useQuantityFromParent}
          onChange={(e) => {
            setSubProductFieldTemplate({
              ...subProductFieldTemplate,
              useQuantityFromParent: !e.target.checked,
            });
          }}
        />
      )}
      <FormSubmitWrapper>
        {step < 2 && <Button onClick={() => setStep(step + 1)}>Nästa</Button>}
        {step === 2 && <Button onClick={saveSubProduct}>Spara</Button>}
      </FormSubmitWrapper>
    </>
  );
};
