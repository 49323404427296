import styled from 'styled-components';
import { usePrice } from 'api/price';
import Box from 'ui-library/Box';
import LabeledCollapse from 'ui-library/LabeledCollapse';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useHouseProperties } from 'hooks/useHouseProperties';
import CalculationInput from 'components/CalculationInput';
import { useCalculationIsDisabled } from 'hooks/useCalculationIsDisabled';
import { useIsViewingOlderVersion } from 'hooks/useIsViewingOlderVersion';
import CalculationChangesAlert from 'components/CalculationChangesAlert';
import Button from 'ui-library/Button';
import { useGlobalStateValue } from 'stateContext';
import { useSaveCalculation } from 'api/calculation';
import toast from 'react-hot-toast';
import PriceItem from './PriceItem';
import SaveCalculation from './SaveCalculation';
import CustomerAndHouse from './CustomerAndHouse';

const PriceWrapper = styled.div`
  position: fixed;
  color: #fff;
  top: 4rem;
  right: 1.25rem;
  width: 26%;
  padding: 0 2rem;
  background-color: ${({ theme }) => theme.colors.bgDark};
  hr {
    border-color: #fff;
  }
  button {
    position: absolute;
    top: calc(100% + 1rem);
    left: 0rem;
  }
`;

const CalculationPrice = () => {
  const { currentCalculation } = useGlobalStateValue();
  const { price, isFetching, isLoading } = usePrice();
  const { currentUser } = useCurrentUser();
  const { isSpecialHouse } = useHouseProperties();
  const calculationIsDisabled = useCalculationIsDisabled();
  const isViewingOlderVersion = useIsViewingOlderVersion();
  const { mutate: revertCalculation, isLoading: isResettingVersion } = useSaveCalculation();

  const resetVersion = () => {
    if (window.confirm(`Vill du återställa kalkylen till denna version (version ${isViewingOlderVersion})?`))
      revertCalculation(
        {
          calculation: {
            ...currentCalculation,
            currentHouse: {
              ...currentCalculation.currentHouse,
              comment: `Återskapad från version ${isViewingOlderVersion}`,
            },
          },
        },
        {
          onSuccess: () => {
            toast.success(`Ärende återställt från version ${isViewingOlderVersion}`);
          },
        },
      );
  };

  const loading = isFetching || isLoading;
  return (
    <PriceWrapper>
      <Box>
        <CustomerAndHouse />
        {/* {(isFetching || isLoading) && <Loader />} */}
        {Boolean(currentUser?.isAdmin && isSpecialHouse) && (
          <div>
            <CalculationInput
              disabled={calculationIsDisabled}
              path=""
              property="overridePrice"
              label="Överstyrningspris, hus"
            />
            <CalculationInput
              disabled={calculationIsDisabled}
              path=""
              property="overridePriceGarage"
              label="Överstyrningspris, garage"
            />
          </div>
        )}
        <PriceItem label="Pris materialsats hus:" price={price?.currentHousePrice} loading={loading} />
        <PriceItem
          label="Materialsats garage:"
          price={price?.currentGaragePrice}
          variant="secondary"
          loading={loading}
        />
        {price && price.currentContractorPrices && (
          <LabeledCollapse label="Fler priser">
            <PriceItem
              label="Entreprenad hus:"
              price={price.currentContractorPrices.totalHousePrice * 1.25}
              variant="secondary"
              loading={loading}
            />
            <PriceItem
              label="Entreprenad garage:"
              price={price.currentContractorPrices.totalGaragePrice * 1.25}
              variant="secondary"
              loading={loading}
            />
            <hr />
            <PriceItem
              label="Totalpris hus:"
              price={price.currentContractorPrices.totalHousePrice * 1.25 + price.currentHousePrice}
              variant="secondary"
              loading={loading}
            />
            <PriceItem
              label="Totalpris garage:"
              price={
                price.currentContractorPrices.totalGaragePrice * 1.25 +
                price.currentContractorPrices.totalGaragePrice
              }
              variant="secondary"
              loading={loading}
            />
            <PriceItem
              label="Totalpris:"
              price={
                price?.currentTotalPriceIncludingContractors +
                price?.currentContractorPrices.totalGaragePrice * 0.25 +
                price?.currentContractorPrices.totalHousePrice * 0.25
              }
              loading={loading}
            />
          </LabeledCollapse>
        )}
      </Box>
      {Boolean(!calculationIsDisabled && !isViewingOlderVersion) && <SaveCalculation />}
      {isViewingOlderVersion && (
        <Button disabled={isResettingVersion} onClick={resetVersion}>
          Återställ till denna version
        </Button>
      )}
      <CalculationChangesAlert />
    </PriceWrapper>
  );
};

export default CalculationPrice;
