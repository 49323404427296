import { useGetProductGroupsDict, useGetProductsDict } from 'Admin/api/product';
import ProductDependencyForm from 'Admin/Modals/ProductDependency';
import styled from 'styled-components';
import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import Button from 'ui-library/Button';
import List from 'ui-library/List';
import { FaPen } from 'react-icons/fa';
import { dependencyEnums } from '../../../constants';

const DependencyItem = styled.span`
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
`;
const Actions = styled.div`
  display: flex;
  button {
    margin-left: 0.5rem;
  }
`;

type Props = {
  form: any;
  prefix: string;
};
const DependencyListForm = ({ form, prefix }: Props) => {
  const { control, setValue } = form;
  const [editDependencyId, setEditDependencyId] = useState<number | undefined>();
  const [addingDependency, setAddingDependency] = useState(false);
  const { data: allProducts } = useGetProductsDict({ date: false });
  const { data: allProductGroups } = useGetProductGroupsDict({ date: false });
  const { fields: dependencies, append, remove } = useFieldArray({ control, name: prefix });

  return (
    <div>
      <List>
        {dependencies.map((dep: any, idx) => {
          let depName;
          if (dep.sourceType === dependencyEnums.sourceType.all_from_product_group) {
            depName = allProductGroups?.[dep.sourceID];
          }
          if (dep.sourceType === dependencyEnums.sourceType.specific_product) {
            depName = allProducts?.[dep.sourceID];
          }

          if (!depName) return null;
          return (
            <DependencyItem key={dep.id}>
              {depName}
              <Actions>
                <Button type="button" onClick={() => setEditDependencyId(dep.id)}>
                  <FaPen />
                </Button>
                <Button type="button" onClick={() => remove(idx)}>
                  x
                </Button>
              </Actions>
              <ProductDependencyForm
                show={Boolean(editDependencyId === dep.id)}
                onClose={() => setEditDependencyId(undefined)}
                path={`${prefix}.${idx}`}
                onSave={(dependency) => {
                  setValue(`${prefix}.${idx}`, dependency);
                  setEditDependencyId(undefined);
                }}
                control={control}
                form={form}
              />
            </DependencyItem>
          );
        })}
      </List>
      <Button type="button" onClick={() => setAddingDependency(true)}>
        Lägg till beroende
      </Button>
      <ProductDependencyForm
        show={addingDependency}
        onClose={() => setAddingDependency(false)}
        path="newDependency"
        onSave={(dependency) => {
          append(dependency);
          setAddingDependency(false);

          // setValue(`${prefix}`, dependency);
        }}
        form={form}
        control={control}
      />
    </div>
  );
};
export default DependencyListForm;
