import styled from 'styled-components';
import BigButton from 'ui-library/Button/BigButton';

const ToCalculationButton = styled(BigButton)`
  position: absolute;
  right: -3rem;
  top: 1rem;
`;

export default ToCalculationButton;
