import { useIsViewingOlderVersion } from 'hooks/useIsViewingOlderVersion';
import { Link, useLocation, useParams } from 'react-router-dom';
import NavTabs from 'ui-library/NavTabs';

const CalculatorNav = () => {
  const { caseNumber, templateCalculationId } =
    useParams<{ caseNumber: string; templateCalculationId: string }>();
  const isViewingOlderVersion = useIsViewingOlderVersion();
  const { pathname } = useLocation();
  const isHouseTemplate = pathname.includes('husmall');
  const urlBase = isHouseTemplate ? 'husmall' : 'case';
  const id = isHouseTemplate ? templateCalculationId : caseNumber;

  const links = [
    {
      to: `/${urlBase}/${id}/huskalkyl`,
      label: 'Huskalkyl',
    },
    {
      to: `/${urlBase}/${id}/entreprenad`,
      label: 'Entreprenad',
    },
  ];
  return (
    <NavTabs variant="tabs" exact={false}>
      {links.map(({ to, label }) => {
        const link = isViewingOlderVersion ? `${to}?version=${isViewingOlderVersion}` : to;
        return (
          <Link key={to} to={link}>
            {label}
          </Link>
        );
      })}
    </NavTabs>
  );
};

export default CalculatorNav;
