import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { IoMdClose as CloseIcon } from 'react-icons/io';
import Button from '../Button';

const ModalContent = styled.div`
  position: relative;
`;
const CloseButton = styled(Button)`
  position: absolute;
  top: -60px;
  right: 0;
  svg {
    width: 1.5rem;
    height: 1.5rem;

    path {
      stroke: #fff;
    }
  }
`;

const StyledModal = styled(Modal)`
  .custom-modal {
    min-width: 500px;
    width: auto;
    &.small {
      max-width: 500px;
    }
    &.medium {
      max-width: 60%;
    }
    &.large {
      max-width: 80%;
    }
  }
`;

type sizes = 'small' | 'medium' | 'large';
export type ModalProps = {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
  header?: string;
  size?: sizes;
};
const BaseModal = ({ show, onClose, header, children, size = 'small' }: ModalProps) => {
  // if (!show) return null;
  return (
    <StyledModal dialogClassName={`custom-modal ${size}`} show={show} onHide={onClose} onClose={onClose}>
      {header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Body>
        <ModalContent>
          <CloseButton size="small" icon onClick={() => onClose()}>
            <CloseIcon />
          </CloseButton>
          {children}
        </ModalContent>
      </Modal.Body>
    </StyledModal>
  );
};

export default BaseModal;
