import { forwardRef } from 'react';
import { InputProps } from 'react-html-props';
import { FaCheck } from 'react-icons/fa';
import styled from 'styled-components';
import Label from 'ui-library/Label';

const CheckboxWrapper = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  label {
    display: flex;
    align-items: center;
  }
  input {
    appearance: none;
  }
  span {
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    border: 2px solid ${({ theme }) => theme.colors.border};
    border-radius: 2px;
    margin-right: 0.5rem;
    position: relative;
    cursor: pointer;
    background: #fff;
  }
  svg {
    display: none;
    position: absolute;
    top: 0;
  }
  input:checked + span {
    svg {
      fill: #000;
      display: inline-block;
    }
  }
  &.disabled {
    opacity: 0.5;
  }
`;

interface Props extends InputProps {
  label?: string;
}
const Checkbox = forwardRef(({ label, disabled, ...rest }: Props, ref: any) => {
  return (
    <CheckboxWrapper className={disabled ? 'disabled' : ''}>
      <Label>
        <input type="checkbox" disabled={disabled} {...rest} ref={ref} />
        <span>
          <FaCheck />
        </span>
        {label}
      </Label>
    </CheckboxWrapper>
  );
});

export default Checkbox;
