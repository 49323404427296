import { useCurrentCase } from 'api/case';
import { getHouseName } from 'helpers';
import { useGlobalStateValue } from 'stateContext';
import styled from 'styled-components';

const Wrapper = styled.div`
  border-bottom: 1px solid #fff;
  margin-bottom: 1rem;
`;

const CustomerAndHouse = () => {
  const { currentCase } = useCurrentCase();
  const { currentCalculation } = useGlobalStateValue();

  if (!currentCalculation) return null;
  const houseName = getHouseName(currentCalculation);
  const customerNames = currentCase?.customers
    .map((customer) => `${customer.firstName} ${customer.lastName}`)
    .join(', ');

  return (
    <Wrapper>
      <p>{houseName}</p>
      <span>{customerNames}</span>
    </Wrapper>
  );
};

export default CustomerAndHouse;
