import Button from 'ui-library/Button';
import { PageProps } from './pageProps';

export const StartPage = ({ productGroup, setPage }: PageProps) => {
  return (
    <>
      Vad vill du göra?
      <Button onClick={() => setPage('newProductGroup')}>
        Lägg till produktgrupp under {productGroup.name}
      </Button>
      <Button onClick={() => setPage('connectProductgroup')}>Koppla befintlig produktgrupp</Button>
    </>
  );
};
