import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useCallback, useEffect, useMemo } from 'react';
import { useCurrentCase } from 'api/case';
import { useCalculationFromVersion } from 'api/calculation';
import { IVersion } from 'models/calculationModel';
import { usePrice } from 'api/price';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useMutation, useQuery } from 'react-query';
import { objectToParams } from 'helpers';
import { adFetch } from 'adalConfig';
import { usePdfContext } from 'hooks/usePdfMaker/PdfContext';
import getQuoteDocument from './documents/quote';
import getDeliveryDeclarationDocument from './documents/deliveryDeclaration/deliveryDeclaration';
import getProductionCalculationCostDocument from './documents/productionCostCalculation/productionCalculationCost';
import { getContractDocument } from './documents/contract/contract';
import { getContratB2BDocument } from './documents/contract/contractB2B';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export type DocumentType =
  | 'quote'
  | 'deliveryDeclaration'
  | 'constructionOrder'
  | 'productionCostCalculation'
  | 'contract'
  | 'contractB2B';

export const documentNames: Record<DocumentType, string> = {
  quote: 'Offert',
  deliveryDeclaration: 'Leveransdeklaration',
  constructionOrder: 'Tillverkningsorder',
  contract: 'Kontrakt',
  contractB2B: 'Kontrakt_abm07',
  productionCostCalculation: 'Produktionskostnadskalkyl',
};
const documentTemplates: Record<DocumentType, any> = {
  quote: getQuoteDocument,
  deliveryDeclaration: getDeliveryDeclarationDocument,
  constructionOrder: getDeliveryDeclarationDocument,
  productionCostCalculation: getProductionCalculationCostDocument,
  contract: getContractDocument,
  contractB2B: getContratB2BDocument,
};

const useGetDocument = (name?: string, version?: IVersion) => {
  const { currentCase } = useCurrentCase();
  const params = objectToParams({
    name,
    caseNumber: currentCase?.caseNumber,
    version: version?.version,
  });
  const getQuery = useQuery(
    ['document', name, version?.version, currentCase?.caseNumber],
    () => adFetch<string>(`/document?${params}`),
    {
      enabled: Boolean(currentCase && name && version),
      retry: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
  return getQuery;
};

type UploadPdfRequest = {
  caseNumber: number;
  documentAsBase64: string;
  name: string;
  version: number;
};

const useUploadPdf = () => {
  const mutation = useMutation((request: UploadPdfRequest) =>
    adFetch('/document', {
      method: 'POST',
      body: JSON.stringify(request),
    }),
  );
  return mutation;
};

const usePdfMaker = (type: DocumentType | undefined, version?: IVersion) => {
  // const [dataUrl, setDataUrl] = useState<string>();
  const { currentUser } = useCurrentUser();
  const { currentCase } = useCurrentCase();
  const { data: calculation } = useCalculationFromVersion(version);
  const { mutate: uploadPdf } = useUploadPdf();
  const { setDocumentUrlData } = usePdfContext();
  const {
    data: document,
    isError: documentNotFound,
    isFetched: documentIsFetched,
  } = useGetDocument(type && documentNames[type], version);
  const { price } = usePrice(calculation, true);

  const args = useMemo(
    () => ({
      currentUser,
      currentCase,
      calculation,
      version,
      price,
      type,
    }),
    [currentUser, currentCase, calculation, version, price, type],
  );

  const getDataUrl = useCallback(
    (gdpr?: boolean) => {
      if (!type || !documentTemplates[type] || !version || !calculation || !price) return null;
      const definition = documentTemplates[type](gdpr)(args);
      if (!definition) return null;
      const pdfDoc = pdfMake.createPdf(definition);
      return new Promise<string>((resolve, reject) => {
        try {
          pdfDoc.getDataUrl((url: string) => {
            resolve(url);
          });
        } catch (e) {
          reject(new Error('Could not create pdf'));
        }
      });
    },
    [version, type, args, calculation, price],
  );

  const upload = useCallback(
    (documentUrl: string, gdpr = false) => {
      if (type && version && currentCase) {
        uploadPdf(
          {
            name: `${documentNames[type]}${gdpr ? '_gdpr' : ''}`,
            documentAsBase64: documentUrl,
            version: version.version,
            caseNumber: currentCase.caseNumber,
          },
          {
            onSuccess: () => {
              // console.log(`${documentNames[type]} document uploaded`);
            },
          },
        );
      }
    },
    [type, version, currentCase, uploadPdf],
  );

  useEffect(() => {
    if (documentIsFetched && price && calculation) {
      if (documentNotFound) {
        getDataUrl()?.then((url) => {
          setDocumentUrlData(url);
          upload(url);
        });
        getDataUrl(true)?.then((gdpr_url) => {
          upload(gdpr_url, true);
        });
      } else {
        setDocumentUrlData(document);
      }
    }
  }, [
    documentNotFound,
    document,
    getDataUrl,
    price,
    calculation,
    documentIsFetched,
    setDocumentUrlData,
    upload,
  ]);

  return { getDataUrl };
};

export default usePdfMaker;
