import { useMemo, useState } from 'react';
import { IVersion } from 'models/calculationModel';
import Table from 'ui-library/Table';
import { formatDate } from 'helpers';
import Checkbox from 'ui-library/Checkbox';
import { useCurrentCase } from 'api/case';
import { useGlobalStateValue } from 'stateContext';
import StatusCell from './StatusCell';
import CommentField from './CommentField';

type Props = {
  versions: IVersion[];
  actionButtons?: any;
};

const VersionsTable = ({ versions, actionButtons }: Props) => {
  const [showOnlyVersionsWithStatusChanges, setShowOnlyVersionsWithStatusChanges] = useState(true);
  const { currentCase } = useCurrentCase();
  const { currentCalculation } = useGlobalStateValue();
  const { caseNumber } = currentCase || {};

  const columns = useMemo(
    () => [
      {
        Header: 'Versionsnr.',
        id: 'versionnr',
        Cell: ({ row }: any) => {
          return (
            <span>
              {caseNumber}-{row.original.version}
            </span>
          );
        },
      },
      {
        Header: 'Kommentar',
        id: 'comment',
        accessor: 'comment',
        Cell: ({ row }: any) => {
          return <CommentField version={row.original as IVersion} />;
        },
      },
      {
        Header: 'Datum',
        id: 'date',
        Cell: ({ row }: any) => {
          return <span>{formatDate(row.original.dateSaved)}</span>;
        },
      },
      {
        Header: 'Status',
        id: 'status',
        Cell: ({ row }: any) => <StatusCell version={row.original as IVersion} />,
      },
      {
        Header: '',
        id: 'buttons',
        accessor: '',
        Cell: ({ row }: any) =>
          actionButtons ? <>{actionButtons({ version: row.original as IVersion })}</> : null,
      },
    ],
    [caseNumber, actionButtons],
  ) as any;

  if (!currentCase) return null;

  const filterWithChanges = (version: IVersion) => {
    if (!showOnlyVersionsWithStatusChanges) return true;
    return (
      currentCase.contractsSent.includes(version.version) ||
      currentCase.quotesSent.includes(version.version) ||
      currentCase.constructionOrdersSent.includes(version.version) ||
      currentCase.contractVersion === version.version ||
      currentCase.constructionOrderSigned === version.version ||
      currentCalculation?.currentHouse.version === version.version
    );
  };

  const filteredVersions = showOnlyVersionsWithStatusChanges ? versions.filter(filterWithChanges) : versions;
  return (
    <div>
      <Checkbox
        label="Visa bara versioner med statusförändringar"
        checked={showOnlyVersionsWithStatusChanges}
        onChange={(e: any) => setShowOnlyVersionsWithStatusChanges(e.target.checked)}
      />
      <Table columns={columns} data={filteredVersions} />
    </div>
  );
};

export default VersionsTable;
