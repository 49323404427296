// import styled from 'styled-components';
// import { VscWarning } from 'react-icons/vsc';

import WarningBox from 'ui-library/WarningBox';

// const StyledAlert = styled.div`
//   background-color: ${({ theme }) => theme.colors.messages.warning};
//   border-left: 10px solid ${({ theme }) => theme.colors.messages.warningDark};
//   color: #fff;
//   padding: 1rem;
//   margin-top: 1rem;
//   svg {
//     margin-right: 0.5rem;
//   }
// `;
const UnsavedChangesAlert = () => {
  return (
    <WarningBox>
      <b>OBS!</b> Du har osparade ändringar och kan därför inte generera vissa dokument
    </WarningBox>
  );
};

export default UnsavedChangesAlert;
