import styled from 'styled-components';
import { VscWarning } from 'react-icons/vsc';

export const StyledWarningBox = styled.div`
  background-color: ${({ theme }) => theme.colors.messages.warning};
  border-left: 10px solid ${({ theme }) => theme.colors.messages.warningDark};
  color: #fff;
  padding: 1rem;
  margin-top: 1rem;
  svg {
    margin-right: 0.5rem;
  }
`;
const WarningBox = ({ children }: any) => {
  return (
    <StyledWarningBox>
      <VscWarning color="#e27300" />

      {children}
    </StyledWarningBox>
  );
};
export default WarningBox;
