import { useState } from 'react';
import styled from 'styled-components';
import { IAdminProductGroup } from 'models/adminModels';
import { FiMenu } from 'react-icons/fi';
import { Dropdown } from 'react-bootstrap';
import EditProductGroup from 'Admin/Modals/EditProductGroup';
import PreviewCalculatorProduct from 'Admin/Modals/PreviewCalculatorProduct';
import Checkbox from 'ui-library/Checkbox';
import Loader from 'ui-library/Loader';
import { ProductWizard } from 'Admin/Components/ProductWizard';
import { EditProduct } from 'Admin/Modals';
import SortProductsModal from 'Admin/Modals/SortProducts';
import { getTemplate } from 'templates';
import { useQueryClient } from 'react-query';
import { FaExclamationCircle } from 'react-icons/fa';
import ProductTable from './ProductTable';
import { useProductGroupDependencies } from './useHasDependencies';

const GroupHeader = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    svg {
      margin-right: 0.25rem;
    }
  }
  button {
    background: ${({ theme }) => theme.colors.primary};
    border: none;
    &:hover,
    &:active,
    &:focus {
      background: ${({ theme }) => theme.colors.primaryFocus} !important;
      box-shadow: none !important;
    }
  }
  & > span {
    display: flex;
    align-items: center;
  }
  .actions {
    label {
      margin-bottom: 0;
      margin-right: 0.5rem;
    }
  }
`;
const AdminProductGroupWrapper = styled.div`
  padding: 5px 20px;
`;
const SubProductsWrapper = styled.div`
  margin-left: 20px;
  border-left: 2px solid ${({ theme }) => theme.colors.primary};
`;

type Props = {
  adminProductGroup: IAdminProductGroup;
};
const AdminProductGroup = ({ adminProductGroup }: Props) => {
  const [showCancelledProducts, setShowCancelledProducts] = useState(false);
  const [showWizard, setShowWizard] = useState(false);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [showEditProductGroup, setShowEditProductGroup] = useState<number | undefined>(undefined);
  const [showPreview, setShowPreview] = useState(false);
  const [showSortProducts, setShowSortProducts] = useState(false);
  const queryClient = useQueryClient();
  const { name, products, adminSubProductGroups } = adminProductGroup;

  const { hasDependencies } = useProductGroupDependencies(adminProductGroup);

  const fetchingProductGroups = queryClient.isFetching(['admin', 'productGroups']);

  return (
    <AdminProductGroupWrapper>
      <GroupHeader>
        <span>
          {fetchingProductGroups > 0 && <Loader size="small" />}
          <h5>
            {hasDependencies && <FaExclamationCircle title="Har beroenden" />}
            {name}
          </h5>
        </span>
        <span className="actions">
          <Checkbox
            checked={showCancelledProducts}
            label="Visa produkter som utgått"
            onChange={(e) => setShowCancelledProducts(e.target.checked)}
          />
          <Dropdown>
            <Dropdown.Toggle>
              <FiMenu />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setShowEditProductGroup(adminProductGroup.productGroupNumber)}>
                Editera
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowPreview(true)}>Förhandsgranska i kalkylator</Dropdown.Item>
              <Dropdown.Item onClick={() => setShowWizard(true)}>Lägg till underprodukkategori</Dropdown.Item>
              <Dropdown.Item onClick={() => setShowAddProduct(true)}>Lägg till Produkt</Dropdown.Item>
              <Dropdown.Item onClick={() => setShowSortProducts(true)}>Sortera produkter</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </span>
      </GroupHeader>
      <ProductTable
        products={products.filter((prod) => (showCancelledProducts ? true : !prod.isCanceled))}
        productGroup={adminProductGroup}
      />
      {adminSubProductGroups.length > 0 && (
        <SubProductsWrapper>
          {adminSubProductGroups.map((subProduct) => (
            <AdminProductGroup key={subProduct.id} adminProductGroup={subProduct} />
          ))}
        </SubProductsWrapper>
      )}
      <EditProductGroup
        show={showEditProductGroup === adminProductGroup.productGroupNumber}
        onClose={() => setShowEditProductGroup(undefined)}
        adminProductGroup={adminProductGroup}
      />
      <PreviewCalculatorProduct
        show={showPreview}
        onClose={() => setShowPreview(false)}
        adminProductGroup={adminProductGroup}
      />
      <ProductWizard
        show={showWizard}
        onClose={() => setShowWizard(false)}
        productGroup={adminProductGroup}
      />
      <EditProduct
        show={showAddProduct}
        onClose={() => setShowAddProduct(false)}
        product={getTemplate('productTemplate')}
        adminProductGroup={adminProductGroup}
        isNew
      />
      <SortProductsModal
        show={showSortProducts}
        onClose={() => setShowSortProducts(false)}
        productGroup={adminProductGroup}
      />
    </AdminProductGroupWrapper>
  );
};

export default AdminProductGroup;
