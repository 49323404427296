import { useEffect } from 'react';
import { adFetch, authContext } from 'adalConfig';

import { CurrentUser } from 'models/models';

import { SET_CURRENT_USER, useGlobalStateValue } from 'stateContext';

let isFetching = false;
export const useCurrentUser = (): { currentUser: CurrentUser | undefined } => {
  const {
    state: { currentUser },
    dispatch,
  } = useGlobalStateValue();
  useEffect(() => {
    if (!currentUser && !isFetching) {
      isFetching = true;
      authContext.getUser(async (_err, user) => {
        const roles = (await adFetch('/user/roles')) as number[];

        const currUser = {
          userName: user?.profile.name,
          userId: user?.profile.oid,
          roles,
          isAdmin: roles ? Math.min(...roles) < 2 : false,
          isSemiAdmin: roles ? roles.includes(3) : false,
        };

        dispatch({ type: SET_CURRENT_USER, payload: currUser });
        isFetching = false;
      });
    }
  }, [currentUser, dispatch]);
  return { currentUser };
};
