import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { adFetch } from 'adalConfig';

import { CaseStatuses, ICase } from 'models/models';
import { useCurrentUser } from 'hooks/useCurrentUser';
import toast from 'react-hot-toast';

const useCurrentCase = () => {
  const { caseNumber } = useParams<{ caseNumber?: string }>();

  const {
    data: currentCase,
    isFetching,
    isLoading,
  } = useQuery<ICase>(['case', caseNumber], () => adFetch<ICase>(`/case/${caseNumber}`), {
    staleTime: Infinity,
    enabled: caseNumber !== 'undefined' && Boolean(caseNumber),
  });
  return { currentCase, caseNumber, isFetching, isLoading };
};

const useLatestCases = () => {
  const { currentUser } = useCurrentUser();
  const result = useQuery(
    ['latestCases'],
    () => adFetch<ICase[]>(`/case?onlyCurrentUser=${!(currentUser?.isAdmin || currentUser?.isSemiAdmin)}`),
    {
      enabled: Boolean(currentUser),
      staleTime: Infinity,
    },
  );
  return result;
};

const useUpdateCase = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (caseToSave: ICase) => adFetch('/case', { method: 'PUT', body: JSON.stringify(caseToSave) }),
    {
      onSuccess: () => {
        toast.success('Ärende sparat');
        queryClient.invalidateQueries(['case']);
        queryClient.invalidateQueries(['latestCases']);
      },
    },
  );
  return mutation;
};

type CaseStatusRequest = {
  status: CaseStatuses;
  version: number;
};
const useSetCaseStatus = () => {
  const { currentCase } = useCurrentCase();
  const mutation = useMutation(({ status, version }: CaseStatusRequest) =>
    adFetch(`/case/status?caseId=${currentCase?.id}&status=${status}&version=${version}`, { method: 'POST' }),
  );
  return mutation;
};

const useSetBNumber = () => {
  const { currentCase } = useCurrentCase();
  const mutation = useMutation((contractNumber: string) =>
    adFetch(`/case/contractnumber?caseId=${currentCase?.id}&contractNumber=${contractNumber}`, {
      method: 'POST',
    }),
  );
  return mutation;
};

export { useCurrentCase, useLatestCases, useUpdateCase, useSetBNumber, useSetCaseStatus };
