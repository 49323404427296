import LatestCases from 'components/LatestCases';
import CaseWrapper from 'layout/CaseWrapper';

const Home = () => {
  return (
    <CaseWrapper>
      <LatestCases />
    </CaseWrapper>
  );
};

export default Home;
