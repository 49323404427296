/* eslint-disable import/no-cycle */
import React from 'react';
import styled from 'styled-components';
import { usePrice } from 'api/price';

import { IProduct, ISubProduct } from 'models/calculationModel';
import DynamicProductList from 'components/ProductComponents/DynamicProductList';
import { useGlobalStateValue } from 'stateContext';
import CalculationInput from 'components/CalculationInput';
import { useShouldRenderProduct } from 'hooks/useShouldRenderProduct';
import { FloorName } from 'models/models';
import Arrow from 'components/ConstructionOrderValidation/Arrow';
import ProductSelect from './ProductSelect';
import { TitleComponent } from './TitleComponent';
import { showControls } from './showControls';
import { useLayoutConfig } from './useLayoutConfig';

type InputsWrapperProps = {
  fullSize?: boolean;
};
const InputsWrapper = styled.div<InputsWrapperProps>`
  display: flex;
  & > div {
    width: ${({ fullSize }) => (fullSize ? '100%' : '50%')};
  }
`;

const ProductWrapper = styled.div<InputsWrapperProps>`
  position: relative;
  padding: 0 0.5rem;
  ${({ fullSize }) =>
    fullSize &&
    `
    padding: 0;
  `}
`;

type Props = {
  floor?: FloorName;
  disabled?: boolean;
  parentSubProduct?: ISubProduct;
  path: string;
  inProduct?: IProduct;
  isPreview?: boolean;
};

const ProductComponent = ({ floor, disabled, path, parentSubProduct, inProduct, isPreview }: Props) => {
  const { getValue } = useGlobalStateValue();
  const { price } = usePrice();
  const getLayoutSettingsForProduct = useLayoutConfig();

  const product = inProduct || getValue<IProduct>(path);

  const shouldRenderProduct = useShouldRenderProduct(product, isPreview);

  if (!product || !shouldRenderProduct) {
    return null;
  }
  const layoutSettings = getLayoutSettingsForProduct(product);

  const needsDefinition = Boolean(
    floor &&
      price?.deliveryDeclarationValidation.validationItemDictionary[floor]?.messages.some(
        (mess) => mess.productGroupNumber === product.productData.productGroupData.productGroupNumber,
      ),
  );

  const { showInput, showSelect } = showControls(product, parentSubProduct);
  const showTitleComponent = showSelect || showInput || layoutSettings?.titleComponentType;

  return (
    <ProductWrapper fullSize={layoutSettings?.fullSizeInput}>
      <Arrow show={needsDefinition} text="Definiera" />
      <div className="header-and-inputs">
        <>
          {showTitleComponent && (
            <TitleComponent
              product={product}
              isToplevelTitle={!parentSubProduct}
              type={layoutSettings?.titleComponentType}
            />
          )}
          {!product.productData.rules.isContainer && (
            <InputsWrapper fullSize={layoutSettings?.fullSizeInput}>
              {showSelect && <ProductSelect path={path} disabled={disabled} inProduct={inProduct} />}
              {showInput && (
                <CalculationInput
                  path={path}
                  disabled={disabled}
                  label={showSelect ? 'Antal' : ''}
                  inProduct={inProduct}
                />
              )}
            </InputsWrapper>
          )}
        </>
      </div>
      {product.productData.rules.includeSubProducts && (
        <div className="sub-products">
          {product.subProducts.map((subProduct, idx) => {
            if (subProduct.rules.hideInConfigurator) return null;

            return (
              <React.Fragment key={`${path}-${subProduct.productNumber}-${subProduct.productGroup}`}>
                <>
                  {subProduct.rules.allowMultipleRows ? (
                    <>
                      <DynamicProductList
                        path={`${path}.subProducts[${idx}]`}
                        disabled={disabled}
                        subProduct={subProduct}
                        isPreview={isPreview}
                        floor={floor}
                      />
                    </>
                  ) : (
                    // ELSE
                    <>
                      <ProductComponent
                        parentSubProduct={subProduct}
                        disabled={disabled}
                        path={`${path}.subProducts[${idx}].products.[0]`}
                        inProduct={product.subProducts?.[idx]?.products?.[0]}
                        isPreview={isPreview}
                        floor={floor}
                      />
                    </>
                  )}
                </>
              </React.Fragment>
            );
          })}
        </div>
      )}
    </ProductWrapper>
  );
};

export default ProductComponent;
