import React from 'react';
import styled, { css } from 'styled-components';
import Box from '../Box';

type MessageWrapperProps = {
  variant: MessageVariant;
};
const MessageWrapper = styled.div<MessageWrapperProps>`
  border-left: 10px solid;
  ${({ variant, theme }) => {
    if (variant === 'warning' || variant === 'error') {
      return css`
        border-color: ${theme.colors.messages.warningDark};
        background-color: ${theme.colors.messages.warning};
      `;
    }
    if (variant === 'success') {
      return css`
        border-color: ${theme.colors.messages.successDark};
        background-color: ${theme.colors.messages.success};
      `;
    }
    return '';
  }};
`;

type MessageVariant = 'success' | 'warning' | 'error';
type Props = {
  variant?: MessageVariant;
  text?: string;
};
const Message: React.FC<Props> = ({ variant = 'warning', text, children }) => {
  return (
    <MessageWrapper variant={variant}>
      <Box>
        {text && text}
        {children && children}
      </Box>
    </MessageWrapper>
  );
};

export default Message;
