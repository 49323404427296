import Box from 'ui-library/Box';
import { contractorTypes } from '../../../constants';
import { ContractorBox } from './ContractorBox';

const CaseContractors = () => {
  return (
    <Box bgColor="#fff">
      {contractorTypes.map((contractorType) => {
        return <ContractorBox key={contractorType.id} contractorType={contractorType} />;
      })}
    </Box>
  );
};

export default CaseContractors;
