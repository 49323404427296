import { useState } from 'react';
import { IAdminProductGroup } from 'models/adminModels';
import LabeledCollapse from 'ui-library/LabeledCollapse';
import AdminProductGroup from 'Admin/AdminProductGroup';
import Box from 'ui-library/Box';
import Loader from 'ui-library/Loader';

type Props = {
  adminProductGroups: IAdminProductGroup[] | undefined;
  isFetching?: boolean;
};
const ProductGroupList = ({ adminProductGroups, isFetching }: Props) => {
  const [openPanel, setOpenPanel] = useState(-1);
  const togglePanel = (idx: number) => {
    return () => {
      if (openPanel === idx) {
        setOpenPanel(-1);
      } else {
        setOpenPanel(idx);
      }
    };
  };

  if (!adminProductGroups) return <Loader />;

  return (
    <Box>
      {adminProductGroups.map((adminProductGroup, idx) => {
        const { name, id } = adminProductGroup;
        return (
          <div key={id}>
            <LabeledCollapse
              label={
                <h5>
                  {isFetching && <Loader size="small" />}
                  {name}
                </h5>
              }
              open={openPanel === idx}
              onLabelClick={togglePanel(idx)}
            >
              {openPanel === idx && <AdminProductGroup adminProductGroup={adminProductGroup} />}
            </LabeledCollapse>
          </div>
        );
      })}
    </Box>
  );
};

export default ProductGroupList;
