import { useDeleteContractor, useUpdateContractor } from 'api/contractors';
import { IContractor } from 'models/calculationModel';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FaRegTrashAlt } from 'react-icons/fa';
import { defaultTheme } from 'theme';
import Button, { FormSubmitWrapper } from 'ui-library/Button';
import InputField from 'ui-library/InputField';
import Label from 'ui-library/Label';
import ControlledSelect from 'ui-library/Select/ControlledSelect';
import { contractorTypes, counties } from '../../../constants';
import { ContractorRating } from './ContractorRating';

const contractorTemplate: IContractor = {
  id: null,
  orgNumber: '',
  name: '',
  address: '',
  postalAddress: '',
  phone: '',
  phoneCell: '',
  email: '',
  contactPerson: '',
  contractorTypeId: null,
  certifiering: '',
  counties: null,
  rating: null,
};

type Props = {
  contractor?: IContractor;
  isAdding?: boolean;
};

const ContractorDetailsForm = ({ contractor, isAdding }: Props) => {
  const { register, handleSubmit, formState, control } = useForm<IContractor>({
    defaultValues: isAdding ? contractorTemplate : contractor,
  });
  const { mutate: updateContractor } = useUpdateContractor(isAdding);
  const { mutate: deleteContractor } = useDeleteContractor();
  const { isDirty } = formState;
  const onSubmit = (data: IContractor) => {
    updateContractor(data, {
      onSuccess: () => {
        toast.success('Entreprenör sparad');
      },
    });
  };
  const onDelete = () => {
    deleteContractor(contractor?.id || null, {
      onSuccess: () => {
        toast.success('Entreprenör borttagen');
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputField label="Namn" {...register('name')} />
      <InputField label="Adress" {...register('address')} />
      <InputField label="Postadress" {...register('postalAddress')} />
      <InputField label="Epost" {...register('email')} />
      <InputField label="Telefon" {...register('phone')} />
      <InputField label="Telefon (Mobil)" {...register('phoneCell')} />
      <InputField label="Kontaktperson" {...register('contactPerson')} />
      <InputField label="Org. nr." {...register('orgNumber')} />
      <Controller
        name="rating"
        render={({ field }) => {
          return (
            <Label>
              Betyg
              <ContractorRating rating={contractor?.rating || 0} editMode {...field} />
            </Label>
          );
        }}
        control={control}
      />

      <ControlledSelect
        options={contractorTypes.map(({ id: value, type: label }) => ({ value, label }))}
        label="Entreprenörstyp"
        control={control}
        name="contractorTypeId"
      />
      <ControlledSelect
        options={Object.entries(counties).map(([value, label]) => ({
          value,
          label,
        }))}
        label="Verksam i"
        control={control}
        name="counties"
        isMulti
      />
      <FormSubmitWrapper>
        {!isAdding && (
          <Button type="button" variant="secondary" disabled onClick={onDelete}>
            Ta bort entreprenör <FaRegTrashAlt color={defaultTheme.colors.primary} />
          </Button>
        )}
        <Button disabled={!isDirty}>Spara</Button>
      </FormSubmitWrapper>
    </form>
  );
};

export default ContractorDetailsForm;
