import { adFetch } from 'adalConfig';
// import Form from 'react-jsonschema-form';
import Box from 'ui-library/Box';
import Button from 'ui-library/Button';

// import testschema from './testschema.json';

const Config = () => {
  // console.log({ testschema });
  const config = {
    data: {
      testdata: 'test',
    },
    name: 'testconfig',
  };
  const save = () => {
    adFetch('/config/', {
      method: 'post',
      body: JSON.stringify(config),
    });
  };
  return (
    <div>
      Konfiguration
      <Box>
        {/* <Form
          schema={testschema as any}
          onSubmit={() => {
            // console.log({ d });
          }}
        /> */}
      </Box>
      <Button onClick={save}>Spara</Button>
    </div>
  );
};

export default Config;
