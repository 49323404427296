import { Link, useParams } from 'react-router-dom';
import DocumentViewer from 'components/DocumentViewer';
import VersionsTable from 'components/VersionsTable';
import { useCalculationVersions } from 'hooks/useCalculationVersions';
import { useState } from 'react';
import { useCurrentCase } from 'api/case';
import ToCalculationButton from '../ToCalculationButton';
import ActionButtons from './ActionButtons';
import ProductionCostEditor from './ProductionCostEditor';

const QuotePage = () => {
  const { caseNumber } = useParams<{ caseNumber: string }>();
  const { calculationVersions } = useCalculationVersions();
  const { currentCase } = useCurrentCase();
  const [showProductionCostEditor, setShowProductionCostEditor] = useState(false);

  if (!calculationVersions) return <>Hämtar versioner...</>;

  return (
    <div>
      <h2>Offert</h2>
      <DocumentViewer />
      <ToCalculationButton>
        <Link to={`/case/${caseNumber}/huskalkyl/hustyp`}>Till offertdefinition</Link>
      </ToCalculationButton>
      <VersionsTable
        versions={calculationVersions.versions.filter((version) => {
          if (currentCase && currentCase.contractVersion > 0) {
            return version.version <= currentCase.contractVersion;
          }
          return true;
        })}
        actionButtons={({ version }: any) => (
          <ActionButtons version={version} setShowProductionCostEditor={setShowProductionCostEditor} />
        )}
      />
      <ProductionCostEditor
        show={showProductionCostEditor}
        onClose={() => setShowProductionCostEditor(false)}
      />
    </div>
  );
};

export default QuotePage;
