import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

type LoaderSize = 'small' | 'medium' | 'large';

const StyledSpinner = styled<any>(Spinner)`
  border-width: 3px;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  font-size: 1rem;
`;
const SpinnerWrapper = styled.div<any>`
  display: inline-block;
  font-size: 1rem;
  ${({ hasLabel }) =>
    hasLabel &&
    `
    padding: 1rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;
type Props = {
  size?: LoaderSize;
  label?: string;
};

const sizes: Record<LoaderSize, string> = {
  small: '1rem',
  medium: '2rem',
  large: '3rem',
};

const Loader = ({ size = 'medium', label }: Props) => {
  const spinnerSize = sizes[size];
  return (
    <SpinnerWrapper hasLabel={Boolean(label)}>
      <StyledSpinner size={spinnerSize} animation="border" />
      {label && <span>{label}</span>}
    </SpinnerWrapper>
  );
};

export default Loader;
