import { formDataTableCell } from 'hooks/usePdfMaker/documentsCommon';
import { IHouseTemplate } from 'models/calculationModel';
import { ICustomer } from 'models/models';

export const documentCheckBox = (checked = true) => {
  const checkbox = {
    canvas: [
      {
        type: 'rect',
        x: 2,
        y: 2,
        w: 8,
        h: 8,
        r: 1,
        lineColor: '#000000',
      },
    ],
  };

  return [checkbox, checked ? { text: '√', relativePosition: { x: 4, y: -9 } } : ''];
};

export const signingLines = (customers: ICustomer[]) => {
  const getTexts = (i: number) => {
    const texts = [
      {
        width: 260,
        text: [
          { text: '\n' },
          { text: 'Ort och datum för Köparens underskrift\n' },
          { text: ' \n\n\n', style: { fontSize: 14, bold: true } },
          { text: 'Köparens underskrift' },
        ],
      },
    ];
    if (i < 1) {
      texts.push({
        width: 260,
        text: [
          { text: '\n' },
          { text: 'Ort och datum för Säljarens godkännande\n' },
          { text: '\n\n\n\n\n' },
          // {text: 'Bra Hus från Hedlunds AB\n\n\n', style: {fontSize: 14, bold: true}},
          { text: 'Behörig företrädare för Säljaren' },
        ],
      });
    }
    return texts;
  };
  const getDots = (i: number) => {
    const dots = [
      // Ort & datum, köpare
      {
        type: 'line',
        x1: 0,
        y1: -70,
        x2: 230,
        y2: -70,
        dash: { length: 2 },
        lineWidth: 1,
      },

      // Underskrift säljare
      {
        type: 'line',
        x1: 0,
        y1: -15,
        x2: 230,
        y2: -15,
        dash: { length: 2 },
        lineWidth: 1,
      },
    ];
    const sellerDots = [
      // Underskrift Bra Hus
      {
        type: 'line',
        x1: 260,
        y1: -15,
        x2: 480,
        y2: -15,
        dash: { length: 2 },
        lineWidth: 1,
      },
      // Ort & datum, Bra Hus
      {
        type: 'line',
        x1: 260,
        y1: -70,
        x2: 480,
        y2: -70,
        dash: { length: 2 },
        lineWidth: 1,
      },
    ];
    if (i < 1) {
      dots.push(...sellerDots);
    }
    return dots;
  };
  const lines = customers.map((_c, i) => [
    {
      margin: [0, 30, 0, 0],
      columns: getTexts(i),
    },
    {
      // Streck och linjer
      canvas: getDots(i),
    },
  ]);
  return lines;
};

export const makeCustomersRows = (customers: ICustomer[], gdpr?: boolean) => {
  const rows: any = [];

  if (gdpr) {
    customers.forEach((_customer, i) => {
      const row = [
        formDataTableCell(`Köpare ${i + 1}`, '[...]', 3),
        {},
        {},
        formDataTableCell('Personnr./Org. nr.', '[...]', 2),
        {},
        formDataTableCell('Tel. mobil', '[...]', 2),
        {},
      ];
      rows.push(row);
    });

    return rows;
  }
  customers.forEach((customer, i) => {
    const row = [
      formDataTableCell(`Köpare ${i + 1}`, `${customer.firstName} ${customer.lastName}`, 3),
      {},
      {},
      formDataTableCell('Personnr./Org. nr', customer.personalIdentityNumber, 2),
      {},
      formDataTableCell('Tel. mobil', customer.phoneCell, 2),
      {},
    ];
    rows.push(row);
  });

  return rows;
};

export const paymentPlanUnderline = () => {
  return {
    canvas: [
      {
        type: 'line',
        x1: 0,
        y1: 0,
        x2: 255,
        y2: 0,
        lineWidth: 0.5,
      },
    ],
  };
};

export const getTemplateType = (templateCalculationId: number, houseTemplates: IHouseTemplate[]) => {
  const templateCalculation = houseTemplates.filter(
    (template) => template.calculationId === templateCalculationId,
  );
  if (templateCalculation.length > 0) {
    return templateCalculation[0].name;
  }
  return 'Specialhus';
};
