import { formatDate } from 'helpers';
import { pageBreak } from 'hooks/usePdfMaker/documentsCommon';
import { IVersion } from 'models/calculationModel';
import { ICase } from 'models/models';
import { IPriceResponse } from 'models/priceModel';
import {
  createDataRow,
  createHeader,
  createParagraph,
  getCustomers,
  renderGroupRows,
  titleText,
} from './helpers';

type Args = {
  version: IVersion;
  currentCase: ICase;
  price: IPriceResponse;
  gdpr?: boolean;
};
const getProductionCalculationCostDocument =
  (_gdpr: boolean) =>
  ({ currentCase, version, price, gdpr = false }: Args) => {
    const doc = {
      info: 'Produktionskostnadskalkyl',
      content: [
        { text: `Produktionskostnadskalkyl - ${currentCase.caseNumber}-${version.version}` },
        {
          layout: 'noBorders',
          style: 'tableStyle',
          margin: [0, 10, 0, 10],
          table: {
            widths: ['auto', 'auto', 'auto', 'auto'],
            body: [
              [titleText('Kalkylen utförd av'), currentCase.userName, '', ''],
              [
                titleText('Kalkylnr.'),
                `${currentCase.caseNumber} - ${version.version}`,
                titleText('Datum'),
                formatDate(version.dateSaved, 'yyyy-MM-dd'),
              ],
              [
                titleText('Fastighet'),
                gdpr ? '[...]' : currentCase.propertyDesignation,
                titleText('Kommun'),
                gdpr ? '[...]' : currentCase.propertyMunicipal,
              ],
              ...getCustomers(currentCase, gdpr),
            ],
          },
        },
        {
          text: 'Kalkylen utgår från entreprenadformen delad entreprenad. Bra Hus levererar materialsatsen. Kund ingår entreprenadavtal med respektive entreprenör.',
          style: 'textStyle',
        },
        {
          style: 'tableStyle',
          table: {
            widths: [
              20,
              20,
              20, // title
              39, // value1
              39, // value2
              39, // value3
              39, // value4
              '*',
              '*', // comment
            ],
            body: [
              createDataRow({
                title: ' ',
                columnValues: [
                  'BraHus åtaganden',
                  'Annat skriftligt anbud/avtal',
                  'Köparens uppgift',
                  'Agentens  uppskattning',
                ],
                comment: 'Övriga upplysningar',
                isSumRow: true,
              }),
              ...renderGroupRows(currentCase.productionCostCalculation.groups, price),
            ],
          },
        },
        { text: 'Övriga upplysningar', style: { bold: true }, margin: [0, 5, 0, 5] },
        { text: currentCase.productionCostCalculation.comment, style: { fontSize: 8 } },
        pageBreak(),
        createHeader('Viktig information om din produktionskostnadskalkyl'),
        createParagraph(
          'Du har fått en kostnadsuppskattning som omfattar produktionskostnad i samband med köp av monteringsfärdigt husmaterial eller småhusentreprenad.',
        ),

        createHeader('Vem bygger och vem ansvarar'),
        createParagraph(
          'Flera olika företag medverkar ofta för att huset ska bli färdigt, husföretag, bygg- el VVS- m.fl. entreprenörer. Ansvarsfördelningen mellan dem som tillsammans ser till att ditt hus blir färdigt varierar från husköp till husköp. Det är viktigt att du känner till hur ansvaret fördelar sig mellan dem i just ditt fall. Olika begrepp förekommer för att beskriva ansvarsfördelning och färdigställandegrad, t.ex. totalentreprenad, nyckelfärdigt, målningsförberett mm. Vad begreppen innebär kan variera från fall till fall. Därför är enbart ett sådant ord inte tillräcklig beskrivning. Du har rätt att få utförlig information av den som upprättar din kalkyl om vad säljaren åtar sig och vad som förväntas av dig själv och av andra som ska medverka i bygget. Även ett totalt åtagande att bygga ett hus har sina gränser. För att undvika missförstånd är det värdefullt att gränserna för varje åtagande blir klarlagt på ett tidigt stadium.',
        ),

        createHeader('En motpart - ett kontrakt'),
        createParagraph(
          'Ibland åtar sig ett företag, oftast ett husföretag, att gentemot konsumenten svara för allt material och allt arbete som behövs för att få ett färdigt hus inklusive byggadministration, myndighetskontakter mm. Då har du bara ett kontrakt och en motpart. Hantverkarna som bygger ditt hus kan vara anställda av husföretaget eller komma från andra företag, som husföretaget i sin tur anlitat. Ett liknande avtal kan också skrivas med en entreprenör, som i sin tur köper materialsatsen från husföretaget. Om inget annat är avtalat utför husföretaget erforderliga ritningar och konstruktioner och ansvarar för att dessa följer gällande lagar och byggregler.',
        ),

        createHeader('Två kontrakt'),
        createParagraph(
          'En annan variant är att husföretaget svarar för materialleveransen och en entreprenör åtar sig att svara för alla arbeten med egen personal eller genom att i sin tur handla upp arbetena hos andra företag. Det är ofta en entreprenör, som husföretaget har regelbunden kontakt med och som därför känner till byggsystemet. Alternativt kontaktar du en byggentreprenör, som dels förmedlar ett köp mellan dig och ett husföretag och dels svarar för uppförandet av huset, i bägge fallen får du skriva två avtal, ett med entreprenören och ett med husföretaget.',
        ),

        createHeader('Flera kontrakt'),
        createParagraph(
          'En tredje variant är att du köper husmaterialet i form av en byggsats av ett husföretag och de olika arbetena (grundläggning, bygg, el mm) av andra företag. Du får då ett kontrakt med husföretaget, ett med grundläggaren, ett med VVS-firman osv. Ofta sköter husförsäljaren om att alla kontrakten blir upprättade, men det är du som är köpare och ska underteckna dem. De olika arbetena och leveranserna ska samordnas så att byggprocessen kan fortgå utan komplikationer. Eftersom bygget blir en arbetsplats ska valje inblandad arbetsgivare ansvara för arbetsmiljöfrågor och någon ska svara för samordningen av dessa frågor. Om du anlitar någon som inte har en firma med F-skattsedel är du i lagens mening arbetsgivare med ansvar för arbetsmiljö, skatteinbetalning osv.',
        ),

        createHeader('"Lösvirkeshus"'),
        createParagraph(
          'Det kan också hända att ingen husbrikant är inblandad i ditt bygge — att du bygger ett sk. lösvirkeshus. Även i det fallet kan ansvaret överlåtas till en eller flera. Ritningar och konstruktioner, materialval, materialleveranser, byggnadsarbeten, installationer, målning osv. samt samordning och kontroll av de olika arbetena kan skötas av ett eller flera olika företag eller delvis av dig själv. Också i detta fall är det viktigt att se till att ansvarsfördelningen klarläggs i skrift genom tydliga och utförliga kontrakt. Oavsett hur ansvaret fördelar sig svarar varje företag för eventuella fel eller förseningar i sin del av det hela, men inte för fel m.m. som orsakats av ett annat företag eller av dig själv, t ex försening på grund av bristande samordning m.m.',
        ),
        pageBreak(),

        createHeader('Krav på konsumentskydd'),
        createParagraph(
          'Enligt lag (1993:320) om byggfelsförsäkring finns krav på att det som huvudregel ska finnas en byggfelsförsäkring tecknad innan ett en — eller tvåbostadshus ska uppföras. Kravet gäller dock inte för de ev. åtgärder som ska utföras av byggherren i egenskap av konsument av huset. 1 samma lag fmns också krav på att det som huvudregel ska finnas ett färdigställandeskydd i form av en försäkring eller en bankgaranti innan uppförande av ny byggnad påböljas.',
        ),

        createHeader('Kalkylen gäller för dagens förhållanden'),
        createParagraph(
          'Kalkylerna utgår från förutsättningar som är kända då man upprättar dem. En del förutsättningar kan ändras både innan bygget startar och medan du bygger huset. När den första kalkylen görs är kanske inte ansvarsfördelningen beslutad i detalj. Produktionskostnadskalkylen ska dock bygga på realistiska antaganden om vad du och olika inblandade parter ska ansvara för. Om du senare beslutar om annan ansvarsfördelning kommer det att påverka kalkylen. Kalkylen förutsätter att arbetena sker efter avtal med ett angivet fast pris. Det går också att träffa avtal utan att göra upp om priset i förväg, s.k. löpande räkning. Om avtal med löpande räkning träffas kan det ändå vara klokt att avtala om vilket timpris som ska gälla. En fördel med fast pris är att du från början med större säkerhet vet vad kostnaden blir.',
        ),

        createHeader('Ändrade förhållanden — ny kalkyl'),
        createParagraph(
          'Du gör kanske tillval och utbyten i förhållande till standardutförandet av huset. Vidare kan statsmakter och kommunala myndigheter besluta om ändrade tekniska krav, moms och avgifter. Allt sådant kan påverka totalkostnaden och därmed din boendekostnad. Om det inträffar något före eller efter avtalet som har betydelse för utfallet av kalkylerna, ska säljaren enligt överenskommelsen ge dig en ny produktionskostnadskalkyl.',
        ),

        createHeader('Produktionskostnadskalkylen'),
        {
          text: 'Denna kalkyl ska ge en uppfattning om hur mycket husbygget kostar totalt. Kostnaderna för allt arbete som behövs för att huset ska bli klart ska ingå i denna. Kalkylen är uppdelad i ett antal delposter, som normalt förekommer vid ett småhusbygge för att säkerställa att ingenting har hoppats över eller glömts bort.',
          style: 'paragraphStyle',
        },
        {
          text: 'i kalkylen finns fyra kolumner för att visa vem som ansvarar för varje delpost:',
        },
        {
          text: '1. säljarens åtagande',
        },
        {
          text: '2. annat skriftligt avtal/anbud',
        },
        {
          text: '3. köparens uppgift',
        },
        {
          text: '4. säljarens uppskattning.',
        },
        {
          text: 'Kolumnen "säljarens uppskattning" ska visa kostnaderna för sådant arbete som säljaren inte åtagit sig att göra och som det inte heller finns annan bindande uppgift om. Dessa poster är därför mindre säkra än posterna i övriga kolumner. Säljaren kan inte bestämma en annan entreprenörs priser och uppskattningarna är därför inte bindande för säljaren. Kalkylen blir mer tillförlitlig allteftersom du får bindande anbud från dem som ska utföra de olika arbetena.',
        },
        createHeader('Delposter'),
        createParagraph(
          'Kalkylen innehåller ett stort antal delposter, som representerar de leveranser och arbeten som behövs för att färdigställa huset. Om ett företag svarar för flera av dessa delposter kanske inte kostnaden för de olika delarna kan redovisas separat, utan de redovisas i en gemensam post. Om t.ex. golvbeläggning ingår i övriga byggnadsarbeten står det "ingår i övriga byggnadsarbeten" på raden för golvbeläggning. Eftersom uppdelningen kan vara olika för olika husleverantörer är det inte meningsfullt att jämföra kalkyler från konkurrerande företag delpost för delpost. Det kan t.ex. hända att markförhållandena på tomten inte är kända i första skedet och att kostnaden för grundläggning därför måste ändras. Kostnaden för grundläggning beräknas i så fall utifrån normal markbärighet. Du kan göra en markundersökning för att gardera dig mot oförutsedda kostnader för t ex sprängning och pålning.',
        ),

        createHeader('Eget arbete och innehav av tomt'),
        createParagraph(
          'Produktionskostnadskalkylen får utesluta de arbetsmoment som ska utföras av konsumenten själv om de kostnaderna inte är kända. Likaså behöver inte kostnad för tomt och lagfartskostnad anges i det fall konsumenten innehar tomt sen tidigare.',
        ),

        createHeader('Kalkylkontroll'),
        createParagraph(
          'När alla tillstånd, lån mm är klara görs en slutlig order till husfabriken att tillverkningen kan bölja. I samband därmed, eller, om ingen sådan slutlig order görs, i god tid före avtalad leveranstidpunkt bör den som tillhandahållit kalkylen medverka till att kalkylen kontrolleras. Kalkylkontrollen kan vara uppställd på samma sätt som produktionskostnadskalkylen, men vid denna tidpunkt bör bindande avtal eller anbud finnas för alla poster i kalkylen eller åtminstone för de större och avgörande delarna. Denna slutliga kalkyl är ett bra hjälpmedel att stämma av ekonomin under byggtiden. Om det skett något genom dina egna beslut eller andra förändringar, som inte stämmer med den uppgjorda kalkylen har du då möjlighet att i tid vidta de åtgärder som behövs, som att välja billigare utföranden e d.',
        ),

        createHeader('Tänk igenom, fråga, jämför'),
        createParagraph(
          'Att bygga ett hus är en mycket stor och långsiktig affär. Planera därför byggprocessen i lugn och ro. Ta god tid på dig. Kontakta gärna några olika företag och utvärdera deras förslag till ekonomiska, tekniska och juridiska lösningar! Har du frågor eller känner dig osäker över något kan du fråga husföretaget eller dess representant, den bank du har kontakt med, konsumentvägledaren i din kommun eller Villaägarnas Riksförbunds fria rådgivning om du är medlem där.',
        ),
      ],
      styles: {
        cellFill: {
          fillColor: '#dddddd',
        },
        sumCellFill: {
          fillColor: '#9d9d9d',
          bold: true,
        },
        tableStyle: {
          fontSize: 8,
        },
        textStyle: {
          fontSize: 8,
        },
        paragraphStyle: {
          fontSize: 11,
        },
      },
    };
    return doc;
  };

export default getProductionCalculationCostDocument;
