import { useCurrentCase } from 'api/case';
import DocumentViewer from 'components/DocumentViewer';
import VersionsTable from 'components/VersionsTable';
import { useCalculationVersions } from 'hooks/useCalculationVersions';
import { Link } from 'react-router-dom';
import Loader from 'ui-library/Loader';
import ToCalculationButton from '../ToCalculationButton';
import ActionButtons from './ActionButtons';

const ContractPage = () => {
  const { calculationVersions } = useCalculationVersions();
  const { currentCase } = useCurrentCase();

  if (!calculationVersions) return <Loader label="Hämtar versioner" />;

  return (
    <div>
      <DocumentViewer />
      <ToCalculationButton>
        <Link to={`/case/${currentCase?.caseNumber}/huskalkyl/hustyp`}>Till tillverkningsorder</Link>
      </ToCalculationButton>
      <VersionsTable
        versions={calculationVersions.versions.filter(
          (version) => currentCase && version.version >= currentCase?.contractVersion,
        )}
        actionButtons={({ version }: any) => <ActionButtons version={version} />}
      />
    </div>
  );
};

export default ContractPage;
