// import React from "react";
import styled from 'styled-components';

const StyledComponentGroupHeader = styled.h4`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem 3.125rem;
  margin-left: -3.125rem;
  margin-top: 0.5rem;
  min-width: 106.8%;
  display: inline-block;
  -webkit-box-shadow: 10px 10px 5px -9px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 10px 10px 5px -9px rgba(0, 0, 0, 0.45);
  box-shadow: 10px 10px 5px -9px rgba(0, 0, 0, 0.45);
  color: white;
  font-size: 1.125rem;
`;

export default StyledComponentGroupHeader;
