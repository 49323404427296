import { Redirect, Route } from 'react-router-dom';
import { IAdminProductGroupDictionary } from 'models/adminModels';
import ProductGroupList from 'Admin/ProductGroupList';
import AdminSubNavigation, { PageItem } from 'Admin/SubNavigation';
import Loader from 'ui-library/Loader';

type Props = {
  productGroups: IAdminProductGroupDictionary | undefined;
  isFetching?: boolean;
};
const ContractorProducts = ({ productGroups, isFetching }: Props) => {
  const pages: PageItem[] = [
    {
      title: 'Bygg',
      path: 'entreprenad/bygg',
      products: 'construction',
    },
    {
      title: 'Grund',
      path: 'entreprenad/grund',
      products: 'foundation',
    },
    {
      title: 'El',
      path: 'entreprenad/el',
      products: 'electricity',
    },
    {
      title: 'Målning (utvändigt)',
      path: 'entreprenad/malning-utvandigt',
      products: 'exteriorPainting',
    },
    {
      title: 'Målning',
      path: 'entreprenad/malning',
      products: 'painting',
    },
    {
      title: 'VS',
      path: 'entreprenad/vs',
      products: 'plumbing',
    },
    {
      title: 'Kakel',
      path: 'entreprenad/kakel',
      products: 'tiling',
    },
  ];

  if (!productGroups) return <Loader label="Hämtar produkter" />;

  return (
    <>
      <AdminSubNavigation pages={pages} />
      <Route path="/admin/entreprenad">
        <Redirect to="/admin/entreprenad/bygg" />
      </Route>
      {isFetching && <Loader />}
      {pages.map(({ path, products }) => {
        if (!products) return null;
        return (
          <Route key={path} path={`/admin/${path}`} exact>
            <ProductGroupList adminProductGroups={productGroups[products]} />
          </Route>
        );
      })}
    </>
  );
};

export default ContractorProducts;
