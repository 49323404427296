import { useCurrentCase } from 'api/case';
import { BasementTypes, FloorTypes, GarageTypes, IProduct } from 'models/calculationModel';
import { CaseTypes } from 'models/models';
import { useGlobalStateValue } from 'stateContext';

export const useShouldRenderProduct = (product?: IProduct, isPreview?: boolean) => {
  const { currentCalculation } = useGlobalStateValue();
  const { currentCase } = useCurrentCase();

  if (!product) return false;

  if (isPreview) return true;

  const { productData } = product;
  const { house } = currentCalculation.currentHouse;
  const { rules } = productData.productGroupData;

  let caseType = CaseTypes.SPECIAL_HOUSE;
  if (currentCase) {
    caseType = currentCase.type;
  }

  if (rules.availableIfBasement && house.parameters.basementType === BasementTypes.BASEMENT) {
    return true;
  }
  if (
    rules.availableIfIntegratedGarage &&
    house.parameters.garageType &&
    house.parameters.garageType.productData.productNumber === GarageTypes.INTEGRATED
  ) {
    return true;
  }
  if (rules.availableIfOneAndAHalfFloors && house.parameters.floorType === FloorTypes.ONE_AND_ONE_HALF) {
    return true;
  }
  if (
    rules.availableIfOneAndThreeQuartersFloors &&
    house.parameters.floorType === FloorTypes.ONE_AND_THREE_QUARTERS
  ) {
    return true;
  }
  if (rules.availableIfOneFloor && house.parameters.floorType === FloorTypes.SINGLE_FLOOR) {
    return true;
  }
  if (
    rules.availableIfSeparateGarage &&
    house.parameters.garageType &&
    house.parameters.garageType.productData.productNumber === GarageTypes.STAND_ALONE
  ) {
    return true;
  }
  if (rules.availableIfSplitLevel && house.parameters.basementType === BasementTypes.SPLIT_LEVEL) {
    return true;
  }
  if (rules.availableIfTwoFloors && house.parameters.floorType === FloorTypes.TWO_FLOORS) {
    return true;
  }
  if (rules.availableIfCatalogueHouse && caseType === CaseTypes.CATALOGUE_HOUSE) {
    return true;
  }
  if (rules.availableIfCustomHouse && caseType === CaseTypes.SPECIAL_HOUSE) {
    return true;
  }
  if (rules.availableIfWildStrawberry && caseType === CaseTypes.WILD_STRAWBERRY) {
    return true;
  }

  return false;
};
