import { IProductionCostCalculationRow } from 'models/models';
import { IPriceResponse } from 'models/priceModel';
import { usePrice } from '../../../api/price';

export const dynamicRows = {
  HOUSE_COST: 300,
  GARAGE_COST: 301,
  CONTRACTOR_FOUNDATION: 303,
  CONTRACTOR_CONSTRUCTION: 304,
  CONTRACTOR_HEATING_VENTILATION: 306,
  CONTRACTOR_ELECTRICAL: 308,
  CONTRACTOR_PAINTING: 309,
  CONTRACTOR_TILING: 310,
  CONTRACTOR_EXTERIOR_PAINTING: 312,
  CONTRACTOR_GENERAL_CONTRACTOR_FEE: 313,
};

export const getPriceFromRow = (rowId: number, price?: IPriceResponse) => {
  if (!price) return 0;
  switch (rowId) {
    case dynamicRows.HOUSE_COST:
      return Math.round(price.currentHousePrice);
    case dynamicRows.GARAGE_COST:
      return Math.round(price.currentGaragePrice);
    case dynamicRows.CONTRACTOR_FOUNDATION:
      return Math.round(price.currentContractorPrices.foundation.totalPrice * 1.25);
    case dynamicRows.CONTRACTOR_CONSTRUCTION:
      return Math.round(price.currentContractorPrices.construction.totalPrice * 1.25);
    case dynamicRows.CONTRACTOR_HEATING_VENTILATION:
      return Math.round(price.currentContractorPrices.plumbing.totalPrice * 1.25);
    case dynamicRows.CONTRACTOR_ELECTRICAL:
      return Math.round(price.currentContractorPrices.electricity.totalPrice * 1.25);
    case dynamicRows.CONTRACTOR_PAINTING:
      return Math.round(price.currentContractorPrices.painting.totalPrice * 1.25);
    case dynamicRows.CONTRACTOR_TILING:
      return Math.round(price.currentContractorPrices.tiling.totalPrice * 1.25);
    case dynamicRows.CONTRACTOR_EXTERIOR_PAINTING:
      return Math.round(price.currentContractorPrices.exteriorPainting.totalPrice * 1.25);
    case dynamicRows.CONTRACTOR_GENERAL_CONTRACTOR_FEE:
      return Math.round(price.currentContractorPrices.generalContractorPrice.totalPrice * 1.25) || 0;

    default:
      return 0;
  }
};

export const rowHasUserValue = (row: IProductionCostCalculationRow) => {
  const columns = Object.keys(row)
    .filter((key) => key.startsWith('column'))
    .map((key) => (row as any)[key]);

  return columns.some((col) => col.value !== null && col.value > 0);
};

export const useProductionCalculationCost = (row: IProductionCostCalculationRow) => {
  const { rowNumber } = row;
  const { price } = usePrice();
  const getProductionCalculationCost = () => {
    return getPriceFromRow(rowNumber, price);
  };
  const isDynamic = Object.values(dynamicRows).includes(rowNumber);
  const hasUserValue = rowHasUserValue(row);
  return { getProductionCalculationCost, isDynamic, hasUserValue };
};
