import Select from 'react-select';

import { useGlobalStateValue } from 'stateContext';
import Label from 'ui-library/Label';
import { useSelectOptions } from 'hooks/useSelectOptions';
import { useSetProductValue } from 'api/price';

type Props = {
  path: string;
  productName: string;
  disabled?: boolean;
  hideLabel?: boolean;
  label?: string;
};

const ParameterSelect = ({ path, disabled, productName, hideLabel, label }: Props) => {
  const { getValue } = useGlobalStateValue();
  const setProductValue = useSetProductValue(0);
  const { getOptionsByName } = useSelectOptions();

  const optionsKey = productName === 'basementType' ? 'basement' : productName;
  const options = getOptionsByName(optionsKey);
  const defaultVal = getValue(path);
  const selectedOption = options.find((opt) => opt.value === defaultVal) || options[0];
  return (
    <>
      {options.length > 1 && (
        <div className="select-input">
          {!hideLabel && <Label>{label}</Label>}
          <Select
            onChange={(o: any) => {
              setProductValue(path, null, o.value);
            }}
            defaultValue={selectedOption || options[0]}
            options={options}
            disabled={disabled}
            isDisabled={disabled}
          />
        </div>
      )}
    </>
  );
};

export default ParameterSelect;
