import { Collapse } from 'react-bootstrap';
import styled from 'styled-components';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useEffect, useState } from 'react';

const CollapseLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
  cursor: pointer;
  h5 {
    margin: 0;
  }
  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

type Props = {
  label: string | JSX.Element;
  open?: boolean;
  children: React.ReactNode;
  onLabelClick?: () => void;
};

const LabeledCollapse = ({ label, open = false, children, onLabelClick }: Props) => {
  const [isOpen, setIsOpen] = useState(open);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  return (
    <div>
      <CollapseLabel onClick={onLabelClick || handleClick}>
        {typeof label === 'string' ? <h5>{label}</h5> : label}
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </CollapseLabel>
      <Collapse in={isOpen}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

export default LabeledCollapse;
