import { Route } from 'react-router-dom';
import { IAdminProductGroupDictionary } from 'models/adminModels';
import AdminProductGroup from 'Admin/AdminProductGroup';
import AdminSubNavigation, { PageItem } from 'Admin/SubNavigation';
import Loader from 'ui-library/Loader';

type Props = {
  productGroups: IAdminProductGroupDictionary | undefined;
  isFetching?: boolean;
};
const CatalogueHouses = ({ productGroups, isFetching }: Props) => {
  const pages: PageItem[] = [
    {
      title: 'Kataloghus',
      path: 'kataloghus',
      products: 'catalogueHouses',
    },
    {
      title: 'Smultronställen',
      path: 'kataloghus/smultronstallen',
      products: 'wildStrawberry',
    },
    {
      title: 'Garage',
      path: 'kataloghus/garage',
      products: 'garage',
    },
  ];

  if (!productGroups) return <Loader size="large" label="Hämtar produkter..." />;

  return (
    <div>
      <AdminSubNavigation pages={pages} />
      {isFetching && <Loader />}
      {pages.map(({ products, path }) => {
        if (!products) return null;
        return (
          <Route key={path} path={`/admin/${path}`} exact>
            <AdminProductGroup adminProductGroup={productGroups[products][0]} />
          </Route>
        );
      })}
    </div>
  );
};

export default CatalogueHouses;
