import styled from 'styled-components';

const StyledArrow = styled.div`
  right: 110%;
  top: 27px;
  position: absolute;
  background: ${({ theme }) => theme.colors.messages.warning};
  border: 4px solid ${({ theme }) => theme.colors.messages.warningDark};
  width: 100px;
  p {
    margin: 5px 0 5px 5px;
    color: #fff;
  }
  // animation: arrow-bump 3s infinite;
  &:before,
  &:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: ${({ theme }) => theme.colors.messages.warning};
    border-width: 30px;
    margin-top: -30px;
  }
  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: ${({ theme }) => theme.colors.messages.warningDark};
    border-width: 36px;
    margin-top: -36px;
  }
`;
type Props = {
  text: string;
  show: boolean;
};
const Arrow = ({ text, show }: Props) => {
  if (!show) return null;
  return (
    <StyledArrow>
      <p>{text}</p>
    </StyledArrow>
  );
};
export default Arrow;
