import { useCurrentCase } from 'api/case';
import { CaseStatuses } from 'models/models';
import { Link, useParams } from 'react-router-dom';
import NavTabs from 'ui-library/NavTabs';

const CaseNavigation = () => {
  const { caseNumber } = useParams<{ caseNumber: string }>();
  const { currentCase } = useCurrentCase();
  if (!currentCase) return null;

  const links = [
    {
      to: `/case/${caseNumber}`,
      label: 'Offert',
    },
    {
      to: `/case/${caseNumber}/avtal`,
      label: 'Avtal',
      className: currentCase.quotesSent.length < 1 ? 'disabled-link' : '',
    },
    {
      to: `/case/${caseNumber}/tillverkningsorder`,
      label: 'Tillverkningsorder',
      className: currentCase?.status < CaseStatuses.SIGNED_CONTRACT ? 'disabled-link' : '',
    },
    {
      to: `/case/${caseNumber}/entreprenorer`,
      label: 'Entreprenörer',
    },
    {
      to: `/case/${caseNumber}/prislista`,
      label: 'Prislista',
    },
  ];
  return (
    <NavTabs>
      {links.map(({ to, label, className }) => {
        return (
          <Link key={to} to={to} className={className}>
            {label}
          </Link>
        );
      })}
    </NavTabs>
  );
};

export default CaseNavigation;
