import React from 'react';
import { FaBan } from 'react-icons/fa';
import styled from 'styled-components';

type BadgeType = 'catalogue' | 'wildStrawBerry' | 'custom';

const colors: Record<BadgeType, string> = {
  catalogue: '#2fc564',
  wildStrawBerry: '#ec485f',
  custom: '#7d50ea',
};

type Props = {
  type: BadgeType;
  children: React.ReactNode;
};

const StandardBadge = styled.span<Props>`
  border-radius: 50%;
  // border: '1px solid';
  color: #fff;
  display: inline-block;
  min-width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  margin-right: 4px;
  background-color: ${({ type }) => colors[type]};
`;

const ExcludeBadgeWrapper = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  ${StandardBadge}, svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const ExcludedBadge = ({ type, children }: Props) => {
  return (
    <ExcludeBadgeWrapper>
      <StandardBadge type={type}>{children}</StandardBadge>
      <FaBan color="#000" fontSize="20px" />
    </ExcludeBadgeWrapper>
  );
};

export default StandardBadge;
