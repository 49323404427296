import { useGetProductGroupsDict, useUpdateSubProductField } from 'Admin/api/product';
import { formatDate } from 'helpers';
import { IAdminProductGroup, IAdminSubProduct } from 'models/adminModels';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Button, { FormSubmitWrapper } from 'ui-library/Button';
import Checkbox from 'ui-library/Checkbox';
import InputField from 'ui-library/InputField';
import ControlledSelect from 'ui-library/Select/ControlledSelect';

type Props = {
  subProductField: IAdminSubProduct;
  subProduct?: IAdminProductGroup;
  parentProductGroup: IAdminProductGroup;
  onClose: () => void;
};
const SubProductForm = ({ subProductField, subProduct, parentProductGroup, onClose }: Props) => {
  const { data: productGroups } = useGetProductGroupsDict({ date: false });
  const { mutate: saveSubProduct } = useUpdateSubProductField();
  // const defaultSubProductField = isNew ? subProductFieldTemplate : subProductField;
  const { handleSubmit, register, watch, control, formState } = useForm<IAdminSubProduct>({
    defaultValues: { ...subProductField, validFrom: formatDate(subProductField?.validFrom || '') },
  });
  const { isDirty } = formState;

  const onSubmit = (data: IAdminSubProduct) => {
    saveSubProduct(data, {
      onSuccess: onClose,
    });
  };

  const watchHasSpecificProduct = watch('hasSpecificProduct');
  const watchUseQuantityFromParent = watch('useQuantityFromParent');

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(onSubmit)();
      }}
    >
      <Container>
        <Row>
          <Col md="6">
            <Checkbox label="Har specifik produkt" {...register('hasSpecificProduct')} />
            <ControlledSelect
              options={subProduct?.products.map((product) => ({
                value: product.productNumber,
                label: product.name,
              }))}
              name="childProductNumber"
              control={control}
              label="Specifik produkt"
              disabled={!watchHasSpecificProduct}
            />
            <ControlledSelect
              options={Object.entries(productGroups || {}).map(([value, label]) => ({ value, label }))}
              name="childProductGroupNumber"
              control={control}
              label="Kopplad produktgrupp"
              disabled={watchHasSpecificProduct}
            />
          </Col>
          <Col md="6">
            <InputField label="Gilting från" {...register('validFrom')} disabled />
          </Col>
        </Row>
        <hr />
        <Checkbox
          {...register('useQuantityFromParent')}
          label={`Antal hämtas från produktgrupp ovanför (${parentProductGroup?.name})`}
        />
        <InputField
          label="Default antal"
          {...register('definedQuantityInEachParent')}
          disabled={watchUseQuantityFromParent}
        />
        <Checkbox
          {...register('allowMultipleRows')}
          label="Användaren kan lägga till fler rader av denna produkt"
        />
        <Checkbox {...register('isDeliveredSeparately')} label="Levereras separat" />
        <Checkbox {...register('hideInConfigurator')} label="Göm i konfigurator" />
        <Checkbox {...register('forceHideInput')} label="Göm input för antal" />
        <Checkbox {...register('forceHideSelect')} label="Göm select för produkt" />

        <InputField {...register('labelOverride')} label="Label override" />
      </Container>
      <FormSubmitWrapper>
        <Button disabled={!isDirty}>Spara</Button>
      </FormSubmitWrapper>
    </form>
  );
};

export default SubProductForm;
