import { Link } from 'react-router-dom';
import NavTabs from 'ui-library/NavTabs';

export interface PageItem {
  title: string;
  path: string;
  products?: string;
}

const AdminSubNavigation = ({ pages }: { pages: PageItem[] }) => {
  return (
    <NavTabs variant="tabs">
      {pages.map(({ title, path }) => {
        const fullPath = `/admin/${path}`;
        return (
          <Link key={path} to={fullPath}>
            {title}
          </Link>
        );
      })}
    </NavTabs>
  );
};

export default AdminSubNavigation;
