import { forwardRef } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import Label from '../Label';

// interface Props {
//   label?: string;
//   disabled?: boolean;
//   value?: string;
// }

const Textarea = forwardRef(({ label, disabled, ...rest }: any, ref) => {
  return (
    <>
      {label && <Label>{label}</Label>}
      <InputGroup>
        <FormControl as="textarea" disabled={disabled} {...rest} ref={ref} />
      </InputGroup>
    </>
  );
});

export default Textarea;
