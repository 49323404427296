import Button from 'ui-library/Button';

const convertToCSV = (data: any, columnDelimiter = ';', lineDelimiter = '\n') => {
  if (data === null || !data.length) {
    return null;
  }

  const keys = Object.keys(data[0]);

  let result = '';

  data.unshift({
    label: 'Produkt',
    price: 'Pris',
    quantity: 'Mängd',
    calculated: 'Totalt',
  });

  data.forEach((item: any) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      if (item[key] != null) {
        result += item[key];
      } else {
        result += '';
      }
      ctr += 1;
    });
    result += lineDelimiter;
  });

  return result;
};
const DownloadAsCsv = ({ data }: any) => {
  const makeCsv = () => {
    const csv = convertToCSV(data) as BlobPart;
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'log.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Button type="button" onClick={makeCsv}>
      Ladda ner som CSV
    </Button>
  );
};

export default DownloadAsCsv;
