import { forwardRef, useState } from 'react';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import styled from 'styled-components';

const StyledStar = styled.span`
  &.editing {
    cursor: pointer;
  }
`;

type Props = {
  rating: number;
  editMode?: boolean;
  onChange?: (newRating: number) => void;
  value?: number | null;
};
export const ContractorRating = forwardRef(({ rating, editMode, onChange, value }: Props, _ref) => {
  const [tmpRating, setTmpRating] = useState<number | undefined>();

  const starRating = tmpRating ?? value ?? rating;

  const handleHover = (star: number) => () => {
    if (editMode) {
      setTmpRating(star);
    }
  };

  return (
    <div>
      {Array.from(Array(5).keys()).map((star) => {
        return (
          <StyledStar
            className={editMode ? 'editing' : ''}
            key={star}
            onMouseOver={handleHover(star + 1)}
            onMouseOut={() => setTmpRating(undefined)}
            onClick={() => onChange && onChange(star + 1)}
          >
            {star + 1 <= starRating ? (
              <AiFillStar fill="#e9e321" stroke="#000" strokeWidth={15} />
            ) : (
              <AiOutlineStar />
            )}
          </StyledStar>
        );
      })}
    </div>
  );
});
