import { Redirect, Route, useParams } from 'react-router-dom';
// import { useGetTemplateForProduct } from 'Admin/api/template';
import { useCurrentCalculation } from 'hooks/useCurrentCalculation';
import { useGlobalStateValue } from 'stateContext';
import Loader from 'ui-library/Loader';
import Contractor from '../Contractor/Contractor';
import HouseNav from './HouseNav';
import Floor from './Floor';

const HouseTemplate = () => {
  const { templateCalculationId } = useParams<{ templateCalculationId: string }>();
  const { currentCalculation } = useGlobalStateValue();
  const { fetchingCalculation } = useCurrentCalculation();

  // const template = useGetTemplateForProduct(
  //   currentCalculation?.currentHouse.house.parameters.catalogueHouse.productData.productNumber,
  // );

  const isGarageTemplate =
    currentCalculation?.currentHouse.house.garage.templateProduct.productData.productNumber > 0;

  // console.log(currentCalculation?.currentHouse.house.garage.templateProduct.productData.productNumber);

  if (fetchingCalculation) return <Loader label="Hämtar kalkyl" />;
  return (
    <>
      {!isGarageTemplate && <HouseNav isHouseTemplate />}
      <Route path="/husmall/:templateCalculationId" exact>
        {isGarageTemplate && <Redirect to={`/husmall/${templateCalculationId}/huskalkyl/garage`} />}
        <Redirect to={`/husmall/${templateCalculationId}/huskalkyl/hustyp`} />
      </Route>
      <Route path="/husmall/:templateCalculationId/huskalkyl" exact>
        {isGarageTemplate && <Redirect to={`/husmall/${templateCalculationId}/huskalkyl/garage`} />}
        <Redirect to={`/husmall/${templateCalculationId}/huskalkyl/hustyp`} />
      </Route>
      <Route path="/husmall/:templateCalculationId/huskalkyl/hustyp" exact>
        {isGarageTemplate && <Redirect to={`/husmall/${templateCalculationId}/huskalkyl/garage`} />}
        <Floor floorName="parameters" />
      </Route>
      <Route path="/husmall/:templateCalculationId/huskalkyl/bottenvaning" exact>
        <Floor floorName="firstFloor" />
      </Route>
      <Route path="/husmall/:templateCalculationId/entreprenad">
        <Contractor isHouseTemplate isGarageTemplate={isGarageTemplate} />
      </Route>
      <Route path="/husmall/:templateCalculationId/huskalkyl/garage" exact>
        <Floor floorName="garage" />
      </Route>
    </>
  );
};

export default HouseTemplate;
