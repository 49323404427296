import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Floor from './Floor';
import HouseNav from './HouseNav';

const HouseWrapper = styled.div``;
const House = () => {
  return (
    <HouseWrapper>
      <HouseNav />
      <Switch>
        <Route path="/case/:caseNumber/huskalkyl/hustyp" exact>
          <Floor floorName="parameters" />
        </Route>
        <Route path="/case/:caseNumber/huskalkyl/bottenvaning" exact>
          <Floor floorName="firstFloor" />
        </Route>
        <Route path="/case/:caseNumber/huskalkyl/overvaning" exact>
          <Floor floorName="secondFloor" />
        </Route>
        <Route path="/case/:caseNumber/huskalkyl/kallare" exact>
          <Floor floorName="basement" />
        </Route>
        <Route path="/case/:caseNumber/huskalkyl/garage" exact>
          <Floor floorName="garage" />
        </Route>
      </Switch>
    </HouseWrapper>
  );
};

export default House;
