import { useQuery } from 'react-query';
import { adFetch } from 'adalConfig';
import { CaseStatuses, IOptions } from 'models/models';
import { useCurrentCase } from 'api/case';
import { useGlobalStateValue } from 'stateContext';

export const useSelectOptions = () => {
  const { currentCase } = useCurrentCase();
  const { currentCalculation } = useGlobalStateValue();
  const date =
    (currentCase?.status || 0) >= CaseStatuses.SIGNED_CONSTRUCITON_ORDER && currentCalculation !== undefined
      ? currentCalculation?.date
      : 'false';
  const { data: options, isFetching: fetchingOptions } = useQuery(
    ['options', date],
    () => adFetch<IOptions>(`/product/options?date=${date}&houseCategory=0`),
    {
      staleTime: Infinity,
    },
  );

  const getOptionsByName = (optionsName: string) => {
    if (options?.dict[optionsName] && options?.options[options?.dict[optionsName]]) {
      return options?.options[options?.dict[optionsName]];
    }
    return [];
  };
  const getOptionsForProduct = (productNumber: number) => {
    if (options?.options[productNumber]) {
      return options.options[productNumber];
    }
    return [];
  };

  return { options, fetchingOptions, getOptionsForProduct, getOptionsByName };
};
