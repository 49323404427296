import ProductComponent from 'components/ProductComponents/ProductComponent';
import { useProductTemplate } from 'hooks/useProductTemplates';
import { IAdminProductGroup } from 'models/adminModels';
import Loader from 'ui-library/Loader';
import Modal from 'ui-library/Modal';

type Props = {
  show: boolean;
  onClose: () => void;
  adminProductGroup: IAdminProductGroup;
};

const PreviewCalculatorProduct = ({ show, onClose, adminProductGroup }: Props) => {
  const { templates } = useProductTemplate();
  if (!templates) return <Loader />;
  return (
    <Modal show={show} onClose={onClose} header={adminProductGroup.name} size="medium">
      <ProductComponent
        path=""
        inProduct={templates.templates[adminProductGroup.productGroupNumber]}
        isPreview
      />
    </Modal>
  );
};

export default PreviewCalculatorProduct;
