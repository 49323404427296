import { forwardRef } from 'react';
import { InputPropsWithoutRef } from 'react-html-props';
import styled from 'styled-components';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Label from '../Label';

const StyledInputField = styled(FormControl)`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 2px;
  /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); */
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
`;

const StyledAddon = styled(InputGroup.Text)`
  border-color: ${({ theme }) => theme.colors.border};
  border-width: 1px;
  line-height: 1;
  border-left: 0;
  font-size: 0.875rem;
`;
const StyledPrefix = styled(InputGroup.Text)`
  border-right: 0;
  border-width: 1px;
  font-size: 0.875rem;
  line-height: 1;
  border-color: ${({ theme }) => theme.colors.border};
`;
export interface InputFieldProps extends InputPropsWithoutRef {
  label?: string;
  addonText?: string;
  prefix?: string;
  disabled?: boolean;
  readOnly?: boolean;
  hideLabel?: boolean;
}

const InputField = forwardRef(
  ({ label, prefix, addonText, hideLabel = false, ...props }: InputFieldProps, ref: any) => {
    return (
      <div className="input-field">
        {Boolean(label && !hideLabel) && <Label>{label}</Label>}
        <InputGroup>
          {prefix && (
            <InputGroup.Prepend>
              <StyledPrefix>{prefix}</StyledPrefix>
            </InputGroup.Prepend>
          )}
          <StyledInputField {...props} ref={ref} />
          {addonText && (
            <InputGroup.Append>
              <StyledAddon>{addonText.replace('&sup2;', '²')}</StyledAddon>
            </InputGroup.Append>
          )}
        </InputGroup>
      </div>
    );
  },
);

export default InputField;
