import { BsX } from 'react-icons/bs';
import styled from 'styled-components';
import usePdfMaker from 'hooks/usePdfMaker';
import { usePdfContext } from 'hooks/usePdfMaker/PdfContext';
import Button from 'ui-library/Button';
import { useEffect } from 'react';

const DocumentWrapper = styled.div`
  width: 100%;
  min-height: 840px;
  border: 3px solid black;
  background-color: ${({ theme }) => theme.colors.bgDark};
  padding: 2.5rem;
  object {
    width: 100%;
    height: 100%;
    min-height: 1100px;
  }
`;

const DocumentViewer = () => {
  const { documentType, documentVersion, clearDocument, documentUrlData } = usePdfContext();
  usePdfMaker(documentType, documentVersion);

  useEffect(() => {
    if (clearDocument) {
      clearDocument();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (documentUrlData) {
    return (
      <DocumentWrapper>
        <Button onClick={clearDocument}>
          <BsX color="#fff" />
        </Button>
        <object data={documentUrlData} type="application/pdf">
          {documentType} - {documentVersion?.version}
        </object>
      </DocumentWrapper>
    );
  }
  return null;
};

export default DocumentViewer;
