import styled from 'styled-components';
import InputField from '../InputField';

const SearchBox = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px 52px 20px 58px;
  margin-left: -40px;
  min-width: 104.6%;
  display: inline-block;
  box-shadow: 10px 10px 5px -9px rgba(0, 0, 0, 0.45);
`;

const SearchBar = ({ ...props }: any) => {
  return (
    <SearchBox>
      <InputField {...props} />
    </SearchBox>
  );
};

export default SearchBar;
