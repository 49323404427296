import { IAdminProduct, IAdminProductGroup, IAdminSubProduct } from 'models/adminModels';

export const subProductFieldTemplate: IAdminSubProduct = {
  id: 0,
  subProductFieldNumber: 0,
  parentProductGroupNumber: 0,
  validFrom: null,
  definedQuantityInEachParent: 0.0,
  useQuantityFromParent: false,
  allowMultipleRows: false,
  childProductGroupNumber: 0,
  childProductNumber: 0,
  hasSpecificProduct: false,
  hideInConfigurator: false,
  isDeliveredSeparately: false,
  sortIndex: 0,
  isAllowedToBeUndefined: false,
  isDeleted: false,
  labelOverride: null,
  forceHideSelect: false,
  forceHideInput: false,
  changedDate: null,
  changedBy: null,
};

export const productGroupTemplate: IAdminProductGroup = {
  productGroupNumber: 0,
  name: '',
  includeStandardInOptions: false,
  standardProductName: null,
  hideQuantityAndUnitInDeliveryDeclaration: false,
  validationType: null,
  validationMin: 0.0,
  validationMax: 0.0,
  unit: null,
  isTopLevel: false,
  metaData: null,
  standardPriceIncludingVat: 0.0,
  standardPriceExcludingVat: 0.0,
  standardDeductionPrice: 0.0,
  validFrom: null,
  productGroupDependencies: null,
  isAllowedToBeUndefined: false,
  isGarageProduct: false,
  availableIfOneFloor: true,
  availableIfOneAndAHalfFloors: true,
  availableIfOneAndThreeQuartersFloors: true,
  availableIfTwoFloors: true,
  availableIfBasement: true,
  availableIfSplitLevel: true,
  availableIfIntegratedGarage: true,
  availableIfSeparateGarage: true,
  availableIfCustomHouse: true,
  availableIfCatalogueHouse: true,
  availableIfWildStrawberry: true,
  labels: {
    externalTotalPriceLabel: null,
    externalTotalPricePlaceholder: null,
    externalPricePerPieceLabel: null,
    externalPricePerPiecePlaceholder: null,
    descriptionLabel: null,
    descriptionPlaceholder: null,
    externalDeductionPriceLabel: null,
    externalDeductionPricePlaceholder: null,
  },
  logicOverrideType: 0,
  changedDate: '0001-01-01T00:00:00',
  changedBy: null,
  isCanceled: false,
  products: [],
  adminSubProductGroups: [],
  id: -1,
  sortIndex: 0,
  subProductFieldNumber: 0,
};

export const productTemplate: IAdminProduct = {
  productId: 0,
  name: '',
  productNumber: 0,
  productGroupNumber: 0,
  priceIncludingVat: 0.0,
  priceExcludingVat: 0.0,
  netPrice: 0.0,
  deductionPrice: 0.0,
  validFrom: null,
  requiresExternalTotalPrice: false,
  requiresExternalPricePerPiece: false,
  requiresExternalDeductionPrice: false,
  requiresDescription: false,
  isStandardCustom: false,
  isStandardCatalogue: false,
  isStandardCatalogueWildStrawberry: false,
  isContainer: false,
  includeSubProducts: true,
  validationType: null,
  validationMin: null,
  validationMax: null,
  unit: null,
  metaData: null,
  productDependencies: [],
  isNoneProduct: false,
  minOverride: null,
  maxOverride: null,
  labels: {
    id: 0,
    externalTotalPriceLabel: '',
    externalTotalPricePlaceholder: '',
    externalPricePerPieceLabel: '',
    externalPricePerPiecePlaceholder: '',
    descriptionLabel: '',
    descriptionPlaceholder: '',
    externalDeductionPriceLabel: '',
    externalDeductionPricePlaceholder: '',
  },
  isCanceled: false,
  excludeFromWildStrawberry: false,
  changedDate: null,
  changedBy: null,
  sortOrder: 0,
};

const templates: Record<Template, any> = {
  productGroupTemplate,
  productTemplate,
  subProductFieldTemplate,
};

type Template = 'productGroupTemplate' | 'productTemplate' | 'subProductFieldTemplate';

export const getTemplate = (template: Template) => {
  return { ...templates[template] };
};
