import { FaCheck } from 'react-icons/fa';
import styled from 'styled-components';
import { useCurrentCase } from 'api/case';
import { IVersion } from 'models/calculationModel';

const StatusLabel = styled.div``;

type CaseStatus =
  | 'contractsSent'
  | 'contractSigned'
  | 'quoteSent'
  | 'constructionOrderSent'
  | 'constructionOrderSigned';

const statusTexts: Record<CaseStatus, string> = {
  contractsSent: 'Avtal skickat',
  contractSigned: 'Avtal påskrivet',
  quoteSent: 'Offert skickad',
  constructionOrderSent: 'TO skickad',
  constructionOrderSigned: 'TO påskriven',
};
type Props = {
  version: IVersion;
};
const StatusCell = ({ version }: Props) => {
  const { currentCase } = useCurrentCase();
  const statuses: Record<CaseStatus, boolean> = {
    contractsSent: (currentCase?.contractsSent || []).includes(version.version),
    contractSigned: currentCase?.contractVersion === version.version,
    quoteSent: (currentCase?.quotesSent || []).includes(version.version),
    constructionOrderSent: (currentCase?.constructionOrdersSent || []).indexOf(version.version) > -1,
    constructionOrderSigned: currentCase?.constructionOrderSigned === version.version,
  };
  return (
    <div>
      {Object.entries(statusTexts)
        .filter(([key]) => statuses[key as CaseStatus])
        .map(([, value]) => (
          <StatusLabel key={value}>
            <FaCheck />
            {value}
          </StatusLabel>
        ))}
    </div>
  );
};

export default StatusCell;
