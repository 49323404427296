import styled from 'styled-components';

const StvoHeader = styled.h2`
  color: #fff;
  background-color: ${({ theme }) => theme.colors.bgDark};
  font-size: 1.5rem;
  padding: 2.5rem;
  margin: 0;
  margin-top: 2rem;
`;

export default StvoHeader;
