import { calculationHasChangesAtom } from 'atoms';

import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { StyledWarningBox } from 'ui-library/WarningBox';

const StyledHasChangesAlert = styled(StyledWarningBox)`
  position: absolute;
  width: 340px;
  left: 0;
  bottom: -128px;
`;

const CalculationChangesAlert = () => {
  const calculationHasChanges = useRecoilValue(calculationHasChangesAtom);

  if (!calculationHasChanges) return null;

  return (
    <StyledHasChangesAlert>
      <b>OBS!</b> Du har osparade ändringar!
    </StyledHasChangesAlert>
  );
};

export default CalculationChangesAlert;
