import styled from 'styled-components';

const FormWrapper = styled.div`
  width: 66.66%;
  margin: 0 auto;
  /* background: #fff; */
  padding: 2rem 0rem;
  margin-bottom: 2rem;
`;

export default FormWrapper;
