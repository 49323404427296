import { useGetProductGroups } from 'Admin/api';
import { IAdminProduct, IAdminProductGroup } from 'models/adminModels';

export const useProductGroupDependencies = (productGroup: IAdminProductGroup) => {
  const { productGroupsResponse } = useGetProductGroups();
  const productGroupDependencies =
    productGroupsResponse?.productGroupDependentProductGroups[productGroup.productGroupNumber];
  const productDependencies =
    productGroupsResponse?.productGroupDependentProducts[productGroup.productGroupNumber];

  return {
    hasDependencies: Boolean(productGroupDependencies || productDependencies),
    productGroupDependencies,
    productDependencies,
  };
};

export const useProductDependencies = (product: IAdminProduct) => {
  const { productGroupsResponse } = useGetProductGroups();
  const productGroupDependencies =
    productGroupsResponse?.productDependentProductGroups[product.productNumber];
  const productDependencies = productGroupsResponse?.productDependentProducts[product.productNumber];

  return {
    hasDependencies: Boolean(productGroupDependencies || productDependencies),
    productGroupDependencies,
    productDependencies,
  };
};
