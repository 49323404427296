import { useGetSubProductField } from 'Admin/api/product';
import { IAdminProductGroup, IAdminSubProduct } from 'models/adminModels';
import { useEffect } from 'react';
import Box from 'ui-library/Box';
import Checkbox from 'ui-library/Checkbox';
import Select from 'ui-library/Select';

type Props = {
  adminProductGroup: IAdminProductGroup;
  subProductField?: IAdminSubProduct;
  setSubProductField: (spf: IAdminSubProduct) => void;
};
export const ProductGroupSubProductField = ({
  adminProductGroup,
  subProductField,
  setSubProductField,
}: Props) => {
  const { data: subProductFieldData } = useGetSubProductField(adminProductGroup.subProductFieldNumber);

  useEffect(() => {
    if (subProductFieldData) {
      setSubProductField(subProductFieldData);
    }
  }, [subProductFieldData, setSubProductField]);

  const handleSubProductFieldCheckbox =
    (property: any, invert = false) =>
    (e: any) => {
      const check = invert ? !e.target.checked : e.target.checked;
      const spf: any = { ...subProductField, [property]: check };

      setSubProductField(spf);
    };
  const specificProduct = adminProductGroup.products.find(
    (p) => p.productNumber === subProductField?.childProductNumber,
  );
  if (!subProductField) return null;
  return (
    <Box>
      <h5>Inställningar i kalkylator</h5>
      <Checkbox
        checked={!subProductField.useQuantityFromParent}
        label="Ska användaren kunna ange antal för denna underprodukt?"
        onChange={handleSubProductFieldCheckbox('useQuantityFromParent', true)}
      />
      <Checkbox
        checked={subProductField.allowMultipleRows}
        label="Ska användaren kunna lägga till flera rader av denna underproduktkategori?"
        onChange={handleSubProductFieldCheckbox('allowMultipleRows')}
      />
      <Checkbox
        checked={!subProductField.hasSpecificProduct}
        label="Skall användaren kunna välja mellan flera olika produkter?"
        onChange={handleSubProductFieldCheckbox('hasSpecificProduct', true)}
      />
      {subProductField.hasSpecificProduct && (
        <Select
          options={adminProductGroup.products.map((product) => ({
            value: product.productNumber,
            label: product.name,
          }))}
          defaultValue={
            specificProduct && { value: specificProduct.productNumber, label: specificProduct.name }
          }
          label="Välj kopplad produkt"
        />
      )}
    </Box>
  );
};
