import { ISubProduct } from 'models/calculationModel';

export const getTotalQuantity = (subProducts: ISubProduct[]) => {
  let count = 0;
  if (!subProducts) return count;

  subProducts.forEach((subProduct) => {
    if (subProduct.products.length > 0) {
      subProduct.products.forEach((product) => {
        count += product.quantity;
        if (product.subProducts.length > 0) {
          count += getTotalQuantity(product.subProducts);
        }
      });
    }
  });
  return count;
};
