import styled, { css } from 'styled-components';
import { formatPrice } from 'helpers';
import Loader from 'ui-library/Loader';

const PriceNumber = styled.span<any>`
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 300;
  line-height: 1;
  font-size: 3rem;
  display: flex;
  align-items: center;
  .number {
    opacity: ${({ isLoading }) => (isLoading ? '0.5' : '1')};
  }
`;

const PriceLabel = styled.span`
  color: #fff;
  font-weight: bold;
`;

type Variant = 'primary' | 'secondary';
type NumberProps = {
  variant?: Variant;
};
const PriceItemWrapper = styled.div<NumberProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      ${PriceLabel}, ${PriceNumber} {
        color: #c3c7cc;
      }
      ${PriceNumber} {
        font-size: 1.875rem;
      }
    `}
`;

type Props = {
  variant?: Variant;
  price?: number;
  label: string;
  loading?: boolean;
};
const PriceItem = ({ variant = 'primary', price, label, loading }: Props) => {
  return (
    <PriceItemWrapper variant={variant}>
      <PriceLabel>{label}</PriceLabel>
      <PriceNumber isLoading={loading}>
        {loading && <Loader />}
        <span className="number">{price && formatPrice(Math.round(price))}</span>
      </PriceNumber>
    </PriceItemWrapper>
  );
};

export default PriceItem;
