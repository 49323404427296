import { useGetProductGroupsDict, useUpdateSubProductField } from 'Admin/api/product';
import { IAdminSubProduct } from 'models/adminModels';
import { useState } from 'react';
import { getTemplate } from 'templates';
import Button, { FormSubmitWrapper } from 'ui-library/Button';
import Select from 'ui-library/Select';
import { PageProps } from './pageProps';

export const ConnectProductGroup = ({ productGroup }: PageProps) => {
  const [subProductFieldTemplate, setSubProductFieldTemplate] = useState<IAdminSubProduct>(
    getTemplate('subProductFieldTemplate'),
  );
  const { data: productGroupsDict } = useGetProductGroupsDict({ date: false });
  const { mutate: saveSubProductField } = useUpdateSubProductField();

  const handleSetChildProductGroup = ({ value }: any) => {
    setSubProductFieldTemplate({ ...subProductFieldTemplate, childProductGroupNumber: value });
  };

  const handleSave = () => {
    saveSubProductField({
      ...subProductFieldTemplate,
      parentProductGroupNumber: productGroup.productGroupNumber,
    });
  };

  return (
    <>
      <Select
        options={Object.entries(productGroupsDict || {}).map(([value, label]) => ({
          value: Number(value),
          label,
        }))}
        onChange={handleSetChildProductGroup}
      />
      <FormSubmitWrapper>
        <Button onClick={handleSave}>Spara</Button>
      </FormSubmitWrapper>
    </>
  );
};
