import { useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'ui-library/Button';
import { IAdminProduct } from 'models/adminModels';
import { useGetTemplateForProduct } from 'Admin/api/template';
import { FaClone, FaPen, FaSave } from 'react-icons/fa';
import { useUpdateProduct } from 'Admin/api';
import { useCloneProduct } from 'Admin/api/product';
import { useRecoilState } from 'recoil';
import { changedAdminProductsAtom } from 'atoms';
import { StyledChipLabel } from 'ui-library/ChipLabel';
import { EditProduct } from '../Modals';

const UnsavedChip = styled(StyledChipLabel)`
  background-color: ${({ theme }) => theme.colors.messages.warning};
  font-size: 0.875rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 0.25rem;
  }
`;

type Props = {
  product: IAdminProduct;
};
const TableActions = ({ product }: Props) => {
  const [showEditProduct, setShowEditProduct] = useState(false);
  const { pathname } = useLocation();
  const { mutate: updateProduct } = useUpdateProduct();
  const { mutate: cloneProduct } = useCloneProduct();
  const [changedProducts, setChangedProducts] = useRecoilState(changedAdminProductsAtom);

  const thisHasChanged = changedProducts?.find((pr) => pr.productNumber === product.productNumber);

  const isCatalogueHouse = pathname.includes('kataloghus');
  const template = useGetTemplateForProduct(product.productNumber);

  const quickSave = () => {
    updateProduct(
      { ...product, validFrom: null },
      {
        onSuccess: () => {
          setChangedProducts([]);
        },
      },
    );
  };
  const handleCloneProduct = () => {
    cloneProduct(product);
  };

  return (
    <>
      <ButtonsWrapper>
        {thisHasChanged && <UnsavedChip>Ändringar</UnsavedChip>}
        <Button
          size="small"
          variant="secondary"
          icon
          onClick={() => setShowEditProduct(true)}
          title="Editera produkt"
        >
          <FaPen />
        </Button>
        <Button disabled={!thisHasChanged} size="small" icon onClick={quickSave} title="Spara ändringar">
          <FaSave />
        </Button>
        <Button size="small" icon onClick={handleCloneProduct} title="Klona produkt">
          <FaClone />
        </Button>
        {Boolean(isCatalogueHouse && template) && (
          <Button size="small" onClick={() => {}}>
            <Link to={`/husmall/${template?.calculationId}`}>Gå till mall</Link>
          </Button>
        )}
        {Boolean(isCatalogueHouse && !template) && <span>Ingen mall</span>}
      </ButtonsWrapper>

      <EditProduct show={showEditProduct} onClose={() => setShowEditProduct(false)} product={product} />
    </>
  );
};

export default TableActions;
