import { FloorName } from 'models/models';
import { TitleComponent } from '../ProductComponent/TitleComponent';
import DefinedFrontDoors from './DefinedFrontDoors';
import UndefinedFrontDoors from './UndefinedFrontDoors';

type Props = {
  floor: FloorName;
  productName: string;
};
const FrontDoors = ({ floor, productName }: Props) => {
  let definedOrUndefined = null;
  if (productName === 'frontDoorsDefined') {
    definedOrUndefined = <DefinedFrontDoors floor={floor} />;
  }
  if (productName === 'frontDoorsUndefined') {
    definedOrUndefined = <UndefinedFrontDoors floor={floor} />;
  }
  return (
    <div>
      <TitleComponent nameOverride="Ytterdörrar" type="banner" />
      {definedOrUndefined}
    </div>
  );
};

export default FrontDoors;
