import { IAdminProduct } from 'models/adminModels';
import { atom } from 'recoil';

export const caseChangedAtom = atom<boolean>({
  key: 'caseChangedAtom',
  default: false,
});
export const calculationHasChangesAtom = atom<boolean>({
  key: 'initialCalcuation',
  default: false,
});
export const changedAdminProductsAtom = atom<IAdminProduct[]>({
  key: 'changedProducts',
  default: [],
});
