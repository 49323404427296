import { useCurrentCase, useUpdateCase } from 'api/case';
import { useCurrentCalculation } from 'hooks/useCurrentCalculation';
import set from 'lodash/set';
import {
  ICase,
  IProductionCostCalculation,
  IProductionCostCalculationRow,
  ProductionCostCalculationColumn,
} from 'models/models';
import { useState } from 'react';
import styled from 'styled-components';
import Box from 'ui-library/Box';
import Button, { FormSubmitWrapper } from 'ui-library/Button';
import InputField from 'ui-library/InputField';
import LabeledCollapse from 'ui-library/LabeledCollapse';
import Loader from 'ui-library/Loader';
import Modal from 'ui-library/Modal';
import Textarea from 'ui-library/Textarea';
import { useProductionCalculationCost } from './productionCalculationCost';

const StyledCalculationRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;
const CalculationBox = styled(Box)`
  border-bottom: 1px solid;
  &:nth-child(even) {
    background: ${({ theme }) => theme.colors.bgLight};
  }
`;

type Props = {
  show: boolean;
  onClose: () => void;
};

const rowHeaders = [
  'Bra Hus åtaganden',
  'Skriftligt anbud/avtal',
  'Köparens uppgift',
  'Agentens uppskattning',
];
type CalculationRowProps = {
  row: IProductionCostCalculationRow;
  path: string;
  onChange: (path: string, value: any) => void;
};
const CalculationRow = ({ row, onChange, path }: CalculationRowProps) => {
  const columns = Object.keys(row)
    .filter((key) => key.startsWith('column'))
    .map((key) => (row as any)[key]);
  const { isDynamic, getProductionCalculationCost, hasUserValue } = useProductionCalculationCost(row);
  const handleChange = (property: string) => (e: any) => {
    onChange(`${path}.${property}`, e.target.value);
  };

  return (
    <CalculationBox>
      <p>{row.name}</p>
      <StyledCalculationRow>
        {columns.map((col: ProductionCostCalculationColumn, idx) => {
          const value = isDynamic && idx === 1 && !hasUserValue ? getProductionCalculationCost() : col.value;
          return (
            <InputField
              label={rowHeaders[idx]}
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              defaultValue={value}
              disabled={col.isLocked}
              placeholder={col.isLocked ? 'Ej valbar' : ''}
              onChange={handleChange(`column${idx + 1}.value`)}
            />
          );
        })}
      </StyledCalculationRow>
      <Textarea defaultValue={row.comment} onChange={handleChange('comment')} />
    </CalculationBox>
  );
};

const ProductionCostEditor = ({ show, onClose }: Props) => {
  useCurrentCalculation();
  const { currentCase } = useCurrentCase();
  const { mutate: updateCase } = useUpdateCase();
  const [tmpCase, setTmpCase] = useState<ICase | undefined>(currentCase);

  if (!currentCase || !tmpCase) return <Loader label="Hämtar ärende" />;
  const { productionCostCalculation } = currentCase;

  const handleChange = (path: string, value: any) => {
    const changed = { ...tmpCase.productionCostCalculation };
    set(changed, `${path}`, value);
    setTmpCase({ ...tmpCase, productionCostCalculation: changed as IProductionCostCalculation });
  };

  const handleSave = () => {
    updateCase(tmpCase);
  };

  return (
    <Modal show={show} header="Produktionskostnadskalkyl" onClose={onClose} size="medium">
      {productionCostCalculation?.groups.map((group, gidx) => {
        return (
          <div key={group.name}>
            <LabeledCollapse label={group.name}>
              {group.rows.map((row, idx) => {
                return (
                  <CalculationRow
                    key={row.rowNumber}
                    row={row}
                    onChange={handleChange}
                    path={`groups.[${gidx}].rows.[${idx}]`}
                  />
                );
              })}
            </LabeledCollapse>
          </div>
        );
      })}
      <LabeledCollapse label="Övriga upplysningar">
        <Textarea
          defaultValue={productionCostCalculation?.comment}
          onChange={(e: any) => {
            handleChange('comment', e.target.value);
          }}
        />
      </LabeledCollapse>
      <FormSubmitWrapper>
        <Button onClick={handleSave}>Spara</Button>
      </FormSubmitWrapper>
    </Modal>
  );
};

export default ProductionCostEditor;
