/* eslint-disable import/no-cycle */

import styled from 'styled-components';
import { ImCross } from 'react-icons/im';
import { useProductTemplate } from 'hooks/useProductTemplates';
import { IProduct, ISubProduct } from 'models/calculationModel';
import Button from 'ui-library/Button';
import ProductComponent from 'components/ProductComponents/ProductComponent';
import { joinPath } from 'helpers';
import { FloorName } from 'models/models';
import { useSelectOptions } from 'hooks/useSelectOptions';
import Arrow from 'components/ConstructionOrderValidation/Arrow';
import { usePrice, useSetProductValue } from 'api/price';
import { TitleComponent } from '../ProductComponent/TitleComponent';

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    margin: 0;
  }
`;

const DeleteButton = styled(Button)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;
const RowWrapper = styled.div`
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 1rem;
`;
const RowItem = styled.li`
  background-color: ${({ theme }) => theme.colors.bgLight};
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  padding: 1rem;
  padding-top: 2rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  position: relative;
`;

type Props = {
  floor?: FloorName;
  path: string;
  subProduct: ISubProduct;
  disabled?: boolean;
  property?: string;
  isPreview?: boolean;
  overrideTitle?: string;
};

const DynamicProductList = ({
  subProduct,
  path,
  disabled,
  property = 'products',
  isPreview,
  floor,
  overrideTitle,
}: Props) => {
  const setProductValue = useSetProductValue(0);
  const { templates } = useProductTemplate();
  const { getOptionsForProduct } = useSelectOptions();
  const { price } = usePrice();

  const { products, productGroup } = subProduct;

  const newProductTemplate = templates?.templates[productGroup] as IProduct;
  const createRow = () => {
    const opts = getOptionsForProduct(productGroup);
    setProductValue(path, property, [...products, { ...newProductTemplate, productData: opts[0].data }]);
  };

  const needsDefinition = Boolean(
    floor &&
      price?.deliveryDeclarationValidation.validationItemDictionary[floor]?.messages.some(
        (mess) => mess.productGroupNumber === productGroup,
      ),
  );

  const deleteRow = (idx: number) => {
    const spliced = [...products];
    spliced.splice(idx, 1);

    setProductValue(path, property, [...spliced]);
  };
  return (
    <RowWrapper className="subproduct-row">
      <TitleRow>
        {newProductTemplate && (
          <TitleComponent nameOverride={overrideTitle} product={newProductTemplate} regularTitle />
        )}
        <Arrow show={needsDefinition} text="Definiera" />

        <Button onClick={createRow} disabled={disabled}>
          Lägg till ny rad
        </Button>
      </TitleRow>
      <ul>
        {products.map((product, idx) => {
          const i = idx;
          return (
            <RowItem
              key={`${product.productData.productNumber}${product.productData.productGroupData.productGroupNumber}-${i}`}
            >
              <ProductComponent
                parentSubProduct={subProduct}
                disabled={disabled}
                path={`${joinPath(path, property)}[${idx}]`}
                isPreview={isPreview}
                floor={floor}
              />
              {!disabled && (
                <DeleteButton icon onClick={() => deleteRow(idx)}>
                  <ImCross />
                </DeleteButton>
              )}
            </RowItem>
          );
        })}
      </ul>
    </RowWrapper>
  );
};

export default DynamicProductList;
