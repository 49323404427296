import { IAdminProductGroup } from 'models/adminModels';
import { useState } from 'react';
import Modal from 'ui-library/Modal';
import { StartPage } from './Pages/StartPage';
import { NewProductGroup } from './Pages/NewProductGroup';
import { ConnectProductGroup } from './Pages/ConnectProductGroup';

type Props = {
  show: boolean;
  onClose: () => void;
  productGroup: IAdminProductGroup;
};

export const ProductWizard = ({ show, onClose, productGroup }: Props) => {
  const [page, setPage] = useState('start');
  const handleClose = () => {
    setPage('start');
    onClose();
  };
  return (
    <Modal header="Lägg till produkt/grupp" show={show} onClose={handleClose}>
      {page === 'start' && <StartPage productGroup={productGroup} setPage={setPage} onClose={handleClose} />}
      {page === 'newProductGroup' && (
        <NewProductGroup productGroup={productGroup} setPage={setPage} onClose={handleClose} />
      )}
      {page === 'connectProductgroup' && (
        <ConnectProductGroup productGroup={productGroup} setPage={setPage} onClose={handleClose} />
      )}
    </Modal>
  );
};
