import ContractorDetailsForm from 'Admin/Components/Contractors/ContractorDetailsForm';
import Modal from 'ui-library/Modal';

type Props = {
  show: boolean;
  onClose: () => void;
};

const AddContractor = ({ show, onClose }: Props) => {
  return (
    <Modal size="medium" show={show} onClose={onClose} header="Lägg till entreprenör">
      <ContractorDetailsForm isAdding />
    </Modal>
  );
};

export default AddContractor;
