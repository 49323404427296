import cloneDeep from 'lodash/cloneDeep';
import { IPriceResponse } from 'models/priceModel';

const createProductTableRow = (productField: any, index: number) => {
  // console.log('-- Product field', productField.productData.productGroupData.name, productField.productData.name, productField);
  let productFieldName =
    productField.productData.productNumber <= 0
      ? productField.productData.productGroupData.standardProductName || 'Standard'
      : productField.productData.name;
  const unit = productField.productData.unit || productField.productData.productGroupData.unit || '';
  let unitText = '';
  if (!productField.productData.productGroupData.rules.hideQuantityAndUnitInDeliveryDeclaration) {
    unitText = unit ? `${productField.quantity} ${unit}` : '';
    unitText = unitText.replace('&sup2;', '²');
  }

  const rows = [];

  let metaDescription = '';
  if (productField.productData.metaData) {
    if (
      productField.productData.metaData.description &&
      productField.productData.metaData.description !== ''
    ) {
      metaDescription = productField.productData.metaData.description;
    }
    if (productField.productData.metaData.title && productField.productData.metaData.title !== '') {
      productFieldName = productField.productData.metaData.title;
    }
  } else if (productField.productData.productGroupData.metaData) {
    if (
      productField.productData.productGroupData.metaData.description &&
      productField.productData.productGroupData.metaData.description !== ''
    ) {
      metaDescription = productField.productData.productGroupData.metaData.description;
    }
  }

  const fillColor = index % 2 === 0 ? '#EEEEEE' : null;

  const titleMargin = productField.LDLevel ? productField.LDLevel * 15 : 0;

  let descriptionSubRow = null;
  if (productField.description) {
    descriptionSubRow = [
      {
        text: '',
        fillColor,
        margin: [0, 0, 0, 7],
      },
      {
        text: productField.description,
        fillColor,
        bold: true,
        italicts: true,
        colSpan: 4,
        margin: [titleMargin, 0, 0, 0],
      },
    ];
  }

  let metaDescriptionSubRow = null;
  if (metaDescription.length && metaDescription.length > 20) {
    metaDescriptionSubRow = [
      {
        text: '',
        fillColor,
      },
      {
        text: metaDescription,
        // margin: [0,3,0,5],
        colSpan: 4,
        bold: true,
        fillColor,
        margin: [titleMargin, 0, 0, 0],
      },
    ];
  }

  const row = [
    { text: '-', fillColor },
    {
      text: productField.productData.productGroupData.name || '',
      margin: [titleMargin, 0, 0, 0],
      fillColor,
    },
    { text: productFieldName || '', fillColor },
    { text: '', fillColor },
    { text: unitText || '', fillColor },
  ];
  rows.push(row);
  if (metaDescriptionSubRow) {
    rows.push(metaDescriptionSubRow);
  }
  if (descriptionSubRow) {
    rows.push(descriptionSubRow);
  }

  // This is needed to keep counter in sync in global zebra-function for this table
  if (rows.length && rows.length > 1) {
    if (index % 2 === rows.length % 2) {
      rows.push([null, null, null, null, null]);
    }
  }

  return rows;
};

const productRowCounter = { index: 0 };
const extractProductRows = (
  productField: any,
  index: number,
  withDeliveredSeparatley = false,
  level: number,
) => {
  if (!productField) return [];

  const productRows = [];

  // console.log('-- Product field', productField.productData.productGroupData.name, productField.productData.name, productField);
  if (
    !productField.productData.rules.isContainer &&
    !productField.productData.rules.isNoneProduct &&
    productField.quantity > 0
  ) {
    if (withDeliveredSeparatley && level < 1) {
      return [];
    }
    // if (!productField.LDLevel) productField.LDLevel = 0;
    const rows = createProductTableRow(
      { ...productField, LDLevel: productField.LDLevel ?? 0 },
      productRowCounter.index,
    );
    if (rows) {
      productRows.push(...rows);
    }
    productRowCounter.index += 1;
  }

  if (productField.subProducts) {
    const { subProducts } = productField;

    subProducts.forEach((subProduct: any) => {
      if (subProduct.products && subProduct.rules.isDeliveredSeparately === withDeliveredSeparatley) {
        subProduct.products.forEach((product: any) => {
          // product.LDLevel = level;

          const extractedRows = extractProductRows(
            { ...product, LDLevel: level },
            index + 1,
            withDeliveredSeparatley,
            level + 1,
          );
          productRows.push(...extractedRows);
        });
      }
    });
  }

  return productRows;
};

const createProductTable = (headline: string, productField: any, withDeliveredSeparatley = false) => {
  if (productField === null || productField === undefined) return false;

  const productRows = [];
  productRowCounter.index = 0;
  const extractedProductRows = extractProductRows(productField, 0, withDeliveredSeparatley, 0);

  productRows.push([{ text: headline, colSpan: 5 }, '', '', '', '']);
  productRows.push(...extractedProductRows);

  if (extractedProductRows.length === 0) {
    return null;
  }

  return {
    style: 'infoTable',
    table: {
      headerRows: 1,
      dontBreakRows: true,
      widths: ['auto', 200, 125, 'auto', '*'],
      body: productRows,
    },
    margin: [20, 10, 0, 0],
    layout: {
      hLineWidth: () => {
        return 0;
      },
      vLineWidth: () => {
        return 0;
      },
    },
  };
};

const getTableForProductField: any = (productField: any, title: any, withDeliveredSeparatley: boolean) => {
  if (productField === null || productField === undefined) return false;

  return createProductTable(title, productField, withDeliveredSeparatley);
};

const getMetaDataTable = (metaDataTable: any, headline: string) => {
  const table: any = {
    style: 'infoTable',
    table: {
      headerRows: 1,
      dontBreakRows: true,
      widths: ['auto', 250, '*', '*'],
    },
    margin: [20, 10, 100, 0],
    layout: 'noBorders',
  };
  let tableRows = [];

  if (headline) {
    tableRows.push([{ text: headline, colSpan: 4 }, '', '', '']);
  }

  const tableBodyRows = metaDataTable.map((rowData: any) => {
    const row = ['-', { text: rowData.col1 }, { text: rowData.col2 }, { text: rowData.col3 }];
    return row;
  });
  if (tableBodyRows.length > 0) {
    tableRows = tableRows.concat(tableBodyRows);
    table.table.body = tableRows;
    return table;
  }
  return false;
};

const section = (sectionData: any) => {
  const table = [];
  if (sectionData.title) {
    table.push({
      text: sectionData.title,
      style: 'sectionHeadline',
    });
  }
  if (sectionData.subTitle) {
    table.push({
      text: sectionData.subTitle,
      style: 'sectionSubTitle',
    });
  }
  if (sectionData.subSections) {
    sectionData.subSections.forEach((subSection: any) => {
      table.push(subSection);
    });
  }
  table.push({ text: '', pageBreak: 'after' });
  return table;
};

const makeSectionHeadline = (headline: string) => {
  const style = 'subSectionHeadline';
  return [{ text: headline, style }];
};
const makeDescription = (description: string) => {
  return [{ text: description, style: 'sectionSubTitle' }];
};

class SubSection {
  constructor(sectionData: any, isSubsection: any) {
    this.sectionData = sectionData;
    this.isSubsection = isSubsection;
    this.assembleSectionDefinition();
  }

  isSubsection;

  sectionData;

  sectionTitle: any = null;

  sectionDescription: any = null;

  metaDataTable: any = null;

  subProductTable = [];

  documentData: any = [];

  groupMetaDataTable: any;

  deliveredSeparatelyTable: any;

  assembleSectionDefinition() {
    // if( this.isSubsection ) return null;

    const topProduct = this.sectionData;
    if (!topProduct) {
      return {};
    }

    const productMeta = topProduct.productData.metaData || {};
    const productGroupMeta = topProduct.productData.productGroupData.metaData || {};

    if (productMeta.tables && productMeta.tables.length > 0) {
      this.metaDataTable = productMeta.tables.map((table: any) => {
        const t = getMetaDataTable(table.tableRows, table.tableHeadline);
        return t;
      });
    }

    if (productGroupMeta.tables && productGroupMeta.tables.length > 0) {
      this.groupMetaDataTable = productGroupMeta.tables.map((table: any) => {
        const t = getMetaDataTable(table.tableRows, table.tableHeadline);
        return t;
      });
    }

    // Make section title
    this.sectionTitle =
      productGroupMeta.title ||
      topProduct.productData.productGroupData.name ||
      productMeta.title ||
      topProduct.productData.name;

    // Make description
    if (productMeta.description || productGroupMeta.description) {
      this.sectionDescription = makeDescription(productMeta.description || productGroupMeta.description);
    }

    // Make subProduct table
    const subProductTableTitle = productMeta.subProductHeadline || '';
    this.subProductTable = getTableForProductField(this.sectionData, subProductTableTitle, false);
    this.deliveredSeparatelyTable = getTableForProductField(
      this.sectionData,
      'Levereras löst för montering på byggplats',
      true,
    );

    // Assemble section pieces
    if (this.sectionTitle) {
      this.sectionTitle = makeSectionHeadline(this.sectionTitle);
      this.documentData.push(this.sectionTitle);
    }
    if (this.sectionDescription) {
      this.documentData.push(this.sectionDescription);
    }
    if (this.subProductTable) {
      this.documentData.push(this.subProductTable);
    }
    if (this.deliveredSeparatelyTable) {
      this.documentData.push(this.deliveredSeparatelyTable);
    }
    if (this.metaDataTable) {
      this.documentData.push(this.metaDataTable);
    }
    if (this.groupMetaDataTable) {
      this.documentData.push(this.groupMetaDataTable);
    }
    return null;
  }
}

const sectionMapper = (productData: any) => {
  const subSection = new SubSection(productData.productField, productData.isSubsection);

  return subSection.documentData;
};

export const getDeliveryDeclarationData = (price: IPriceResponse) => {
  const sections = [
    {
      topSection: 'houseParameters',
      title: 'Husegenskaper',
    },
    {
      topSection: 'firstFloor',
      title: 'Bottenvåning',
    },
    {
      topSection: 'secondFloor',
      title: 'Övervåning',
    },
    {
      topSection: 'basement',
      title: 'Källare',
    },
    {
      topSection: 'garage',
      title: 'Garage/Sidobyggnad',
    },
    {
      topSection: 'misc',
      title: 'Övrigt',
    },
  ];

  const newLayout = sections.map((topSection) => {
    const products = price?.deliveryDeclarationSource[topSection.topSection].list;

    if (products && products.length > 0) {
      return section({
        title: topSection.title,
        subSections: products.map(sectionMapper),
      });
    }

    return {};
  });

  return cloneDeep(newLayout);
};
