import { useCurrentCase, useUpdateCase } from 'api/case';
import { IVersion } from 'models/calculationModel';
import { ICase } from 'models/models';
import StyledButton from 'ui-library/Button';
import Modal from 'ui-library/Modal';
import { usePdfContext } from 'hooks/usePdfMaker/PdfContext';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { DocumentType } from 'hooks/usePdfMaker';

type Props = {
  show: boolean;
  onClose: () => void;
  version: IVersion;
};
const GenerateContractModal = ({ show, onClose, version }: Props) => {
  const { currentCase } = useCurrentCase();
  const { openDocument } = usePdfContext();

  const { mutate: saveCase, isLoading } = useUpdateCase();
  const queryClient = useQueryClient();

  const canGenerateContract = () => {
    if (!currentCase) return false;
    const requiredFields = [
      'drawingNumberHouse',
      'indexBaseMonth',
      'indexBaseYear',
      'drawingHouseCreatedDate',
      'propertyDesignation',
      'propertyMunicipal',
    ];
    return requiredFields.every((field) => {
      return Boolean(currentCase[field as keyof ICase]);
    });
  };
  const handleOpen =
    (contractType: DocumentType = 'contract', send = false) =>
    () => {
      if (canGenerateContract()) {
        openDocument(contractType, version);
        if (send && !currentCase?.contractsSent.includes(version.version)) {
          const updatedContracts = currentCase?.contractsSent
            ? [...currentCase?.contractsSent, version.version]
            : [version.version];
          const updatedCase = { ...currentCase, contractsSent: updatedContracts };
          saveCase(updatedCase as ICase, {
            onSuccess() {
              toast.success('Kontrakt skickat');
              queryClient.invalidateQueries(['versions']);
              queryClient.invalidateQueries(['case']);
              onClose();
            },
          });
        }
        if (!send) {
          onClose();
        }
      }
    };

  return (
    <Modal header="Generera avtal" show={show} onClose={onClose}>
      {!canGenerateContract() && (
        <>
          <p>
            Nödvändig information saknas för att kunna generera avtal.
            <br /> Nödvändiga uppgifter är:
          </p>
          <ul>
            <li>Ritningsnr. hus</li>
            <li>Datum, ritning skapad</li>
            <li>Index basmånad/år</li>
            <li>Fastighets beteckning + kommun</li>
          </ul>
        </>
      )}
      {canGenerateContract() && (
        <>
          <StyledButton variant="secondary" onClick={handleOpen()} disabled={isLoading}>
            Generera och förandsgranska
          </StyledButton>
          <StyledButton variant="secondary" onClick={handleOpen('contractB2B')} disabled={isLoading}>
            Generera och förandsgranska ABM07
          </StyledButton>
          <StyledButton onClick={handleOpen('contract', true)} disabled={isLoading}>
            Generera och skicka
          </StyledButton>
          <StyledButton onClick={handleOpen('contractB2B', true)} disabled={isLoading}>
            Generera och skicka ABM07
          </StyledButton>
        </>
      )}
    </Modal>
  );
};

export default GenerateContractModal;
