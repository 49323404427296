import { useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import styled from 'styled-components';
import { CaseStatuses, ICase } from 'models/models';
import { defaultTheme } from 'theme';

import List from 'ui-library/List';
import { useLatestCases } from 'api/case';
import ChipLabel from 'ui-library/ChipLabel';
import Loader from 'ui-library/Loader';
import Filters, { ILatestCaseFilters } from './Filters';
import { caseStatuses } from '../../constants';

const LatestCasesStyled = styled.div``;
const LatestCaseItemStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .user-name {
    font-size: 11px;
    font-weight: bold;
  }
  .user-name,
  .date {
    font-style: italic;
    color: ${({ theme }) => theme.colors.gray};
    display: inline-block;
    margin-right: 1rem;
  }
`;
const CaseName = styled.div`
  display: flex;
  align-items: center;
  .case-name {
    display: inline-block;
    margin-right: 0.5rem;
    max-width: 580px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const sortByChanged = (a: ICase, b: ICase) => {
  if (!a || !b) return 0;
  const caseA = new Date(a.changedAt as string).getTime();
  const caseB = new Date(b.changedAt as string).getTime();
  return caseB - caseA;
};

const filterSearch = (caseItem: ICase, search: string) => {
  return (
    caseItem.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    caseItem.userName!.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );
};

type LatestCaseItemProps = {
  caseItem: ICase;
};
const LatestCaseItem = ({ caseItem }: LatestCaseItemProps) => {
  const caseStatus = caseItem.status as CaseStatuses;
  return (
    <LatestCaseItemStyled>
      <CaseName>
        <span className="case-name">{caseItem.name}</span>
        <ChipLabel type={caseStatus} label={caseStatuses[caseStatus].label} small />
      </CaseName>
      <div className="changed">
        <span className="user-name">({caseItem.userName})</span>
        <span className="date">Ändrad {format(new Date(caseItem.changedAt!), 'yyyy-MM-dd hh:mm:ss')}</span>
        <FaChevronRight color={defaultTheme.colors.primary} fontSize="1.25rem" />
      </div>
    </LatestCaseItemStyled>
  );
};

const LatestCases = () => {
  const { data: allCases, isLoading, isFetching, error } = useLatestCases();
  const [filters, setFilters] = useState<ILatestCaseFilters>({ statusToggle: [] });
  const loading = isLoading && !error;
  return (
    <LatestCasesStyled>
      <h5>Senaste ärenden</h5>
      <Filters filters={filters} setFilters={setFilters} />
      {error && <>Kunde inte hämta ärenden</>}
      {loading && <Loader label="Hämtar ärenden" size="large" />}
      {Boolean(isFetching && !loading) && <Loader label="Hämtar nya ärenden" />}
      {allCases && (
        <List>
          {allCases
            .filter((c) => filterSearch(c, filters.search || ''))
            .filter((c) =>
              filters.statusToggle.length > 0 ? filters.statusToggle.includes(c.status!) : true,
            )
            .sort(sortByChanged)
            .map((caseItem) => {
              return (
                <Link key={caseItem.caseNumber} to={`/case/${caseItem.caseNumber}`}>
                  <LatestCaseItem caseItem={caseItem} />
                </Link>
              );
            })}
        </List>
      )}
    </LatestCasesStyled>
  );
};

export default LatestCases;
