import Modal from 'ui-library/Modal';
import CustomerAddForm from 'Admin/Components/Customer/CustomerAddForm';

type Props = {
  show: boolean;
  onClose: () => void;
};

const AddCustomer = ({ show, onClose }: Props) => {
  return (
    <Modal size="medium" show={show} onClose={onClose} header="Lägg till kund">
      <CustomerAddForm />
    </Modal>
  );
};

export default AddCustomer;
