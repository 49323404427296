import styled from 'styled-components';
import { CaseStatuses } from 'models/models';
import ChipLabel from 'ui-library/ChipLabel';
import SearchBar from 'ui-library/SearchBar';
import { caseStatuses } from '../../constants';

export interface ILatestCaseFilters {
  search?: string;
  statusToggle: CaseStatuses[];
}

const StatusFiltersList = styled.ul`
  margin-bottom: 0.5rem;
  display: flex;
  .chip {
    margin-right: 0.5rem;
  }
`;

type Props = {
  filters: ILatestCaseFilters;
  setFilters: (filters: ILatestCaseFilters) => void;
};

const Filters = ({ filters, setFilters }: Props) => {
  const toggleStatus = (status: number) => {
    if (filters.statusToggle.includes(status)) {
      setFilters({ ...filters, statusToggle: filters.statusToggle.filter((s) => s !== status) });
    } else {
      setFilters({ ...filters, statusToggle: [...filters.statusToggle, status] });
    }
  };
  return (
    <div>
      <StatusFiltersList>
        {Object.entries(caseStatuses).map((status) => {
          const [key, value] = status;
          return (
            <ChipLabel
              label={value.label}
              type={Number(key)}
              key={key}
              active={filters.statusToggle.includes(Number(key))}
              onClick={() => toggleStatus(Number(key))}
            />
          );
        })}
      </StatusFiltersList>
      <SearchBar
        placeholder="Sök (agent/ärende)"
        onChange={(e: any) => setFilters({ ...filters, search: e.target.value })}
      />
    </div>
  );
};

export default Filters;
