import { IAdminProduct, IAdminProductGroup } from 'models/adminModels';
import StandardBadge, { ExcludedBadge } from 'ui-library/StandardBadge';
import InputField from 'ui-library/InputField';
import { useRef } from 'react';
import TableActions from './TableActions';

const externalPrice = (product: IAdminProduct) => {
  return (
    product.requiresExternalDeductionPrice ||
    product.requiresExternalPricePerPiece ||
    product.requiresExternalTotalPrice ||
    product.isContainer
  );
};

type InputProps = {
  product: IAdminProduct;
  property: keyof IAdminProduct;
  onChange: (value: any, property: keyof IAdminProduct) => void;
};
const InputFieldCell = ({ product, property, onChange }: InputProps) => {
  const inputRef = useRef<any>();
  return (
    <InputField
      defaultValue={product[property]}
      onChange={(e: any) => onChange(e.target.value, property)}
      ref={inputRef}
    />
  );
};

type Props = {
  product: IAdminProduct;
  productGroup: IAdminProductGroup;
  onChange: (product: IAdminProduct) => void;
};
export const ProductTableRow = ({ product, productGroup, onChange }: Props) => {
  const handleChange = (value: any, key: keyof IAdminProduct) => {
    onChange({
      ...product,
      priceExcludingVat: key === 'priceIncludingVat' ? Number(value) * 0.8 : product.priceExcludingVat,
      validFrom: null,
      [key]: value,
    });
  };
  return (
    <tr>
      <td>
        <>
          {product.isStandardCustom && <StandardBadge type="custom">S</StandardBadge>}
          {product.isStandardCatalogue && <StandardBadge type="catalogue">K</StandardBadge>}
          {product.isStandardCatalogueWildStrawberry && (
            <StandardBadge type="wildStrawBerry">Sm</StandardBadge>
          )}
          {product.excludeFromWildStrawberry && <ExcludedBadge type="wildStrawBerry">Sm</ExcludedBadge>}
        </>
      </td>
      <td>
        <>
          {product.isCanceled && <b>(OBS! utgått) </b>}
          {product.name}
        </>
      </td>
      <td>
        {!externalPrice(product) && (
          <InputFieldCell product={product} property="netPrice" onChange={handleChange} />
        )}
      </td>
      <td>{!externalPrice(product) && <b>{Math.round(product.priceExcludingVat)}</b>}</td>
      <td>
        {!externalPrice(product) && (
          <InputFieldCell product={product} property="priceIncludingVat" onChange={handleChange} />
        )}
      </td>
      <td>
        {!externalPrice(product) && (
          <b>{product.priceIncludingVat - productGroup.standardPriceIncludingVat}</b>
        )}
      </td>
      <td>
        {!externalPrice(product) && (
          <InputFieldCell product={product} property="deductionPrice" onChange={handleChange} />
        )}
      </td>
      <td>
        <TableActions product={product} />
      </td>
    </tr>
  );
};
