import { isValid } from 'date-fns';
import format from 'date-fns/format';
import { ICalculation } from 'models/calculationModel';

export const objectToParams = (obj: { [key: string]: any }) => {
  return Object.entries(obj)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');
};

export const joinPath = (...args: string[]): string => {
  return args.filter((v) => v).join('.');
};

export const formatPrice = (price: string | number) => {
  return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') || '';
};

export const formatDate = (date: string | Date, formatting = 'yyyy-MM-dd HH:mm:ss') => {
  const _date = typeof date === 'string' ? new Date(date) : date;
  if (isValid(_date)) {
    return format(_date, formatting);
  }
  return _date.toDateString();
};

export const getHouseName = (calculation: ICalculation) => {
  const { catalogueHouse, wildStrawBerry } = calculation.currentHouse.house.parameters;

  let templateHouseProduct = null;
  if (catalogueHouse.productData.productNumber > -1) {
    templateHouseProduct = catalogueHouse;
  }
  if (wildStrawBerry.productData.productNumber > -1) {
    templateHouseProduct = wildStrawBerry;
  }

  if (!templateHouseProduct) {
    return 'Specialhus';
  }
  return templateHouseProduct.productData.name;
};
