import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import React, { createContext, useCallback, useContext, useReducer } from 'react';
import { joinPath } from './helpers';
import { ICalculation } from './models/calculationModel';
import { CurrentUser, IOptions } from './models/models';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SET_CURRENT_CASE = 'SET_CURRENT_CASE';
export const SET_CURRENT_CALCULATION = 'SET_CURRENT_CALCULATION';
export const FETCHING_OPTIONS = 'FETCHING_OPTIONS';
export const SET_FETCHING_CALCULATION = 'SET_FETCHING_CALCULATION';
export const SET_HOUSE_CALCULATION = 'SET_HOUSE_CALCULATION';

type GlobalState = {
  currentUser: CurrentUser | undefined;
  currentCalculation: ICalculation | undefined;
  options: IOptions | undefined;
  fetchingOptions: boolean;
  fetchingCalculation: boolean;
};

type Action = {
  payload?: any;
  type: string;
};

const initialState: GlobalState = {
  currentUser: undefined,
  currentCalculation: undefined,
  options: undefined,
  fetchingOptions: false,
  fetchingCalculation: false,
};

type ContextValues = {
  state: GlobalState;
  dispatch: (action: Action) => void;
  setCurrentCalculation: (calculation: ICalculation) => void;
  currentUser: CurrentUser;
  currentCalculation: ICalculation;
  setProductValue: any;
  getValue: <T>(path: string, property?: string) => T;
  setValue: (path: string, value: any) => any;
};

export const GlobalStateContext = createContext<any>(null);

const reducer = (state: GlobalState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_USER: {
      return {
        ...state,
        currentUser: payload,
      };
    }
    case SET_CURRENT_CALCULATION: {
      return {
        ...state,
        currentCalculation: { ...(state.currentCalculation || {}), ...payload },
      };
    }
    case SET_FETCHING_CALCULATION: {
      return {
        ...state,
        fetchingCalculation: payload,
      };
    }
    case SET_CURRENT_CASE: {
      return {
        ...state,
        currentCase: payload,
      };
    }
    case FETCHING_OPTIONS: {
      return {
        ...state,
        fetchingOptions: true,
      };
    }
    case SET_OPTIONS: {
      return {
        ...state,
        options: payload,
        fetchingOptions: false,
      };
    }
    default:
      return state;
  }
};

type ProviderProps = {
  children: React.ReactNode;
};

export const GlobalStateProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setCurrentCalculation = useCallback((calculation: ICalculation) => {
    dispatch({ type: SET_CURRENT_CALCULATION, payload: calculation });
  }, []);

  const setValue = useCallback(
    (path: string, value: any) => {
      const newHouse = set(state?.currentCalculation || {}, path, cloneDeep(value)) as ICalculation;
      setCurrentCalculation({ ...newHouse });
    },
    [state?.currentCalculation, setCurrentCalculation],
  );

  const setProductValue = useCallback(
    (path: string, property: string, value: any) => {
      const fullPath = `currentHouse.house.${joinPath(path, property || '')}`;
      const val = [
        'quantity',
        'externalPricePerPiece',
        'externalTotalPrice',
        'externalDeductionPrice',
        'contractorManualPrice',
        'contractorManualQuantity',
      ].includes(property)
        ? Number(value)
        : value;

      setValue(fullPath, val);
      // console.log('productValue set', fullPath, val);
    },
    [setValue],
  );

  const getValue = useCallback(
    <T extends unknown>(path: string, property?: string): T => {
      const fullPath = `currentHouse.house.${joinPath(path, property || '')}`;
      return get(state?.currentCalculation, fullPath) as T;
    },
    [state?.currentCalculation],
  );

  const value = {
    state,
    dispatch,
    currentUser: state.currentUser,
    currentCalculation: state.currentCalculation,
    setCurrentCalculation,
    setProductValue,
    getValue,
    setValue,
  };
  return <GlobalStateContext.Provider value={value}>{children}</GlobalStateContext.Provider>;
};

export const useGlobalStateValue = () => useContext<ContextValues>(GlobalStateContext);
