import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ICustomer } from 'models/models';
import Box from 'ui-library/Box';
import Button from 'ui-library/Button';
import List from 'ui-library/List';
import SearchBar from 'ui-library/SearchBar';
import { useGetCustomers } from 'Admin/api';
import { AddCustomer } from 'Admin/Modals';
import Loader from 'ui-library/Loader';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Customers = () => {
  const [search, setSearch] = useState<string>();
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const { customers, isLoading } = useGetCustomers();

  const filterCustomers = useCallback(
    (customer: ICustomer) => {
      if (!search || search === '') return true;
      const filterStr = Object.values(customer).join(' ').toLocaleLowerCase();
      return filterStr.includes(search.toLocaleLowerCase());
    },
    [search],
  );

  if (isLoading) return <Loader label="Hämtar kunder" />;

  return (
    <Box>
      <Header>
        <h2>Kunder</h2>
        <Button onClick={() => setShowAddCustomer(true)}>+ Lägg till kund</Button>
      </Header>
      <SearchBar placeholder="Sök kund" onChange={(e: any) => setSearch(e.target.value)} />
      <List>
        {customers?.filter(filterCustomers).map(({ firstName, lastName, id }) => (
          <Link key={id} to={`/admin/kunder/${id}`}>
            {id} - {firstName} {lastName}
          </Link>
        ))}
      </List>
      <AddCustomer show={showAddCustomer} onClose={() => setShowAddCustomer(false)} />
    </Box>
  );
};

export default Customers;
