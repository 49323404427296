import { usePrice, useSetProductValue } from 'api/price';
import CalculationInput from 'components/CalculationInput';
import ContractorPrice from 'components/ContractorPrice';
import { useCurrentCalculation } from 'hooks/useCurrentCalculation';
import { IContractorCalculation, IProduct, ISubProduct } from 'models/calculationModel';
import { IContractorPriceDict } from 'models/priceModel';
import { useGlobalStateValue } from 'stateContext';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';
import Box from 'ui-library/Box';
import Textarea from 'ui-library/Textarea';
import InputField from 'ui-library/InputField';
import Loader from 'ui-library/Loader';
import { useCalculationIsDisabled } from 'hooks/useCalculationIsDisabled';
import { EditableContractorField } from './EditableContractorField';
import { ExcludeCheckbox } from './ExcludeCheckbox';
import { productFilter, setAllExcluded } from './helpers';

const ContractorTableRow = styled.tr`
  &.excluded {
    td:not(.checkbox) {
      opacity: 0.5;
    }
  }
`;

type PriceTableProps = {
  rows: ISubProduct[];
  topProduct: IProduct;
  path: string;
  contractorPrice: IContractorPriceDict;
};
const PriceTable = (props: PriceTableProps) => {
  const { getValue } = useGlobalStateValue();
  const calculationIsDisabled = useCalculationIsDisabled();

  const { rows, topProduct, path, contractorPrice } = props;
  if (!contractorPrice) return null;
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Produkt</th>
            <th>Antal</th>
            <th>á pris</th>
            <th>Totalt pris</th>
            <th>Exkludera</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            const subProductIndex = topProduct.subProducts.findIndex(
              (sp) => sp.productNumber === row.productNumber,
            );

            const fullPath = `${path}.subProducts[${subProductIndex}].products[0]`;
            const excluded = getValue(`${fullPath}.contractorExcludePrice`);
            return (
              <ContractorTableRow className={excluded ? 'excluded' : ''} key={row.name}>
                <td>{row.name}</td>
                <td>
                  <EditableContractorField
                    value={contractorPrice.results?.[row.productNumber].quantity}
                    path={fullPath}
                    property="contractorManualQuantity"
                    disabled={calculationIsDisabled}
                  />
                </td>
                <td>
                  <EditableContractorField
                    value={contractorPrice.results[row.productNumber]?.pricePerUnit}
                    path={fullPath}
                    property="contractorManualPrice"
                    disabled={calculationIsDisabled}
                  />
                </td>
                <td>{contractorPrice.results[row.productNumber]?.calculatedPrice || '0'}</td>
                <td className="checkbox">
                  <ExcludeCheckbox
                    path={fullPath}
                    property="contractorExcludePrice"
                    disabled={calculationIsDisabled}
                  />
                </td>
              </ContractorTableRow>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

type Props = {
  calculationName: string;
  isGarageTemplate: boolean;
};
const ContractorTable = ({ calculationName, isGarageTemplate }: Props) => {
  const { getValue, setValue } = useGlobalStateValue();
  const { fetchingCalculation } = useCurrentCalculation();
  const calculationIsDisabled = useCalculationIsDisabled();
  const setProductValue = useSetProductValue();
  const path = `contractorCalculation.${calculationName}`;
  const calculation = getValue(path, '') as IContractorCalculation;
  const { price } = usePrice();
  const contractorPrice = price?.currentContractorPrices?.[calculationName];

  if (!calculation || fetchingCalculation || !price) return <Loader label="Hämtar entreprenad" />;
  const rowsUnresolved = calculation.fieldsThatRequireInput.subProducts.filter(
    productFilter(isGarageTemplate),
  );
  const rowsResolved = calculation.resolvedFields.subProducts.filter(productFilter(isGarageTemplate));

  const handleExcludeAll = (e: any) => {
    const cpUnresolved = rowsUnresolved.map(setAllExcluded(e));
    const cpResolved = rowsResolved.map(setAllExcluded(e));

    setValue(`currentHouse.house.${path}.fieldsThatRequireInput.subProducts`, cpUnresolved);
    setValue(`currentHouse.house.${path}.resolvedFields.subProducts`, cpResolved);
  };

  return (
    <Box bgColor="#fff">
      <Box>
        <ExcludeCheckbox
          path={path}
          property="contractorExcludePrice"
          label="Exkludera alla"
          onChange={handleExcludeAll}
          disabled={calculationIsDisabled}
        />

        <CalculationInput
          disabled={calculationIsDisabled}
          label="Prisjustering (%)"
          path={path}
          property="priceAdjustment"
        />
      </Box>
      <Box>
        <h4>Värden från kalkyl</h4>
        <PriceTable
          rows={rowsResolved}
          topProduct={calculation.resolvedFields}
          path={`${path}.resolvedFields`}
          contractorPrice={contractorPrice}
        />
      </Box>
      <hr />
      <Box>
        <h4>Värden från användare</h4>
        <PriceTable
          rows={rowsUnresolved}
          topProduct={calculation.fieldsThatRequireInput}
          path={`${path}.fieldsThatRequireInput`}
          contractorPrice={contractorPrice}
        />
      </Box>
      <Box>
        <Textarea
          defaultValue={calculation.otherDescription}
          label="Övrig kommentar"
          disabled={calculationIsDisabled}
          onChange={(e: any) => {
            setProductValue(path, 'otherDescription', e.target.value, true);
          }}
        />
        <InputField
          defaultValue={calculation.otherPrice}
          label="Övrigt pris"
          disabled={calculationIsDisabled}
          onChange={(e: any) => {
            setProductValue(path, 'otherPrice', e.target.value);
          }}
        />
      </Box>
      <ContractorPrice type={calculationName} />
    </Box>
  );
};

export default ContractorTable;
