import { useSetProductValue } from 'api/price';
import { useCalculationIsDisabled } from 'hooks/useCalculationIsDisabled';
import { useCurrentCalculation } from 'hooks/useCurrentCalculation';
import Box from 'ui-library/Box';
import InputField from 'ui-library/InputField';
import { ExcludeCheckbox } from './ExcludeCheckbox';

const GeneralContractor = () => {
  useCurrentCalculation();
  const setProductValue = useSetProductValue();
  const disabled = useCalculationIsDisabled();

  const handleChange = (e: any) => {
    setProductValue('contractorCalculation', 'generalContractorPrice', e.target.value);
  };

  return (
    <Box bgColor="#fff">
      <h3>Generalentreprenad</h3>
      <ExcludeCheckbox
        label="Generalentrepreprenadsarvode"
        path="contractorCalculation"
        property="generalContractor"
        disabled={disabled}
      />
      <InputField
        disabled={disabled}
        onChange={handleChange}
        label="Pris generalentreprenadsarvode (exkl. moms)"
      />
    </Box>
  );
};

export default GeneralContractor;
