import ContractorDetailsForm from 'Admin/Components/Contractors/ContractorDetailsForm';
import { useGetContractors } from 'api/contractors';
import { useParams } from 'react-router-dom';
import Box from 'ui-library/Box';
import Loader from 'ui-library/Loader';

const ContractorDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { data: contractors, isLoading } = useGetContractors();

  if (isLoading) {
    return <Loader label="Hämtar entreprenör" />;
  }
  const contractor = contractors?.find((c) => c.id === Number(id));

  return (
    <Box>
      {contractor?.name}
      <ContractorDetailsForm contractor={contractor} />
    </Box>
  );
};

export default ContractorDetails;
