import styled from 'styled-components';

type Props = {
  bgColor?: string;
};
const Box = styled.div<Props>`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  padding: 1rem;
`;

export default Box;
