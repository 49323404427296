import { Controller } from 'react-hook-form';
import Select from '.';

const filterSingleValue = (value: any) => (option: any) => option.value?.toString() === value?.toString();
const filterMultiValue =
  (value: any = []) =>
  (option: any) =>
    value?.map(String).includes(option.value);

const ControlledSelect = ({
  disabled = false,
  control,
  name,
  label,
  hideLabel,
  defaultValue,
  options,
  isMulti,
  placeholder,
}: any) => {
  const valueFilterType = isMulti ? 'filter' : 'find';
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { value, onChange, ...field } }) => {
        const filterFn = isMulti ? filterMultiValue(value) : filterSingleValue(value);
        const aValue = options?.[valueFilterType](filterFn);

        return (
          <Select
            options={options}
            hideLabel={hideLabel}
            label={label}
            isMulti={isMulti}
            disabled={disabled}
            onChange={(val: any) => {
              if (val.value === 'none') {
                onChange(null);
              } else {
                const valueToSet = isMulti ? val.map((v: any) => Number(v.value)) : Number(val.value);
                onChange(valueToSet);
              }
            }}
            value={aValue}
            placeholder={placeholder}
            {...field}
          />
        );
      }}
    />
  );
};

export default ControlledSelect;
