import { adFetch } from 'adalConfig';
import { useQuery } from 'react-query';
import floorConfig from './staticConfigs/floors.json';
import productGroupLayoutConfig from './staticConfigs/productGroupLayouts.json';

const staticConfigs: Record<string, any> = {
  floor: floorConfig,
  productGroupLayouts: productGroupLayoutConfig,
};

export const useConfig = <T>(configName: string) => {
  const query = useQuery([configName], () => {
    if (staticConfigs[configName]) return new Promise<T>((resolve) => resolve(staticConfigs[configName]));
    return adFetch<T>(`/config?name=${configName}`);
  });

  return query;
};
