import { useCalculationFromVersion } from 'api/calculation';
import { useCurrentCase, useSetCaseStatus, useUpdateCase } from 'api/case';
import { usePrice } from 'api/price';
import { IVersion } from 'models/calculationModel';
import { CaseStatuses } from 'models/models';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import StyledButton from 'ui-library/Button';
import LabeledCollapse from 'ui-library/LabeledCollapse';
import GenerateModal from './GenerateModal';

type Props = {
  version: IVersion;
};
const ActionButtons = ({ version }: Props) => {
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const { data: calculationVersion } = useCalculationFromVersion(version);
  const queryClient = useQueryClient();
  const { mutate: setCaseStatus } = useSetCaseStatus();
  const { mutate: updateCase } = useUpdateCase();
  const { currentCase } = useCurrentCase();
  const { price } = usePrice(calculationVersion);

  const handleSign = () => {
    setCaseStatus(
      { status: CaseStatuses.SIGNED_CONSTRUCITON_ORDER, version: version.version },
      {
        onSuccess: () => {
          if (currentCase) {
            updateCase(
              {
                ...currentCase,
                constructionOrderSigned: version.version,
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries(['versions']);
                  queryClient.invalidateQueries(['case']);
                },
              },
            );
          }
        },
      },
    );
  };
  return (
    <>
      <LabeledCollapse label="...">
        {price?.deliveryDeclarationValidation.isValid && (
          <StyledButton onClick={() => setShowGenerateModal(true)}>Generera tillverkningsorder</StyledButton>
        )}
        {currentCase?.constructionOrdersSent.includes(version.version) && (
          <StyledButton onClick={handleSign}>Ange tillverkningsorder signerad</StyledButton>
        )}
      </LabeledCollapse>
      <GenerateModal show={showGenerateModal} onClose={() => setShowGenerateModal(false)} version={version} />
    </>
  );
};

export default ActionButtons;
