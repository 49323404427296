import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useCurrentCase } from 'api/case';
import { IVersion } from 'models/calculationModel';
import Button from 'ui-library/Button';
import LabeledCollapse from 'ui-library/LabeledCollapse';
import { usePdfContext } from 'hooks/usePdfMaker/PdfContext';
import { useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { caseChangedAtom } from 'atoms';
import QuoteConfirm from './QuoteConfirm';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin-bottom: 0.5rem;
  }
`;

type Props = {
  version: IVersion;
  setShowProductionCostEditor: (show: boolean) => void;
};
const ActionButtons = ({ version, setShowProductionCostEditor }: Props) => {
  const [showQuoteConfirm, setShowQuoteConfirm] = useState(false);
  const caseHasUnsavedChanges = useRecoilValue(caseChangedAtom);
  const { openDocument } = usePdfContext();
  const { currentCase } = useCurrentCase();
  const queryClient = useQueryClient();

  const invalidatePrice = () => {
    queryClient.invalidateQueries(['price']);
  };

  return (
    <LabeledCollapse label="...">
      <ButtonWrapper>
        <Button disabled={caseHasUnsavedChanges} onClick={() => setShowQuoteConfirm(true)}>
          Generera offert
        </Button>
        <Button disabled={caseHasUnsavedChanges} onClick={() => openDocument('deliveryDeclaration', version)}>
          Generera LD
        </Button>
        <Button>
          <Link
            to={`/case/${currentCase?.caseNumber}/huskalkyl/hustyp?version=${version.version}`}
            onClick={invalidatePrice}
          >
            Se kalkyl
          </Link>
        </Button>
        <Button onClick={() => openDocument('productionCostCalculation', version)}>
          Visa produktionskostnadskalkyl
        </Button>
        <Button onClick={() => setShowProductionCostEditor(true)}>Editera produktionskostnadskalkyl</Button>
      </ButtonWrapper>
      <QuoteConfirm show={showQuoteConfirm} onClose={() => setShowQuoteConfirm(false)} version={version} />
    </LabeledCollapse>
  );
};

export default ActionButtons;
