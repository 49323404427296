import { useCurrentCase, useUpdateCase } from 'api/case';
import { IVersion } from 'models/calculationModel';
import { ICase } from 'models/models';
import toast from 'react-hot-toast';
import Button from 'ui-library/Button';
import Modal from 'ui-library/Modal';
import { usePdfContext } from 'hooks/usePdfMaker/PdfContext';
import { useQueryClient } from 'react-query';

type Props = {
  onClose: () => void;
  show: boolean;
  version: IVersion;
};
const QuoteConfirm = ({ onClose, show, version }: Props) => {
  const { openDocument } = usePdfContext();
  const { currentCase } = useCurrentCase();
  const queryClient = useQueryClient();

  const { mutate: saveCase, isLoading } = useUpdateCase();
  const handleOpen =
    (send = false) =>
    () => {
      openDocument('quote', version);
      onClose();
      if (send && !currentCase?.quotesSent.includes(version.version)) {
        const updatedQuotes = currentCase?.quotesSent
          ? [...currentCase?.quotesSent, version.version]
          : [version.version];
        const updatedCase = { ...currentCase, quotesSent: updatedQuotes };
        saveCase(updatedCase as ICase, {
          onSuccess() {
            toast.success('Offert skickad');
            queryClient.invalidateQueries(['versions']);
            queryClient.invalidateQueries(['case']);
          },
        });
      }
    };
  return (
    <Modal show={show} onClose={onClose} header="Generera offert">
      <Button disabled={isLoading} onClick={handleOpen()} variant="secondary">
        Generera och förhandsgranska
      </Button>
      <Button onClick={handleOpen(true)} disabled={isLoading}>
        Generera och skicka
      </Button>
    </Modal>
  );
};
export default QuoteConfirm;
